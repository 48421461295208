import {
  getAllConfigurations,
  getBaseEndpointWithInstance,
  getData,
  getEndpointWithInstanceAndDataset
} from "../service/graph-api";
import cloneDeep from "lodash/cloneDeep";
import {
  ALIAS_CONTAINER, ALIAS_MANAGEMENT_ID_LABEL,
  ALIAS_OWL_ANNOTATION_PROPERTY,
  ALIAS_OWL_CLASS,
  ALIAS_OWL_DATATYPE_PROPERTY,
  ALIAS_OWL_INVERSE_OF,
  ALIAS_OWL_OBJECT_PROPERTY,
  ALIAS_RDF_PROPERTY,
  ALIAS_RDFS_CLASS,
  ALIAS_RDFS_DOMAIN,
  ALIAS_RDFS_LABEL,
  ALIAS_RDFS_SUBCLASS_OF,
  ALIAS_SH_CLASS,
  ALIAS_SH_DATATYPE,
  ALIAS_SH_MAX_COUNT,
  ALIAS_SH_MAX_LENGTH,
  ALIAS_SH_MIN_COUNT,
  ALIAS_SH_MIN_LENGTH,
  ALIAS_SH_NODE_KIND,
  ALIAS_SH_OR,
  ALIAS_SH_PATH,
  ALIAS_SH_PATTERN,
  ALIAS_SH_PROPERTY,
  ALIAS_SH_RESULT,
  ALIAS_SH_RESULT_MESSAGE,
  ALIAS_SH_RESULT_PATH,
  ALIAS_SH_SHAPE,
  ALIAS_SH_TARGET_CLASS,
  ALIAS_SYS_ALIAS,
  ALIAS_SYS_ALIAS_MAPPING,
  ALIAS_SYS_API_CONFIGURATION,
  ALIAS_SYS_CLASS_IRI,
  ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI,
  ALIAS_SYS_DATA_DOMAIN_BASE_IRI,
  ALIAS_SYS_ETAG,
  ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP,
  ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP,
  ALIAS_SYS_EXTENSION_RULE,
  ALIAS_SYS_ID_LABEL,
  ALIAS_SYS_IDENTIFIER,
  ALIAS_SYS_IRI,
  ALIAS_SYS_IS_CONNECTED_TO,
  ALIAS_SYS_IS_SYSTEM_ALIAS,
  ALIAS_SYS_JSONLD_CONTEXT,
  ALIAS_SYS_RESULTS,
  ALIAS_SYS_TYPE_CLASS_BASED_PERMISSION,
  ALIAS_SYS_TYPE_OPERATION_BASED_PERMISSION,
  ALIAS_SYS_TYPE_ROLE,
  AT_CONTEXT,
  AT_DEFAULT,
  AT_GRAPH,
  AT_ID,
  BETWEEN,
  BOOLEAN_OPTIONAL_REGEX,
  BOOLEAN_REQUIRED_REGEX,
  COMBINED_WITH_PROPERTY,
  contentTypeMap,
  DATATYPE_LITERAL_VALIDATION_REGEX,
  DATATYPE_SUGGESTIONS,
  DATE_FORMAT,
  DATETIME_FORMAT,
  EASYGRAPH_DATA_AUTHENTICATION_DISABLED,
  EASYGRAPH_DATA_NAMESPACE,
  EQUAL_TO,
  EXISTS,
  FROM_SHAPE,
  GRAPH,
  GRAPHQL_DELETE_OPERATION,
  GREATER_THAN_EQUAL_TO,
  ID,
  LABEL_DATA_TYPE,
  LABEL_PROPERTY,
  LANG,
  LANG_LITERAL_VALIDATION_REGEX,
  LESS_THAN_EQUAL_TO,
  MAX_VALUE,
  MIN_MAX_LENGTH,
  NOT_EQUAL_TO,
  OBJECT,
  OBJECT_INVERSE,
  ORDER,
  ORDER_LANG,
  ORDER_NO_VALUE,
  ORDER_PRECEDENCE,
  RANDOM_LITERAL_REGEX,
  RDF_LANGSTRING,
  RDF_PREDICATE,
  RDF_SUBJECT,
  RDFS_DOMAIN,
  RDFS_IS_DEFINED_BY,
  RDFS_LITERAL,
  RDFS_MEMBER,
  RDFS_RANGE,
  RDFS_SEE_ALSO,
  RDFS_SUB_CLASS_OF,
  RDFS_SUB_PROPERTY_OF,
  REGEX_FOR_DATATYPES,
  ROUTE_ACCOUNT,
  ROUTE_APP,
  ROUTE_MANAGEMENT_ADMIN_LOGIN,
  ROUTE_MANAGEMENT_HOME,
  ROUTE_MANAGEMENT_LOGIN,
  ROUTE_MANAGEMENT_LOGOUT,
  SH_IRI,
  SH_LITERAL,
  TEXT,
  TIME_FORMAT,
  TYPE,
  TYPE_OWL_ONTOLOGY,
  TYPE_OWL_THING,
  TYPE_RDF_LIST,
  TYPE_RDF_TYPE,
  VALIDATION_ALIAS_NAME_REGEX,
  VALIDATION_BASE_IRI_MAX_LENGTH,
  VALIDATION_BASE_IRI_REGEX,
  VALIDATION_CLASS_NAME_EXISTS,
  VALIDATION_DESCRIPTION_LENGTH,
  VALIDATION_EXAMPLE_SET_FILE_MAX_SIZE,
  VALIDATION_FEEDBACK_FILE_MAX_SIZE,
  VALIDATION_INVALID_IRI_CHARACTERS_REGEX,
  VALIDATION_LABEL_LENGTH,
  VALIDATION_MESSAGE_EMPTY_STRING,
  VALIDATION_MESSAGE_HASH_IN_BASE_IRI,
  VALIDATION_MESSAGE_INVALID_ALIAS_NAME,
  VALIDATION_MESSAGE_INVALID_BASE_IRI,
  VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL,
  VALIDATION_MESSAGE_INVALID_BASE_IRI_SLASH,
  VALIDATION_MESSAGE_NONZERO_NUMBER,
  VALIDATION_MESSAGE_NUMBER,
  VALIDATION_MESSAGE_REQUIRED_FIELD,
  VALIDATION_ONTOLOGY_FILE_MAX_SIZE,
  VALIDATION_PASSWORD,
  VALIDATION_PASSWORD_LENGTH,
  VALIDATION_PASSWORD_MIN_LENGTH,
  VALIDATION_PASSWORD_REGEX,
  VALIDATION_PREFIX_MAX_LENGTH,
  VALIDATION_PREFIX_REGEX,
  VALIDATION_PROPERTY_NAME_EXISTS,
  VALIDATION_REGEX_LENGTH,
  VALIDATION_SITE_FILE_MAX_SIZE,
  VALIDATION_URL,
  VALUE,
  VALUE_END_KEY,
  VARIABLE_DATA_CONTEXT_URL,
  WRITABLE_CLASS_BASE_IRI,
  XSD_BOOLEAN,
  XSD_DATE,
  XSD_DATETIME,
  XSD_STRING,
  XSD_TIME
} from "../Constants";
import format from 'date-fns/format';
import {isArray, isEqual, isObject, isString, transform} from 'lodash';
import {enabledTestConfig, env, getBackendBaseEndpoint, getConfigForDataset, getEGSystemContextURL} from "../Configs";
import uuid4 from 'uuid/v4'
import React from "react";
import ArrayType from "./ShapeToForm/ArrayType";
import IdType from "./ShapeToForm/IdType";
import FieldLabel from "./ShapeToForm/FieldLabel";
import RandExp from "randexp";
import {LANGUAGES} from "../components/LanguagesList";
import {parse, print} from "graphql";
import {traceDebug} from "../components/Trace";
import {Typography} from "@material-ui/core";
import {getSelectedDataset, getSettingsEditAction, isPageEditEnabled} from "../layouts/common/Profile";
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {BACKEND_PATH_EG_CONTEXT, BACKEND_PATH_MANAGEMENT_DATASET_SEARCH} from "../service/backend-paths";

const MODULE_NAME = 'util.js';

export const createFilterOptionsFromLabel = createFilterOptions({
  stringify: option => option.label,
})

export function getPrefLabel(node) {
  return node && node.prefLabel ? node.prefLabel.en : "No title found!";
}

export function getTitle(node) {
  return node && node.title ? node.title.en : "No title found!";
}

export function getBrowseLanguageCode(browseLanguage) {
  return isString(browseLanguage) ? browseLanguage : browseLanguage.value;
}


export function isArrayOnly(value) {
  return Array.isArray(value);
}

export function isObjectOnly(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export function getUiLabelTranslationFromContext(callerThis, key, defaultValue) {
  let globals = callerThis.context;
  let defaultValueToReturn = defaultValue || key;
  let returnValue = globals?.getUiLabelTranslationFor?.(key, defaultValueToReturn) || defaultValueToReturn;
  return returnValue ;
}

export function getSettingsFromContext(callerThis) {
  let globals = callerThis.context;
  return globals?.getSettings?.();
}

export function getMultilingualValue(value, browseLanguage, mode ) {
  if(!value) {
    return ;
  }
  if(isString(value)) {
    return value;
  } else {
    let browseLangCode = getBrowseLanguageCode(browseLanguage);
    let valueToReturn;
    if(isArray(value)) {
      if(value.length > 0 && value[0][LANG]) {
        valueToReturn = value.find(v => v[LANG] === browseLangCode);
        if(valueToReturn) {
          return valueToReturn[VALUE];
        }
      } else {
        //Means array of string so return as it is
        return value.filter(v => isString(v)).find(v => v);
      }
    }
    if(value[LANG] === browseLangCode && value[VALUE]) {
        return value[VALUE];
    }
    if(mode !== 'strict' && !valueToReturn) {
      valueToReturn = value[AT_DEFAULT];
    }
    if(!valueToReturn) {
      valueToReturn = value[browseLangCode];
    }
    //If value has type e.g. datatype of value then return value string
    if( mode !== 'strict' && !valueToReturn && value[VALUE]) {
      return value[VALUE];
    }
    return valueToReturn;
  }
}


export function isPositiveInteger(value) {
  let n = Math.floor(Number(value));
  return n !== Infinity && String(n) === value && n > 0 && n < MAX_VALUE;
}

export function validateZeroOrPositiveInteger(value) {
  let n = Math.floor(Number(value));
  let isValid = (n !== Infinity && String(n) === value && n === 0) || isPositiveInteger(value);
  if (isValid) {
    return '';
  } else {
    return VALIDATION_MESSAGE_NUMBER;
  }
}

export function isMinGreaterThanMax(min, max) {
  let minNum = Math.floor(Number(min));
  let maxNum = Math.floor(Number(max));
  return minNum && maxNum && minNum > maxNum;
}

export function isBooleanDatatype(datatypeIRI) {
  return datatypeIRI === 'http://www.w3.org/2001/XMLSchema#boolean';
}

export function validateCount(count, datatypeIRI, min, max, minMaxMessage, numberValidator) {
  let error = '';
  if(datatypeIRI && isBooleanDatatype(datatypeIRI)) {
    if(count && Math.floor(Number(count)) !== 1) {
      error = 'Value '+count+' is invalid for xsd:boolean datatype. Enter 1 or leave empty.'
    }
  } else if (count && !isPositiveInteger(count) && !numberValidator) {
    error = VALIDATION_MESSAGE_NONZERO_NUMBER;
  } else if (count && numberValidator && numberValidator(count)) {
    error = numberValidator(count);
  } else if (isMinGreaterThanMax(min, max)) {
    error = minMaxMessage || 'Min should be less than or equal to Max.';
  } else if (!isValidMaxLengthWithoutTrim(count, MIN_MAX_LENGTH)) {
    error = getMaxLengthMessage(MIN_MAX_LENGTH);
  }
  return error;

}

export function validateModelName(value, label, length) {
  if (!value) {
    return getRequiredFieldMessage(label);
  } else if (value.trim() === '') {
    return VALIDATION_MESSAGE_EMPTY_STRING;
  } else if (!isValidMaxLengthWithoutTrim(value, length)) {
    return  getMaxLengthMessage(length);
  }
  return '';
}

export function validateAlias(value, label) {
  if (!value) {
    return getRequiredFieldMessage(label);
  } else if (!VALIDATION_ALIAS_NAME_REGEX.test(value)) {
    return VALIDATION_MESSAGE_INVALID_ALIAS_NAME;
  } else if (!isValidMaxLengthWithoutTrim(value, VALIDATION_LABEL_LENGTH)) {
    return  getMaxLengthMessage(VALIDATION_LABEL_LENGTH);
  }
  return '';
}

export function validateRequiredAndMinMaxLength(value, label, minLength, maxLength) {
  let error = validateRequiredAndMaxLength(value, label, maxLength);
  if(error) {
    return error;
  }
  if(!isValidMinLengthWithoutTrim(value, minLength)) {
    return getMinLengthMessage(minLength);
  }
  return '';
}

export function validatePassword(value) {
  let message = '';
  if(!isValidMaxLengthWithoutTrim(value, VALIDATION_PASSWORD_LENGTH)) {
    message = getMaxLengthMessage(VALIDATION_PASSWORD_LENGTH);
  }
  if(!message && value.length < VALIDATION_PASSWORD_MIN_LENGTH) {
    message = `Minimum ${VALIDATION_PASSWORD_MIN_LENGTH} characters required.`
  }
  if(!message && !VALIDATION_PASSWORD_REGEX.test(value)) {
    message = VALIDATION_PASSWORD;
  }
  return message;
}

export function validateRequiredAndMaxLength(value, label, maxLength) {
  if (!value || value.trim() === '') {
    return getRequiredFieldMessage(label);
  } else if (!isValidMaxLengthWithoutTrim(value, maxLength)) {
    return  getMaxLengthMessage(maxLength);
  }
  return '';
}

export function validateMaxLength(value, maxLength) {
  if (!isValidMaxLengthWithoutTrim(value, maxLength)) {
    return  getMaxLengthMessage(maxLength);
  }
  return '';
}

export function validatePrefix(value, label, maxLength) {
  let error = validateRequiredAndMaxLength(value, label, maxLength);
  if(error) {
    return error;
  } else if(!isValidPrefix(value)) {
    return 'Invalid value.'
  }
  return '';
}

export function isValidProtocol(value) {
  let protocolPart = value.substring(0, 4)
  let optional =  value.substring(4, 5)
  if(optional === 's' && protocolPart === 'http') {
    return true;
  } else if ( (optional === ':' || optional === '') && protocolPart === 'http') {
    return true;
  } else if (protocolPart.length > 0 && protocolPart.length < 4 && 'http'.substring(0, protocolPart.length) === protocolPart) {
    return true;
  } else {
    return false;
  }
}

export function validateBaseIRI(value, label) {
  if(!value) {
    return getRequiredFieldMessage(label);
  } else if(value && value.includes('#')) {
    return VALIDATION_MESSAGE_HASH_IN_BASE_IRI;
  } else if (!isValidBaseIRILength(value)) {
    return getMaxLengthMessage(VALIDATION_BASE_IRI_MAX_LENGTH);
  } else if (!isValidBaseIRI(value)) {
    if(!isValidProtocol(value)) {
      return VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL;
    } else if(value[value.length -1] !== '/') {
      return VALIDATION_MESSAGE_INVALID_BASE_IRI_SLASH;
    } else {
      return VALIDATION_MESSAGE_INVALID_BASE_IRI;
    }
  }  else {
    return undefined;
  }

}

export function validateDescription(value) {
  if(!isValidMaxLengthWithoutTrim(value, VALIDATION_DESCRIPTION_LENGTH)) {
    return getMaxLengthMessage(VALIDATION_DESCRIPTION_LENGTH);
  } else {
    return '';
  }
}

export function validateNameDuplication(name, ontologyProperties, ontologyClasses) {
  let existingWithSameName = ontologyProperties.find(op => op[ALIAS_SYS_ID_LABEL] === name);
  if(existingWithSameName) {
    return VALIDATION_PROPERTY_NAME_EXISTS;
  }
  existingWithSameName = ontologyClasses.find(op => op[ALIAS_SYS_ID_LABEL] === name);
  if(existingWithSameName) {
    return VALIDATION_CLASS_NAME_EXISTS;
  }
  return '';
}

export function validateRegex(value, label, maxLength) {
  if(!isValidMaxLengthWithoutTrim(value, maxLength)) {
    return getMaxLengthMessage(maxLength);
  } else {
    try {
      new RegExp(value);
      return '';
    } catch (e) {
      return e.message;
    }
  }
}

export function handlePasswordFieldChange(callerThis, event, errorKey = 'passwordError') {
  restrictMaximumCharacters(event, VALIDATION_PASSWORD_LENGTH);
  callerThis.handleFieldChange(event);
  const { target: { name, value } } = event;
  callerThis.setState({[errorKey]: validatePassword(value)});
}



export function isValidBaseIRI(value) {
  return VALIDATION_BASE_IRI_REGEX.test(value);
}

export function isValidBaseIRILength(value) {
  return value.length <= VALIDATION_BASE_IRI_MAX_LENGTH ;
}
export function isValidPrefixLength(value) {
  return value.length <= VALIDATION_PREFIX_MAX_LENGTH;
}

export function isValidPrefix(value) {
  return VALIDATION_PREFIX_REGEX.test(value);
}

export function isValidLabelLength(value) {
  return value && value.trim().length <= VALIDATION_LABEL_LENGTH;
}

export function isValidMaxLength(value, max) {
  return value && value.trim().length <= max;
}

export function isValidMaxLengthWithoutTrim(value, max) {
  return !value ? true : value.length <= max;
}

export function isValidMinLengthWithoutTrim(value, min) {
  return !value ? false : value.length >= min;
}

export function isValidRegexLength(value) {
  return value && value.trim().length <= VALIDATION_REGEX_LENGTH;
}

export function isValidIRI(value) {
  if(VALIDATION_INVALID_IRI_CHARACTERS_REGEX.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function getMaxLengthMessage(length) {
  if(length === undefined) {
    return 'Character limit exceeded.';
  } else {
    return `Max ${length} characters.`
  }
}

export function getMinLengthMessage(length) {
    return `Minimum ${length} characters required.`
}

export function getRequiredFieldMessage(label) {
  return label + ' '+VALIDATION_MESSAGE_REQUIRED_FIELD;
}

export function getLocalName(iri, prefix = true, prefixLength = 10) {
  if(iri && isString(iri)) {
    let parts1 = iri.split('/')
    let parts2 = parts1[parts1.length - 1].split('#')
    let part2Last = parts2[parts2.length - 1];
    let start = iri.replace(new RegExp("("+part2Last+"$)"), '').substring(0, prefixLength);
    if(start && prefix && start + part2Last === iri) {
      return iri;
    }
    let localNameToReturn = (start && prefix ? start+"..." : '') + part2Last;
    return  localNameToReturn;
  } else {
    console.log(iri);
    return ''
  }
}

export function getLanguageCodesSuggestions() {
  return sort(LANGUAGES, "value");
}

export function getLiteralDatatypeSuggestions(configurations, aliasesMap) {
    let suggestions = DATATYPE_SUGGESTIONS
        .map(v => ({
          value: v.value,
          label: getLocalName(v.value, false),
          tooltip : v.value
        }));
    let set = new Set();
    suggestions.map(v => v.value).forEach(v => set.add(v));
    getShapesData(configurations).forEach(s => {
        getShapePropertyArray(s).filter(p => p[ALIAS_SH_DATATYPE] && !set.has(p[ALIAS_SH_DATATYPE])).forEach(p => {
            let datatype = p[ALIAS_SH_DATATYPE];
            suggestions.push({
                value: datatype,
                label: getLocalName(datatype),
                tooltip : datatype
            })
        })
    });
    let filtered = suggestions.filter(v => !["http://www.w3.org/1999/02/22-rdf-syntax-ns#langString"].includes(v.value));
    return sort(filtered, "label");
}

export function getBaseIRI(iri) {
  if(iri) {
    return  iri.includes('#') ? iri.substring(0, iri.lastIndexOf('#')+1) : iri.substring(0, iri.lastIndexOf('/')+1)
  } else {
    return ''
  }
}

export function getFileExtension(fileName) {
  let split = fileName.split('.')
  return split[split.length - 1]
}

export function normalize(name) {
  if(name) {
    return name.replace(/[\W_]+/g,"")
  } else {
    return ''
  }
}

export function getConfigurationDomainBaseURL(configuration) {
  return getConfigurationDomainAPIBaseURL(configuration[ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI])
}

export function getConfigurationDomainAPIBaseURL(configurationDomainBaseIRI) {
  return configurationDomainBaseIRI+"api/"
}

export function getDataDomainBaseURL(configuration) {
  return configuration[ALIAS_SYS_DATA_DOMAIN_BASE_IRI]
}

export function getSystemContextURL() {
  return getEGSystemContextURL();
}

export function getDataContextFileName() {
  let repo = 'default';
  return 'api.jsonld'
}

export function getDataContextURL() {
  let endpointWithInstanceAndDataset = getEndpointWithInstanceAndDataset();
  if(!endpointWithInstanceAndDataset.endsWith("/")) {
    endpointWithInstanceAndDataset = endpointWithInstanceAndDataset + "/";
  }
  return endpointWithInstanceAndDataset + BACKEND_PATH_EG_CONTEXT + '/' + getDataContextFileName();
}

export function getProp(node, propName, isLang) {
  if(node && node[propName]) {
    if(isLang === false) {
      return node[propName]
    } else {
      return node[propName].en
    }
  } else {
    return "No title found!"
  }
}

export function sortByFunction(items, valueProvider , direction = 'asc') {
  items.sort((aItem, bItem) => {
    let a = direction === 'asc' ? aItem : bItem
    let b = direction === 'asc' ? bItem : aItem
    let valueB = valueProvider(b);
    let valueA = valueProvider(a);
    if(valueA === undefined ) {
      return 1;
    }
    if( valueB === undefined) {
      return -1;
    }

    if (valueB > valueA) {
      return -1;
    }
    if (valueB < valueA) {
      return 1;
    }
    return 0;
  });
  return items;
}

export function sort(items, propName = 'title', direction = 'asc') {
  items.sort((aItem, bItem) => {
    let a = direction === 'asc' ? aItem : bItem
    let b = direction === 'asc' ? bItem : aItem
    if(a[propName] === undefined ) {
      return 1;
    }
    if( b[propName] === undefined) {
      return -1;
    }

    if (b[propName] > a[propName]) {
      return -1;
    }
    if (b[propName] < a[propName]) {
      return 1;
    }
    return 0;
  });
  return items;
}

export function sortShapeProperties(items, aliasesMap) {
  items.sort((aItem, bItem) => {
    if(aItem[ALIAS_SH_PATH] == ID) {
      return -1;
    }

    let a = aliasesMap[aItem[ALIAS_SH_PATH]];
    let b = aliasesMap[bItem[ALIAS_SH_PATH]];

    if (b > a) {
      return -1;
    }
    if (b < a) {
      return 1;
    }
    return 0;
  });
  return items;
}

export function getAllOntologyData(response) {
  let allData = []
  let graph = getGraph(response);
  graph.forEach(ob => {
    if (isBNode(ob)) {
      // do not add
    } else if(isClass(ob) || isObjectProperty(ob) || isDatatypeProperty(ob) || isAnnotationProperty(ob) || isRdfProperty(ob)) {
      allData.push(ob);
    }
  })
  return allData;
}

export function getOntologyClasses(response) {
  return getGraph(response).filter(o => isClass(o))
}

export function getOntologyProperties(response) {
  return getGraph(response).filter(o => isObjectProperty(o) || isDatatypeProperty(o) || isAnnotationProperty(o) || isRdfProperty(o))
}

export function getObjectProperties(response) {
  return getGraph(response).filter(o => isObjectProperty(o) && !isBNode(o))
}

export function getDatatypeProperties(response) {
  return getGraph(response).filter(o => isDatatypeProperty(o) && !isBNode(o))
}

export function getAnnotationProperties(response) {
  return getGraph(response).filter(o => isAnnotationProperty(o) && !isBNode(o))
}

export function getRdfProperties(response) {
  return getGraph(response).filter(o => isRdfProperty(o) && !isBNode(o))
}

export function getShapesData(response) {
  return getGraph(response).filter(o =>isShapeClass(o) && !isBNode(o))
}

export function getAllProperties(response, filterValue) {
  let values = flatten(getShapesData(response).filter(o => o[ALIAS_SH_PROPERTY]).map(o => o[ALIAS_SH_PROPERTY])).map(o => o[ALIAS_SH_PATH]).filter(distinct)
  if(filterValue) {
    return values.filter(o => (o+"").includes(filterValue)).sort()
  } else {
    return values.sort()
  }
}

export function getContainerData(response) {
  return getGraph(response).filter(o =>isContainerClass(o) && !isBNode(o))
}

export function distinct(value, index, self) {
  return self.indexOf(value) === index;
}

export function getInverseData(response) {
  return getGraph(response).filter(o =>isPropertyClass(o) && !isBNode(o) && o[ALIAS_OWL_INVERSE_OF])
}

export function getAliasMappingData(response) {
  return response ? response[ALIAS_SYS_ALIAS_MAPPING] : undefined
}

export function getExtensionRulesData(response) {
  return getGraph(response).filter(o =>isExtensionRulesClass(o) && !isBNode(o))
}

export function getJSONLDContextsData(response) {
  return getGraph(response).filter(o =>isJSONLDContextClass(o) && !isBNode(o))
}

export function getApiConfigurationData(response) {
  return getGraph(response).filter(o =>isApiConfigurationClass(o) && !isBNode(o))
}

export function getApiConfigurationResource(response) {
  let r = getApiConfigurationData(response)
  return r && r[0] ? r[0] : {}
}

export function getClassPermissions(response) {
  let r = getGraph(response).filter(o => isOfType(o, [ALIAS_SYS_TYPE_CLASS_BASED_PERMISSION]))
  return r;
}

export function getOperationBasedPermissions(response) {
  let r = getGraph(response).filter(o => isOfType(o, [ALIAS_SYS_TYPE_OPERATION_BASED_PERMISSION]))
  return r;
}

export function getRoles(response) {
  let r = getGraph(response).filter(o => isOfType(o, [ALIAS_SYS_TYPE_ROLE]))
  return r;
}

export async function getApiConfiguration() {
  let configs = await getAllConfigurations()
  let configurationData = getApiConfigurationData(configs)
  if(configurationData && configurationData[0]) {
    return configurationData[0]
  } else {
    return undefined
  }
}

export function getGraph(response) {
  let graph = response && response[AT_GRAPH]
      ? response[AT_GRAPH]
      : (response && response[GRAPH] ? response[GRAPH] : undefined)

  return graph ? graph : [];
}

export function getSearchResult(response) {
  return response && response[ALIAS_SYS_RESULTS]
      ? response[ALIAS_SYS_RESULTS]
      : []
}

export function getConnectedResourcesObject(response) {
  return response && response[ALIAS_SYS_IS_CONNECTED_TO]
      ? response[ALIAS_SYS_IS_CONNECTED_TO]
      : {}
}

export function getShaclResult(response) {
  return response && response[ALIAS_SH_RESULT]
      ? response[ALIAS_SH_RESULT]
      : []
}

export function getShaclValidationFor(json, iri) {
  let graph = getShaclResult(json);
  return graph.filter(o => o[ALIAS_SH_RESULT_PATH] === iri)
      .map(m => m[ALIAS_SH_RESULT_MESSAGE].en);
}

export function isPreconditionFailed(response) {
  return response?.status === 412;
}

export function isRequestSuccessful(response) {
  return response?.status >= 200 && response?.status <= 299;
}

export function isBadRequestError(response) {
  return response.status === 400;
}

export function isClientError(response) {
  return response.status >= 400 && response.status <= 499;
}

export function isServerError(response) {
  return response.status >= 500;
}

export function isOntologyResource(o) {
  return isOwlClass(o) || isRdfsClass(o) || isPropertyClass(o)
}

export function isClass(o) {
  return isOwlClass(o) || isRdfsClass(o)
}

export function isOntology(o) {
  let types = [TYPE_OWL_ONTOLOGY, 'OwlOntology']
  return isOfType(o, types)
}

export function isOwlClass(o) {
  let types = [ALIAS_OWL_CLASS]
  return isOfType(o, types)
}

export function isRdfsClass(o) {
  let types = [ALIAS_RDFS_CLASS]
  return isOfType(o, types)
}

export function isShapeClass(o) {
  let types = [ALIAS_SH_SHAPE]
  return isOfType(o, types)
}

export function isContainerClass(o) {
  let types = [ALIAS_CONTAINER]
  return isOfType(o, types)
}

export function isPropertyClass(o) {
  let types = [ALIAS_OWL_OBJECT_PROPERTY, ALIAS_OWL_DATATYPE_PROPERTY]
  return isOfType(o, types)
}

export function isObjectProperty(o) {
  let types = [ALIAS_OWL_OBJECT_PROPERTY]
  return isOfType(o, types)
}

function createIRIToAliasMap(aliasesToIRIMap) {
  let aliasesMap = {}
  Object.keys(aliasesToIRIMap).map(k => aliasesMap[aliasesToIRIMap[k]] = k)
  return aliasesMap;
}

export function isObjectPropertyOrRDFConnectionProperty(o, aliasesToIRIMap) {
  let connectIds = [RDF_SUBJECT, RDF_PREDICATE, RDFS_SUB_PROPERTY_OF, RDFS_SUB_CLASS_OF, RDFS_DOMAIN, RDFS_RANGE, RDFS_SEE_ALSO, RDFS_IS_DEFINED_BY, RDFS_MEMBER]
  if(isObjectProperty(o) || connectIds.includes(getResourceId(o))) {
    return true;
  }
  if(isDatatypeProperty(o) || isAnnotationProperty(o)) {
    return false;
  }
  if(isRdfProperty(o) && aliasesToIRIMap) {
    let iriToAliasMap = createIRIToAliasMap(aliasesToIRIMap);
    let alias = iriToAliasMap[RDFS_RANGE] || RDFS_RANGE;
    let rangeValue = o[alias];
    if(rangeValue === undefined) {
      return false;
    }
    let isLiteral = toArray(rangeValue).includes(RDFS_LITERAL);
    return isLiteral ? false : true;
  }
  return false;
}

export function isDatatypeProperty(o) {
  let types = [ALIAS_OWL_DATATYPE_PROPERTY]
  return isOfType(o, types)
}

export function isAnnotationProperty(o) {
  let types = [ALIAS_OWL_ANNOTATION_PROPERTY]
  return isOfType(o, types)
}

export function isRdfProperty(o) {
  let types = [ALIAS_RDF_PROPERTY]
  return isOfType(o, types)
}

export function isExtensionRulesClass(o) {
  let types = [ALIAS_SYS_EXTENSION_RULE]
  return isOfType(o, types)
}

export function isApiConfigurationClass(o) {
  let types = [ALIAS_SYS_API_CONFIGURATION]
  return isOfType(o, types)
}

export function isJSONLDContextClass(o) {
  let types = [ALIAS_SYS_JSONLD_CONTEXT]
  return isOfType(o, types)
}

function isOfType(o, types) {
  return isArray(o.type) ? o.type.some(o => types.includes(o)) : types.includes(o.type)
}

export function isBNode(o) {
  return o.id.startsWith('_')
}

export function isBlankNodeId(id) {
  return id?.startsWith('_');
}


export function findInversePropertyObject(propertyID, ontology) {
  let inverseID;
  ontology.forEach(op => {
    if(op[ID] === propertyID && op[ALIAS_OWL_INVERSE_OF]) {
      inverseID = op[ALIAS_OWL_INVERSE_OF];
    } else if (op[ALIAS_OWL_INVERSE_OF] === propertyID) {
      inverseID = op[ID];
    }
  });
  let found = inverseID && ontology.find(op => op[ID] === inverseID);
  return found;
}

export function computeAllTopLevelClasses(ontology) {
  let ontologyData = ontology ? ontology : []
  // filter classes without superclass and not owl thing and not in OWL , rdf namespace
  let allTopClasses = ontologyData
      .filter(o => isClass(o))
      .filter(o => {
        //filter superclasses which are not in configuration ontology
        let superClasses = computeAllSuperClasses(o[ID], ontology).filter(id => {
          const find = ontologyData.find(od => getResourceId(od) === id);
          return find;
        })
        if(superClasses.length === 0 || (superClasses.length === 1 && superClasses[0] === TYPE_OWL_THING)) {
          return true;
        } else {
          return false;
        }
      })

      .map(c => c[ID])
  return allTopClasses;
}


export function computeAllSuperClasses(classIRI, ontology) {
  let ontologyData = ontology ? ontology : []
  let allOntologyClasses = ontologyData.filter(o => isNonBlankClass(o))
  return computeSuperClasses(allOntologyClasses, [classIRI], [])
}

export function flatten(array) {
  return [].concat.apply([], array)
}

export function flattenRecursive(items) {
  const flat = [];

  items.forEach(item => {
    if (Array.isArray(item)) {
      flat.push(...flatten(item));
    } else {
      flat.push(item);
    }
  });

  return flat;
}

function computeSuperClasses(allOntologyClasses, classIRIs, accumulator) {
  if(classIRIs && classIRIs.length > 0) {
    let superClassesArr = classIRIs.map(c => {
      let superClasses = [].concat.apply([], allOntologyClasses.filter(o => o.id === c).map(o => o[ALIAS_RDFS_SUBCLASS_OF]))
          .filter(o => o && typeof o === 'string')
      return superClasses
    })
    let classesNotInAccumulator = [].concat.apply([], superClassesArr).filter(c => !accumulator.includes(c)).filter(distinct)
    classesNotInAccumulator.forEach(c => {accumulator.push(c)})
    return computeSuperClasses(allOntologyClasses,  classesNotInAccumulator, accumulator)
  } else {
    return accumulator;
  }

}

export function isNonBlankClass(o) {
  return isClass(o) && !isBNode(o);
}

export function computeAllSubClasses(classIRI, ontology) {
  let ontologyData = ontology ? ontology : []
  let allClassesObject = ontologyData.filter(o => isNonBlankClass(o))
  let accumulator = []
  let allSubClasses = [classIRI]
  while(allSubClasses.length > 0) {
    let nextLevelAllSubClasses = []
    allSubClasses.forEach(c => {
      let subClasses = allClassesObject.filter(o => o[ALIAS_RDFS_SUBCLASS_OF] && o[ALIAS_RDFS_SUBCLASS_OF].includes(c)).map(o => o.id)
      let classeNotAcc = subClasses.filter(c => !accumulator.includes(c)).filter(distinct)
      classeNotAcc.forEach(c => {
        accumulator.push(c)
        nextLevelAllSubClasses.push(c)
      })
    })
    allSubClasses = nextLevelAllSubClasses
  }
  return accumulator
}

export function computeSubClasses(classIRI, ontology, sortProperty) {
  let ontologyData = ontology ? ontology : []
  let allClassesObject = ontologyData.filter(o => isNonBlankClass(o))
  let allSubClassesObjects = allClassesObject.filter(o => o[ALIAS_RDFS_SUBCLASS_OF] && o[ALIAS_RDFS_SUBCLASS_OF].includes(classIRI));
  let sorted = sortProperty
      ? sort(allSubClassesObjects, sortProperty)
      : allSubClassesObjects;
  const subClasses = sorted.map(o => o.id)
  return subClasses;
}

export function computeClassShape(classIRI, ontology, sortProperty) {
  const currentSubClasses = computeSubClasses(classIRI, ontology, sortProperty);

  const classNode = { classIRI, children: currentSubClasses };

  if (currentSubClasses.length === 0) {
    return classNode;
  }

  classNode.children.forEach((cls, i) => {
    const subClasses = computeClassShape(cls, ontology, sortProperty);
    classNode.children[i] = subClasses;
  });

  return classNode;
}

export function computeShapesForResource(resource, aliasesToIRIMap, configurations, ontology) {
  let resourceTypes = toArray(resource[TYPE]).map(t => aliasesToIRIMap[t] || t);
  return computeShapesForTypes(resourceTypes, aliasesToIRIMap, configurations, ontology);
}

export function computeShapesForTypes(classIris, aliasesToIRIMap, configurations, ontology) {
  let shapesData = getShapesData(configurations);
  let shapesForResource = classIris.map(rt => {
    let shapeForType = shapesData.find(sh => sh[ALIAS_SH_TARGET_CLASS] === rt);
    if (shapeForType) {
      let shapeWithSuperClasses = computeShapeWithSuperClasses(shapeForType, shapesData, ontology, true, false, true);
      return shapeWithSuperClasses;
    } else {
      console.log('No shape for type', rt);
    }
  });
  return shapesForResource;
}

export function findShapePropertyForAlias(shapes, propertyAlias, aliasesToIRIMap) {
  let found = shapes.map(sh => {
    let shapePropertyArray = getShapePropertyArray(sh);
    let propertyIRI = aliasesToIRIMap[propertyAlias] || propertyAlias;
    let found = shapePropertyArray.find(p => p[ALIAS_SH_PATH] === propertyIRI);
    return found;
  }).filter(p => p).find(p => p);
  return found;
}

export function computeClassesTree(ontology, aliasesMap, sortProperty) {
  const superClasses = computeAllTopLevelClasses(ontology);

  const classShape = superClasses.map(classIRI => computeClassShape(classIRI, ontology, sortProperty));

  classShape.forEach(shapeNode => {
    reshapeClassForTreeData(shapeNode, aliasesMap);
  })

  return sort(classShape, sortProperty);
}


export function reshapeClassForTreeData(classShape, aliasesMap = {}, parentClassID) {
  classShape.title = aliasesMap[classShape.classIRI];
  classShape.id = classShape.classIRI;
  classShape.tooltip = classShape.classIRI;
  classShape[TYPE] = ALIAS_OWL_CLASS;
  classShape.parentClassID = parentClassID;

  classShape.children.forEach(cls => {
    reshapeClassForTreeData(cls, aliasesMap, classShape.id);
  });

  classShape.children = sort(classShape.children);

  return classShape;
}

export function computePropertyOptionsForShape(shape, combinedShape, allOntologyProperties, ontology) {
  let existingShapeProps = getPropertyArray(shape).map(p => p[ALIAS_SH_PATH])
  let objectProperties = allOntologyProperties
      .filter(p => {
        // exclude existing properties
        let shapeHasProp = existingShapeProps.includes(p.id)
        if(shapeHasProp) {
          return false;
        }
        let propDomain = toArray(p[ALIAS_RDFS_DOMAIN])
        if(!propDomain || propDomain.length < 1) {
          // if no domain then property can be used
          return true;
        }
        let propDomainArr = [];

        //compute all subclasses of domain as any subclass can have property
        propDomain.forEach(pd => {
          propDomainArr.push(pd);
          computeAllSubClasses(pd, ontology).forEach(sc => propDomainArr.push(sc));
        })

        if(propDomainArr.includes(shape[ALIAS_SH_TARGET_CLASS])) {
          // If targetClass is in domain then property can be used
          return true;
        }
        return false;
      })
  return objectProperties
}

export function isPropertyObjectTypeProperty(property, ontology) {
  let found = ontology.find(o => o[ID] === property)
  return found ? isObjectProperty(found) : false
}

export function isPropertyDataTypeProperty(property, ontology) {
  let found = ontology.find(o => o[ID] === property)
  return found ? isDatatypeProperty(found) : false
}

export function getPropertyArray(backingObject) {
  let foundNodeProps = []
  if (backingObject && backingObject[ALIAS_SH_PROPERTY]) {
    if (isArray(backingObject[ALIAS_SH_PROPERTY])) {
      foundNodeProps = backingObject[ALIAS_SH_PROPERTY]
    } else {
      foundNodeProps = [backingObject[ALIAS_SH_PROPERTY]]
    }
  }
  return foundNodeProps;
}

export function toArray(value) {
  if(value !== undefined) {
    if (isArray(value)) {
      return value;
    } else {
      return [value]
    }
  } else {
    return []
  }
}

export function toIdsList(values) {
  return toArray(values).map(val => isObject(val) ? val[ID] : val );
}

export function isEmptyArray(array) {
  return array === undefined || array.length === 0;
}

export function computeShapeWithSuperClasses(shapeObject, allShapes, ontology, clone = true, addCombinedProperties = false, dedupe = true) {
  let shapeObj = clone ? cloneDeep(shapeObject) : shapeObject
  let targetClass = shapeObject[ALIAS_SH_TARGET_CLASS]
  let superClasses = computeAllSuperClasses(targetClass, ontology)
  getPropertyArray(shapeObj).forEach(p => {
    p[FROM_SHAPE] = shapeObj[ID]
  })
  superClasses.forEach(c => {
    let foundShape = allShapes.find(o => o[ALIAS_SH_TARGET_CLASS] === c);
    let foundNode = clone ? cloneDeep(foundShape) : foundShape;
    let foundNodeProps = getPropertyArray(foundNode);
    foundNodeProps.forEach(p => {
      let propToAdd = p
      propToAdd[FROM_SHAPE] = foundNode[ID]
      let shapeObjProperties = getPropertyArray(shapeObj);
      if(shapeObjProperties) {
        let existingProp = shapeObjProperties.find(p => p[ALIAS_SH_PATH] === propToAdd[ALIAS_SH_PATH]);
        // If property is not in list add or dedupe is not required
        if (!existingProp || dedupe === false) {
          shapeObj[ALIAS_SH_PROPERTY] = [...shapeObjProperties, propToAdd]
        }
        // If combined then add to combined
        if(existingProp && addCombinedProperties) {
          existingProp[COMBINED_WITH_PROPERTY] = [...toArray(existingProp[COMBINED_WITH_PROPERTY]), propToAdd];
        }
      } else {
        shapeObj[ALIAS_SH_PROPERTY] = []
        shapeObjProperties.push(propToAdd)
      }
    })
  })
  return shapeObj
}

export function computeShapePropertiesWithSuperClasses(shapeObject, allShapes, ontology, superClassesOnly = false, ) {
  let targetClass = shapeObject[ALIAS_SH_TARGET_CLASS];
  let superClasses = computeAllSuperClasses(targetClass, ontology);
  let allProperties = [];
  //let allPropertiesIRISet = new Set();
  if(superClassesOnly === false) {
    getPropertyArray(shapeObject).forEach(p => {
      p[FROM_SHAPE] = shapeObject[ID];
      allProperties.push(p);
    })
  }
  //getPropertyArray(shapeObject).forEach(p => allPropertiesIRISet.add(p[ALIAS_SH_PATH]));
  superClasses.forEach(c => {
    let foundShape = allShapes.find(o => o[ALIAS_SH_TARGET_CLASS] === c);
    let foundNode = foundShape;
    let foundNodeProps = getPropertyArray(foundNode);
    foundNodeProps.forEach(p => {
      let propToAdd = p
      propToAdd[FROM_SHAPE] = foundNode[ID]
      allProperties.push(propToAdd);
      //allPropertiesIRISet.add(propToAdd[ALIAS_SH_PATH]);
    })
  })
  return allProperties;
}

export function getAliasesMap(configs) {
  let apiConfigResource = getApiConfigurationResource(configs);
  return createAliasesMap(apiConfigResource);
}

export function createAliasesMap(apiConfigResource) {
  let aliasesMap = {};
  let data = getAliasMappingData(apiConfigResource);
  data && data.map(a => aliasesMap[a[ALIAS_SYS_IRI]] = a[ALIAS_SYS_ALIAS]);
  return aliasesMap;
}

export function getAliasToIRIMap(aliasesMap) {
  let reverseMap = {}
  Object.keys(aliasesMap).forEach((k) => {
    reverseMap[aliasesMap[k]] = k;
  });
  if(!reverseMap[TYPE]) {
    reverseMap[TYPE] = TYPE_RDF_TYPE;
  }
  return reverseMap;
}

export function getInverseNodeTooltip(node, aliasesMap) {
  let title = sortIdAndInverseOf(node, aliasesMap)
  return title[0].id + ' ' + title[1].id
}

export function getInverseNodeTitle(node, aliasesMap) {
  let title = sortIdAndInverseOf(node, aliasesMap)
  return title[0].label + ' : ' + title[1].label
}

export function sortIdAndInverseOf(node, aliasesMap) {
  return sort([node.id, node[ALIAS_OWL_INVERSE_OF]].map(o => ({id : o, label: aliasesMap[o]})), 'label');
}

export function searchInTree(id, treeData) {
  if(treeData) {
    let found = treeData.find(o => o.id === id);
    if (found) {
      return found;
    } else {
      let found = treeData.map(o => searchInTree(id, o.children)).find(o => o)
      if (found) {
        return found
      }
    }
  }
  return null;
}

export function createNodeForView(o, titleProvider) {
  let item = {};
  item.id = o.id;
  item.title = titleProvider(o);
  item.backingObject = o;
  return item;
}

export function createSelectOptionFromLabel(label, value, tooltip) {
  return {label: label, value: value, tooltip: tooltip};
}

export function createSelectOption(iri, aliasesMap) {
  return createSelectOptionFromLabel(aliasesMap[iri], iri, iri)
}

export function getDatatypeLabel(datatype) {
  let found = DATATYPE_SUGGESTIONS.find(d => d.value === datatype)
  return found && found.label ? found.label : datatype
}

export function getPropertyClasses(p) {
  if(p[ALIAS_SH_CLASS] === TYPE_RDF_LIST) {
    if(p[ALIAS_SH_PROPERTY] && p[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS]) {
      return [p[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS]]
    } else {
      return undefined
    }
  } else if(p[ALIAS_SH_CLASS]) {
    return [p[ALIAS_SH_CLASS]]
  } else if (p[ALIAS_SH_OR]) {
    return getShaclORClasses(p)
  } else {
    return undefined
  }
}

export function getShaclORClasses(p) {
  let classes = p[ALIAS_SH_OR]
      ? p[ALIAS_SH_OR].map(n => n[ALIAS_SH_CLASS]).filter(c => c)
      : []
  return classes.length > 0 ? classes : undefined
}

export function getTypes(classes, configurations) {
  const ontology = getOntologyClasses(configurations);
  let allClasses = flatten(classes.map(c => {
    let subClasses = [c, ...computeAllSubClasses(c, ontology)];
    return subClasses
  }));
  return [...new Set(allClasses)];
}

export function getPropertyTypes(p, configurations) {
  let classes = getPropertyClasses(p);
  return classes ? getTypes(classes, configurations) : [];
}

export function getSortedInverseNodes(response, aliasesMap) {
  let inverseData = getInverseData(response)
  let deduped = dedupeInverse(inverseData)
  let treeData = deduped.map(o => {
    return createNodeForView(o, ()=> {return [aliasesMap[o.id], aliasesMap[o[ALIAS_OWL_INVERSE_OF]]].sort()[0] })
  })
  treeData = treeData.map(d => ({id: d.id, [ALIAS_OWL_INVERSE_OF]: d.backingObject[ALIAS_OWL_INVERSE_OF]})).map(n => {
    let displayTitle = getInverseNodeTitle(n, aliasesMap);
    let tooltip = getInverseNodeTooltip(n, aliasesMap);
    let node = {
      ...n,
      tooltip: tooltip,
      displayTitle: displayTitle,
      searchTitle : displayTitle.toLowerCase()
    }
    return node;
  })
  let sorted = sort(treeData, 'displayTitle');
  return sorted;
}

export function dedupeInverse(inverseProps) {
  let allInverseProps = [];
  inverseProps.forEach(v => {
    if (!allInverseProps.find(ai => ai.id === v.id || ai.id === v[ALIAS_OWL_INVERSE_OF])) {
      allInverseProps.push(v)
    }
  });
  return allInverseProps;
}

export function getHeaderMap(response) {
  let headerMap = {};
  if(response && response.headers && response.headers.entries) {
    for (let [key, value] of response.headers.entries()) {
      headerMap[key] = value;
    }
  }
  return headerMap;
}

export function getRequestURL(response) {
  return response.request.url;
}

export function getRequestHeaderMapFromResponse(response) {
  if(response && response.request && response.request.options && response.request.options.headers) {
    return response.request.options.headers;
  } else {
    return undefined;
  }
}

export function getFetchTimeMillis(response) {
  let {request} = response;
  return request && request.fetchEndTime && request.startTime && request.fetchEndTime.getTime() - request.startTime.getTime();
}

export function byteSize(string, decimals = 2) {
  return formatBytes(new Blob([string]).size, decimals);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getCURLCode(response) {
  let requestHeaders = response.request.options.headers
  let requestURL = getRequestURL(response)
  return 'curl - X GET '+ requestURL+ Object.keys(requestHeaders).map(k => ` -H ${k}:${requestHeaders[k]}`)
}

export function isObjectShapeProperty(property) {
  if(!property) {
    return false;
  }
  return property[ALIAS_SH_CLASS] || property[ALIAS_SH_OR] || property[ALIAS_SH_NODE_KIND] === SH_IRI ? true : false;
}

export function isConnectionProperty(property) {
  let propertyClasses = getPropertyClasses(property);
  return propertyClasses && propertyClasses.length > 0 ? true : false;
}

export function getShapePropertyArray(shape) {
  if(shape && shape[ALIAS_SH_PROPERTY]) {
    let properties = isArray(shape[ALIAS_SH_PROPERTY]) ? shape[ALIAS_SH_PROPERTY] : [shape[ALIAS_SH_PROPERTY]];
    return properties;
  } else {
    return []
  }
}

export function toConnectedQuery(mixin) {


}

export function toGraphQL(connection, recursiveProp='filters', addProperties = false) {
  let filters = toArray(connection[recursiveProp]);
  let properties = addProperties === true ? toArray(connection.properties) : [];
  let hasValue = filters.filter(f => f.value).length > 0 || properties.length > 0;
  if(hasValue === false) {
    return "";
  }

  let acc = '';
  if(hasValue) {
    acc = '{';
  }
  properties.forEach(p => {
    // add to selection only if there are no further selections
    // otherwise iteration over filters is going to add it
    let found = filters.find(f => f.value === p.value)
    if( found === undefined) {
      acc = acc + ' ' + p.value + ' ';
    }
  })
  filters.filter(f => f.value).forEach(f => {
    acc = acc + ' ' + f.value + ' ';
    if(f[ORDER] || f[ORDER_PRECEDENCE] || f[ORDER_NO_VALUE] || f[ORDER_LANG]) {
      acc = acc + '('
          + (f[ORDER] ? `${ORDER}:"${f[ORDER].value}"` : '')
          + (f[ORDER_PRECEDENCE] ? ` ${ORDER_PRECEDENCE}:${f[ORDER_PRECEDENCE]}` : '')
          + (f[ORDER_NO_VALUE] ? ` ${ORDER_NO_VALUE}:"${f[ORDER_NO_VALUE]}"` : '')
          + (f[ORDER_LANG] ? ` ${ORDER_LANG}:"${f[ORDER_LANG]}"` : '')
      + ') ';
    }
    if(f[LABEL_PROPERTY]) {
      acc = acc + `(${LABEL_PROPERTY}:"${f[LABEL_PROPERTY].value}")`;
    }
    let nestedQuery = toGraphQL(f, recursiveProp, addProperties);
    acc = acc + (nestedQuery ? nestedQuery : '');
  })
  if(hasValue) {
    acc = acc + '}';
  }
  return acc ;
}

export function getSearchQuery(search) {
  return `{${getTextQuery(search.textSearch)} ${getQuery(search.filters, '')} }`
}

export function getTextQuery(textSearch) {
  if(textSearch && textSearch.q && textSearch.q.length > 0 && textSearch.q.find(v => v) ) {
    let lang = textSearch.lang ? ` ${getLangFilter(textSearch.lang)}` : ''
    let props = textSearch.props && textSearch.props.length > 0 ? ` props:["${textSearch.props.map(o => o.label).join('" "')}"]` : ''
    let joinedValue = toArray(textSearch.q).map(v => v.replace(/"/g, '\\"')).join('" "');
    return ` textSearch(q:["${joinedValue}"]${lang}${props})`
  } else {
    return ''
  }
}

export function isDateTimeType(f) {
  return isFilterOfType(f, XSD_DATETIME);
}

export function isDateType(f) {
  return isFilterOfType(f, XSD_DATE);
}

export function isTimeType(f) {
  return isFilterOfType(f, XSD_TIME);
}

function isFilterOfType(f, type) {
  return f.property && f.property.value && [type].includes(f.property.value[ALIAS_SH_DATATYPE]);
}

export function resetSearchRequestValues(searchRequest) {
  let {filters, textSearch} = searchRequest.search;
  resetTextSearch(textSearch);
  resetFilters(filters);
}
export function resetTextSearch(textSearch) {
  textSearch.q = [];
  textSearch.lang = undefined;
}

export function excludeHidden(filters) {
  return filters.filter(f => f.hidden !== true);
}

export function resetFilters(filters) {
  excludeHidden(filters).forEach(f => {
    let {operator} = f;
    let operatorValue = operator && operator.value ? operator.value : '';
    if(operatorValue === OBJECT) {
      resetFilters(f.value);
    } else {
      if(isArray(f.value)) {
        if(f.value.length > 0) {
          f.value = [];
        } else {
          f.value = [];
        }
      } else {
        f.value = '';
      }
    }

  })
}

export function typesOptionsForLanguage(typesOptions, aliasesToIRIMap, ontology, browseLanguage) {
  return typesOptions.map(t => {
    let propertyName = getPropertyName(aliasesToIRIMap, t.value, ontology, browseLanguage);
    t.label = propertyName;
    return t;
  });
}

export function getPropertyNameFrom(propertyKey, customizations) {
  return getPropertyName(customizations.aliasesToIRIMap, propertyKey, customizations.ontology, customizations.browseLanguage);
}

export function getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage, prefix=true) {
  let propertyIRI = aliasesToIRIMap[propertyKey] || propertyKey;
  if (propertyIRI) {
    let ontologyProperty = ontology.find(op => op[ID] === propertyIRI);
    if (ontologyProperty) {
      let title = getMultilingualValue(ontologyProperty[ALIAS_RDFS_LABEL], browseLanguage);
      if (!title) {
        title = getMultilingualValue(ontologyProperty[ALIAS_RDFS_LABEL], 'en');
      }
      if (title) {
        return title;
      }
    }
  }
  // if property is URL
  if (propertyKey && VALIDATION_URL.test(propertyKey)) {
    return getLocalName(propertyKey, prefix);
  }
  return propertyKey;
}

export function graphqlStringToObjectValue(graphql, filters, textSearch, typesOptions) {
  try {
    let parsedQuery = parse(graphql);
    let definition = parsedQuery.definitions[0];
    let selectionSet = definition.selectionSet;
    walkDefinitionAndFillObject(selectionSet, filters, textSearch, typesOptions, definition);
    let parsedPrintQuery = print(parsedQuery);
    return parsedPrintQuery;
  } catch (e) {
    console.log("Failed to parse graphQL", e)
    return "";
  }

}

export function walkDefinitionAndFillObject(selectionSet, filters, textSearch, typesOptions, parent) {
  selectionSet.selections.forEach(selection => {
    let selectionName = selection.name.value;
    if(selectionName === 'textSearch') {
      let qArgument = selection.arguments  && selection.arguments.find(a => a.name.value === TEXT);
      if(qArgument) {
        let valuesArray = flatten(getGraphqlArgumentToValue(qArgument));
        textSearch.q = valuesArray;
      }
      return;
    }
    let filter = excludeHidden(filters).find(f => {
      let isLabelMatch = f.property.label === selectionName;
      if(isLabelMatch) {
        let {property, operator, value} = f;
        let operatorValue = operator && operator.value ? operator.value : '';

        //For object check value deep
        if(operatorValue === OBJECT && hasValue(f) === false) {
          return true;
        }
        if(operatorValue === EXISTS) {
          return hasValue(f);
        }
        let sArgument = selection.arguments  && selection.arguments.find(a => a.name.value === operatorValue);
        if(sArgument) {
          //If the value is already set it means there might be another selection with same name
          if(hasValue(f) === false) {
            return true;
          }
        }
      }
      return false;

    });
    let {property, operator, value} = filter;
    let operatorValue = operator && operator.value ? operator.value : ''
    if(operatorValue === BETWEEN) {
      let selectionsSubSet = parent.selectionSet && parent.selectionSet.selections && parent.selectionSet.selections.length > 0
          &&  parent.selectionSet.selections.filter(sl => sl.name.value === selectionName);
      selectionsSubSet && selectionsSubSet.forEach(sl => {
        let gteArgument = sl.arguments && sl.arguments.find(a => a.name.value === GREATER_THAN_EQUAL_TO);
        if(gteArgument && gteArgument.value) {
          filter.value = gteArgument.value.value;
        }
        let lteArgument = sl.arguments && sl.arguments.find(a => a.name.value === LESS_THAN_EQUAL_TO);
        if(lteArgument && lteArgument.value) {
          filter[VALUE_END_KEY] = lteArgument.value.value;
        }
      });
    } else if(operatorValue === EXISTS) {
      filter.value = true;
    } else if(selection.selectionSet && operatorValue === OBJECT) {
      walkDefinitionAndFillObject(selection.selectionSet, filter.value, textSearch, typesOptions, selection)
    } else {
      if(isArray(value)) {
        let valuesArray = flatten(selection.arguments.map(a => getGraphqlArgumentToValue(a)));
        if(property.value === TYPE) {
          valuesArray = valuesArray.map(v => typesOptions.find(to => to.value === v));
        }
        filter.value = valuesArray;
      } else {
        filter.value = getGraphqlArgumentToValue(selection.arguments[0]);
      }
    };
  })
}

function getGraphqlArgumentToValue(argument) {
  if(argument.value.values) {
    return argument.value.values.map(v => v.value);
  } else {
    return argument.value;
  }
}

export function hasValue(f) {
  let {value, operator} = f;
  let operatorValue = operator && operator.value ? operator.value : ''
  if (operatorValue === OBJECT) {
    let anyHasValue = toArray(value).find(v => hasValue(v));
    return anyHasValue ? true : false;
  } else if (operatorValue === EXISTS) {
    if(value === false) {
      return false;
    }
    return true;
  } else {
    let joinedValue = getStringValue(value, f);
    if (joinedValue.trim() === "" || joinedValue.trim() === '""') {
      return false;
    }
    return true;
  }
}

export function getQuery(filters, acc) {
  filters && filters.filter(f => !isFilterItemEditInProgress(f)).forEach(f => {
    let hasValueBoolean = hasValue(f);
    if(hasValueBoolean === false) {
      return;
    }
    let {property, operator, value, lang, dataType} = f
    let propLabel = property ? property.label : ''
    let operatorValue = operator && operator.value ? operator.value : ''
    let joinedValue = getStringValue(value, f)
    acc = acc + ' '
    if(operatorValue === EXISTS) {
      if(value !== false) {
        acc = acc + ' ' + propLabel;
      }
    } else if (operatorValue === EQUAL_TO || operatorValue === NOT_EQUAL_TO) {
        acc = acc + `${propLabel}(${operatorValue}:[${joinedValue}] ${getLangFilter(lang)} ${getDatatypeFilter(dataType)})`
    } else if (operatorValue === OBJECT) {
      acc = acc + `${propLabel}{ ${getQuery(value, '')} }`
    } else if (operatorValue === OBJECT_INVERSE) {
      acc = acc + `eg_reverse_edge {${propLabel}{ ${getQuery(value, '')} }}`
    } else if(operatorValue === TEXT) {
      acc = acc + `${propLabel}(q:[${joinedValue}] ${getLangFilter(lang)})`
    } else if(operatorValue === BETWEEN) {
      acc = acc + `${propLabel}(${GREATER_THAN_EQUAL_TO}:${joinedValue} ${getLangFilter(lang)} ${getDatatypeFilter(dataType)})`
      let joinedValueEnd = getStringValue(f[VALUE_END_KEY], f)
      if(joinedValueEnd) {
        acc = acc + ` ${propLabel}(${LESS_THAN_EQUAL_TO}:${joinedValueEnd} ${getLangFilter(lang)} ${getDatatypeFilter(dataType)})`
      }
    } else {
      acc = acc + `${propLabel}(${operatorValue}:${joinedValue} ${getLangFilter(lang)} ${getDatatypeFilter(dataType)})`
    }
  })
  return acc;
}

function getLangFilter(lang) {
  return `${lang === undefined || Object.keys(lang).length === 0 ? '' : ' lang:"'+ (isString(lang) ? lang : lang.value)+'"'}`;
}

function getDatatypeFilter(dataType) {
  return `${dataType === undefined || Object.keys(dataType).length === 0 ? '' : ' dataType:["'+dataType.value+'"]'}`;
}

function getStringValue(value, filter, valueKey = 'value') {
  if(value) {
    let arrayVal = isArray(value) ? value : [value]
    let stringValue = arrayVal.map(v =>{
      if(isDateTimeType(filter)) {
        let dateObj = isString(v) ? new Date(v) : v;
        let formatted = formatDateTime(dateObj);
        return formatted;
      } else if(isDateType(filter)) {
        let dateObj = isString(v) ? new Date(v) : v;
        return formatDate(dateObj);
      } else if (isTimeType(filter)) {
        let dateObj = isString(v) ? new Date(v) : v;
        return formatTime(dateObj);
      } else {
        return v;
      }
    }).map(v => isObject(v) ? v[valueKey] : v).join('" "')
    return `"${stringValue}"`;
  } else {
    return '';
  }
}

export function formatDateTime(value) {
  try {
    return format(value, DATETIME_FORMAT);
  } catch (e) {
    traceDebug(() =>"Failed to format date "+value, MODULE_NAME);
    return '';
  }
}

export function formatDate(value) {
  try {
    return format(value, DATE_FORMAT);
  } catch (e) {
    traceDebug(() => "Failed to format date "+value, MODULE_NAME);
    return '';
  }
}

export function formatTime(value) {
  try {
    return format(value, TIME_FORMAT);
  } catch (e) {
    traceDebug( () =>"Failed to format date "+value, MODULE_NAME);
    return '';
  }
}

export function isInValidForDataView(filters) {
  if (!filters || filters.length === 0) {
    return false;
  }
  let inProgress = filters.find(f => !f.property);
  if(inProgress) {
    return true;
  } else {
    let objEdit = filters.filter(f =>  f.operator && f.operator.value === OBJECT).find(f =>{
      return isInValidForDataView(f.value)
    })
    return objEdit ? true : false;
  }
}

export function isFilterEditInProgress(filters) {
  if(!filters || filters.length === 0) {
    return false;
  }

  let inProgress = filters.filter(f => !f.operator || f.operator.value !== OBJECT).find(f => {
        let operatorValue = f.operator ? f.operator.value : undefined
        if(!f.property || !operatorValue) {
          return true;
        } else if(operatorValue === EXISTS) {
          return false;
        } else if(operatorValue === EQUAL_TO) {
          let value = f.value
          return  !value || value.includes('');
        } else if(operatorValue === TEXT) {
          return  f.value === '';
        } else {
          let value = f.values
          return  value === '';
        }
      }
  )
  if(inProgress) {
    return true;
  } else {
    let objEdit = filters.filter(f =>  f.operator && f.operator.value === OBJECT).find(f =>{
      return isFilterEditInProgress(f.value)
    })
    return objEdit ? true : false
  }
}

export function isFilterItemEditInProgress(f) {
  let operatorValue = f.operator ? f.operator.value : undefined
  if(!f.property || !operatorValue) {
    return true;
  } else if(operatorValue === EXISTS) {
    return false;
  } else if(operatorValue === EQUAL_TO) {
    let value = f.value;
    if(value === undefined || (isArray(value) === false && value.trim() === '')) {
      return true;
    }
    if(isArray(value) && value.includes('')) {
      return true;
    }
    return  false;
  } else if(operatorValue === TEXT) {
    return  f.value === '';
  } else {
    let value = f.values
    return  value === '';
  }
}

export function difference(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

export function createContainerPayload(label, clazz, isIdentifier) {
  let container = {}
  container.id = WRITABLE_CLASS_BASE_IRI+uuid4()
  container.type = ALIAS_CONTAINER
  container[ALIAS_SYS_ID_LABEL] = label
  container[ALIAS_SYS_CLASS_IRI] = clazz
  container[ALIAS_SYS_IDENTIFIER] = isIdentifier+""
  return container;
}

export function isSystemDefinedAlias(iri, aliasGenerationResponse) {
  let aliasesMapData = getAliasMappingData(aliasGenerationResponse);
  let aliasItem = aliasesMapData.find(d => d[ALIAS_SYS_IRI] === iri);
  return aliasItem && aliasItem[ALIAS_SYS_IS_SYSTEM_ALIAS] && aliasItem[ALIAS_SYS_IS_SYSTEM_ALIAS] === 'true' ? true : false;
}

export function setCookie(cname, cvalue, expireSeconds) {
  let d = new Date();
  d.setTime(d.getTime() + (expireSeconds * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getIdGeneratingClassIRIs(classes) {
  return classes
      ? classes.filter(c => c[ALIAS_SYS_IDENTIFIER] === 'true').map(ic => ic[ALIAS_SYS_CLASS_IRI])
      : [];
}

export function getIdGeneratingClassIRIsSetWithSubclasses(containerData, ontology) {
  let instantiableClasses = new Set();
  containerData.filter(c => c[ALIAS_SYS_IDENTIFIER] === 'true').forEach(container => {
    instantiableClasses.add(container[ALIAS_SYS_CLASS_IRI])
    computeAllSubClasses(container[ALIAS_SYS_CLASS_IRI], ontology).forEach(c => instantiableClasses.add(c))
  })
  return instantiableClasses;
}

export function isReadOnlyAPI(containers) {
  return getIdGeneratingClassIRIs(containers).length <= 0;
}

export function getMaxOntologyFileSize() {
  return (VALIDATION_ONTOLOGY_FILE_MAX_SIZE / (1024 * 1024))+"MB"
}

export function getMaxExampleSetFileSize() {
  return (VALIDATION_EXAMPLE_SET_FILE_MAX_SIZE / (1024 * 1024))+"MB"
}

export function getMaxSiteFileSize() {
  return (VALIDATION_SITE_FILE_MAX_SIZE / (1024 * 1024))+"MB"
}

export function getMaxFeedbackFileSize() {
  return (VALIDATION_FEEDBACK_FILE_MAX_SIZE / (1024 * 1024))+"MB"
}

export function getContentType(file) {
  let contentType = contentTypeMap[getFileExtension(file.name)]
  return contentType;
}

export function formatValidationMessage(message, params = {}) {
  let val = message;
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      val = val.replace(`{{${key}}}`, params[key]);
    }
  }

  return val;
}

export function restrictMaximumCharacters(event, maxLength) {
  let {target:{value}} = event
  if(value && value.length > maxLength) {
    event.target.value = value.substring(0, maxLength + 1)
  }
  return event
}

export function isRequiredProperty(property) {
  return property[ALIAS_SH_MIN_COUNT] === '1'
}

export function initPropertyValue(p) {
  let minCount = computeMinCount(p);
  if (p[ALIAS_SH_MAX_COUNT] === '1') {
    // do not use array
  } else {
    p.value = []
    p.onMock = []
  }
  if (minCount) {
    if (p[ALIAS_SH_MAX_COUNT] === '1') {
      p.value = "";
    } else {
      for (let i = 0; i < minCount; i++) {
        p.value[i] = "";
      }
    }
  }
}

export function createFormFromShape(depth, containerClass, configurations, aliasesMap, onChange, valueObject, customizations) {
  const ontology = getOntologyClasses(configurations)
  const shapes = getShapesData(configurations)

  let superClasses = [containerClass, ...computeAllSuperClasses(containerClass, ontology)];
  let containers = getContainerData(configurations)
  let labelNode = superClasses.map(c => containers.find(cn => cn[ALIAS_SYS_CLASS_IRI] === c)).find(c => c)
  let label = labelNode[ALIAS_SYS_ID_LABEL]
  const shapeObj = shapes.find(o => o[ALIAS_SH_TARGET_CLASS] === containerClass)
  let jsonObj = computeShapeWithSuperClasses(shapeObj, shapes, ontology, true, true);
  jsonObj.label = label
  let properties = []
  let shapePropertyArray = getShapePropertyArray(jsonObj);
  shapePropertyArray.forEach((p) => {
    let label = <FieldLabel json={JSON.stringify(p, null, 4)} property={p} aliasesMap={aliasesMap} />
    initPropertyValue(p);
    let component = <ArrayType depth={depth} onChange={onChange} label={label} property={p} shape={jsonObj} aliasesMap={aliasesMap} ontology={ontology} containers={containers} configurations={configurations} customizations={customizations}></ArrayType>
    p.component = component

  })
  let idProp = {
    [ID] : ID,
    [ALIAS_SH_PATH] : 'id'
  }
  if(valueObject) {
    idProp.value = valueObject[ID]
  }
  idProp.component = <IdType key={containerClass+'-'+depth} containerClass={containerClass} label={label} depth={depth} property={idProp} onChange={onChange} configurations={configurations} customizations={customizations}></IdType>

  let typeProp = {
    [ID] : TYPE,
    [ALIAS_SH_PATH] : 'type',
    component : <></>,
    value : aliasesMap[containerClass]
  }
  properties = [idProp, typeProp, ...getShapePropertyArray(jsonObj)]
  jsonObj[ALIAS_SH_PROPERTY] = properties
  return jsonObj;
}

export function computeMinCount(property) {
  return computeCombined(property, ALIAS_SH_MIN_COUNT, Math.max);
}

export function computeMaxCount(property) {
  return computeCombined(property, ALIAS_SH_MAX_COUNT, Math.min);
}

export function computeMaxLength(property) {
  return computeCombined(property, ALIAS_SH_MAX_LENGTH, Math.min);
}

export function computeMinLength(property) {
  return computeCombined(property, ALIAS_SH_MIN_LENGTH, Math.min);
}

export function getRegEx(property) {
  let allProps = [property, ...toArray(property[COMBINED_WITH_PROPERTY])];
  let valueArray = allProps.filter(p => p[ALIAS_SH_PATTERN]).map(p => p[ALIAS_SH_PATTERN]);
  return valueArray;
}

function computeCombined(property, key, reducer) {
  let allProps = [property, ...toArray(property[COMBINED_WITH_PROPERTY])];
  let valueArray = allProps.filter(p => p[key]).map(p => p[key]);
  if(valueArray && valueArray.length > 0) {
    return reducer(...valueArray);
  } else {
    return undefined;
  }

}

export function addBorder(depth, component) {
  return <div style={{marginTop: '8px', borderRadius: '4px', border : '2px solid #F5F5F5'}}>{component}</div>;
}


export function renderFormFromShape(shapeForm, depth, configurations, aliasesMap, onChange) {
  const ontology = getOntologyClasses(configurations)
  const containers = getContainerData(configurations)

  let jsonObj = shapeForm
  let label = jsonObj.label
  let containerClass = shapeForm[ALIAS_SH_TARGET_CLASS]
  let propertiesToInitialise = getShapePropertyArray(jsonObj).filter(p => ![ID, TYPE].includes(p[ALIAS_SH_PATH]));
  propertiesToInitialise.forEach((p) => {
    if(p.component) {
      return ;
    }
    let label = <FieldLabel json={JSON.stringify(p, null, 4)} property={p} aliasesMap={aliasesMap} />
    if(p[ALIAS_SH_MAX_COUNT] === '1') {
      //do nothing
    } else {
      p.onMock = []
    }
    let component = <ArrayType depth={depth} onChange={onChange} label={label} property={p} shape={jsonObj} aliasesMap={aliasesMap} ontology={ontology} containers={containers} configurations={configurations}></ArrayType>
    p.component = component
  })
  let idProp = {
    [ID] : ID,
    [ALIAS_SH_PATH] : 'id'
  }

  let idProperty = getShapePropertyArray(jsonObj).find(p => p[ALIAS_SH_PATH] === ID);
  let idValue = idProperty && idProperty.value
  idProp.value = idValue
  idProp.component = <IdType key={containerClass+'-'+depth} containerClass={containerClass} label={label} depth={depth} property={idProp} onChange={onChange} configurations={configurations}></IdType>

  let typeProp = {
    [ID] : TYPE,
    [ALIAS_SH_PATH] : 'type',
    component : <></>,
    value : aliasesMap[containerClass]
  }
  let properties = [idProp, typeProp, ...propertiesToInitialise]
  jsonObj[ALIAS_SH_PROPERTY] = properties
  return jsonObj;
}

export function getPropertyValueForForm(props) {
  let {valueIndex, property} = props
  let value = valueIndex !== undefined ? property.value[valueIndex] : property.value
  return value
}

export function registerMock(valueIndex, property, onMock) {
  if(isArray(property.value)) {
    if(property.onMock) {
      property.onMock[valueIndex] = onMock;
    }
  } else {
    property.onMock = onMock;
  }
}

export function invokeMock(shapeForm, eventName) {
  if(shapeForm && shapeForm[ALIAS_SH_PROPERTY]) {
    return shapeForm[ALIAS_SH_PROPERTY].filter(p => p.onMock).map(p => {
      if (isArray(p.onMock)) {
        return p.onMock.map(m => m(eventName));
      } else {
        return p.onMock(eventName);
      }
    });
  }
}

export function createHTTPRequestPayload(shapeForms, aliasesMap) {
  let payloads = shapeForms
      ? shapeForms.map(form => createPayloadObjectRecursive(form, aliasesMap))
      : []
  let payload = {
    [AT_CONTEXT] : [VARIABLE_DATA_CONTEXT_URL],
    [GRAPH] : payloads
  };
  return payload;
}

export function createPayloadObjectForProperty(p, payload, aliasesMap, undefinefToNull = false) {
  if (p[ALIAS_SH_PATH] === ID) {
    if (p.value) {
      payload.id = p.value
    }
  } else if (p[ALIAS_SH_PATH] === TYPE) {
    payload.type = p.value
  } else if (p.value && isArray(p.value)) {
    if (p.value.length > 0) {
      if (p[ALIAS_SH_DATATYPE] === RDF_LANGSTRING) {
        let mergedObj = {}
        p.value.filter(v => v).forEach(o => {
          let langKeys = Object.keys(o).filter(k => k)
          if (langKeys.length > 0) {
            langKeys.forEach(lk => {
              let langValues = mergedObj[lk]
              let newLangVal = o[lk]
              if (isArray(langValues)) {
                langValues.push(newLangVal)
              } else if (langValues) {
                mergedObj[lk] = [langValues, newLangVal]
              } else {
                mergedObj[lk] = newLangVal
              }
            })
          }
        })
        payload[aliasesMap[p[ALIAS_SH_PATH]]] = mergedObj
      } else {
        payload[aliasesMap[p[ALIAS_SH_PATH]]] = p.value.map(v => {
          if (isObject(v)
              && p[ALIAS_SH_DATATYPE] !== RDF_LANGSTRING
              && p[ALIAS_SH_NODE_KIND] !== SH_LITERAL) {
            return createPayloadObjectRecursive(v, aliasesMap)
          } else {
            return v;
          }
        })
      }
    } else if(undefinefToNull === true) {
      payload[aliasesMap[p[ALIAS_SH_PATH]]] = null;
    }
  } else if (p.value && isObject(p.value)
      && p[ALIAS_SH_DATATYPE] !== RDF_LANGSTRING
      && p[ALIAS_SH_NODE_KIND] !== SH_LITERAL
  ) {
    payload[aliasesMap[p[ALIAS_SH_PATH]]] = createPayloadObjectRecursive(p.value, aliasesMap);
  } else if (p.value === undefined && undefinefToNull === true) {
    payload[aliasesMap[p[ALIAS_SH_PATH]]] = null;
  } else {
    payload[aliasesMap[p[ALIAS_SH_PATH]]] = p.value;
  }
}

function createPayloadObjectRecursive(shapeForm, aliasesMap) {
  let payload = {}
  sortShapeProperties(getShapePropertyArray(shapeForm), aliasesMap).forEach(p => {
    createPayloadObjectForProperty(p, payload, aliasesMap);
  })
  return payload;
}

export function createGraphQLVarId(payload, index) {
  return payload[TYPE]+'_'+ (payload[ID] ? extractUUIDForGraphQLFromID(payload[ID]) : index);
}

export function createGraphQLQueryPayload(searchRequestObject) {
  let {query, lang, labelPropertyLang, facet, page, pageSize, countUpto, sortBy, sortDirection } = searchRequestObject.paramMap
  let {search} = searchRequestObject
  let classes = []
  if(search.filters) {
    let typeFilters = search.filters.filter(f => f.property && f.property.value === TYPE && f.operator && f.operator.value === EQUAL_TO)
    if(typeFilters) {
      typeFilters.forEach(f => {
        toArray(f.value).map(v => v.value).forEach(v => {
          classes.push(v);
        })
      })
    }
  }
  let searchQuery = `
  query search (
    ${query ? '$query:String!' : ''} 
    ${page ? '$page: Int = 1' : ''} 
    ${pageSize ? '$pageSize: Int = 10' : ''}
    ${countUpto ? '$countUpto: Int = 10' : ''}
    ${sortBy ? '$sortBy: String!' : ''}
    ${sortDirection ? '$sortDirection: String!' : ''}
    ${lang ? '$lang: String!' : ''}
    ${facet ? '$facet: String!' : ''}
    ${facet && labelPropertyLang ? '$labelPropertyLang: String!' : ''}
  ) {
    search(
      ${query ? 'query:$query' : ''} 
      ${page ? 'page:$page' : ''} 
      ${pageSize ? 'pageSize:$pageSize' : ''}
      ${countUpto ? 'countUpto:$countUpto' : ''}
      ${sortBy ? 'sortBy:$sortBy' : ''}
      ${sortDirection ? 'sortDirection:$sortDirection' : ''}
      ${lang ? 'lang:$lang' : ''}
    ) @hint(queryType: PATHS) {
      egHasNextPage
      egHasMoreToCount
      egTotalCount
      ${
      facet ? 
      `egFacets(query: $facet ${labelPropertyLang ? `labelPropertyLang:$labelPropertyLang` : ''}) {
        egFacetLabel
        egFacetResults {
          egFacetValue {
            type
            value
          }
          egFacetValueCount
          egFacetValueLabel {
            lang
            value
          }
        }
      }`
      : ''    
      }
      egResults  {
        __typename
        ${
          classes.map(c => {
            return '... on '+c+' {id}';
          }).join('\n')
        }
      }
    }
  }`
  let variables = searchRequestObject.paramMap

  let prettyQuery = prettifyGraphQLQuery(searchQuery)
  return {
    query: prettyQuery,
    variables: stringifyPatchReqBody(true, variables)
  }
}

export function createGraphQLMutationPayload(shapeForms, leftMenuMinimized, aliasesMap) {
  let payloadList = [];
  createPayloadObjectForGraphQLRecursive(shapeForms, payloadList, aliasesMap);
  return createGraphQLMutation('create', payloadList, leftMenuMinimized);
}

export function createGraphQLMutation(mutationType, payloadList, leftMenuMinimized) {
  let mutationQuery = `mutation ${mutationType}(\n ${payloadList.map((p, index) => {
    return `${'$'+createGraphQLVarId(p, index)}:${p[TYPE]}Input `
  }).join('\n ')}\n) {
        ${payloadList.map((p, index) => {
    return `\n ${createGraphQLVarId(p, index)} : ${mutationType}_${p[TYPE]}(input:${'$'+createGraphQLVarId(p, index)}) {\n  ${ID}\n  ${ALIAS_SYS_ETAG}\n }`;
  }).join('\n')}
        \n}`
  let variables = {}
  payloadList.forEach((p, index) => {
    let varID = createGraphQLVarId(p, index)
    variables[varID] = p
  })

  let prettyQuery = prettifyGraphQLQuery(mutationQuery);
  return {
    query: prettyQuery,
    variables: stringifyPatchReqBody(leftMenuMinimized, variables)
  };
}

export function createGraphQLMutationForPatch(mutationType, payloadMap, idTypeMap, leftMenuMinimized) {
  let mutationQuery = `mutation ${mutationType}(\n ${Object.keys(payloadMap).map((k, index) => {
    return `${'$'+k}:${mutationType === GRAPHQL_DELETE_OPERATION ? GRAPHQL_DELETE_OPERATION+'_' : ''}${idTypeMap[payloadMap[k][ID]]}Input `
  }).join('\n ')}\n) {
        ${Object.keys(payloadMap).map((k, index) => {
    return `\n ${k} : ${mutationType}_${idTypeMap[payloadMap[k][ID]]}(input:${'$'+k}) {\n  ${ID}\n  ${ALIAS_SYS_ETAG}\n }`;
  }).join('\n')}
        \n}`
  let variables = {}


  let prettyQuery = prettifyGraphQLQuery(mutationQuery);
  return {
    query: prettyQuery,
    variables: stringifyPatchReqBody(leftMenuMinimized, payloadMap)
  };
}

function createPayloadObjectForGraphQLRecursive(shapeForms, payloadList, aliasesMap) {
  if (shapeForms) {
    shapeForms.forEach((shapeForm, i) => {
      let payload = {}
      sortShapeProperties(getShapePropertyArray(shapeForm), aliasesMap).forEach(p => {
        if (p[ALIAS_SH_PATH] === ID) {
          if(p.value) {
            payload.id = p.value
          }
        } else if (p[ALIAS_SH_PATH] === TYPE) {
          payload.type = p.value
        } else if (p.value && isArray(p.value)) {
          if (p.value.length > 0) {
            if (p[ALIAS_SH_DATATYPE] === RDF_LANGSTRING) {
              let langValues = []
              p.value.filter(v => v).forEach(o => {
                let langKeys = Object.keys(o).filter(k => k)
                if (langKeys.length > 0) {
                  langKeys.forEach(lk => {
                    langValues.push({
                      lang: lk,
                      value: o[lk]
                    })
                  })
                }
              })
              payload[aliasesMap[p[ALIAS_SH_PATH]]] = langValues
            } else {
              payload[aliasesMap[p[ALIAS_SH_PATH]]] = p.value.map(v => {
                if (isObject(v)
                    && p[ALIAS_SH_DATATYPE] !== RDF_LANGSTRING
                    && p[ALIAS_SH_NODE_KIND] !== SH_LITERAL) {
                  createPayloadObjectForGraphQLRecursive([v], payloadList, aliasesMap)
                  return {[ID]: v[ID]};
                } else if(isObjectShapeProperty(p)) {
                  return {[ID]: v};
                } else {
                  return v;
                }
              })
            }
          }
        } else if (p.value && isObject(p.value)
            && p[ALIAS_SH_DATATYPE] !== RDF_LANGSTRING
            && p[ALIAS_SH_NODE_KIND] !== SH_LITERAL
        ) {
          payload[aliasesMap[p[ALIAS_SH_PATH]]] = {[ID]: p.value[ID]}
          createPayloadObjectForGraphQLRecursive([p.value], payloadList, aliasesMap)
        } else if(isObjectShapeProperty(p)) {
          return {[ID]: p.value};
        } else if (p.value && isObject(p.value)
            && p[ALIAS_SH_DATATYPE] === RDF_LANGSTRING) {
          let lang = Object.keys(p.value)[0]
          payload[aliasesMap[p[ALIAS_SH_PATH]]] = {
            lang: lang,
            value: p.value[lang]
          }
        } else {
          payload[aliasesMap[p[ALIAS_SH_PATH]]] = p.value
        }
      })
      payloadList.push(payload)
    })
  }
}

export function stringifyPatchReqBody(leftMenuMinimized, body) {
  return JSON.stringify(body, null, (leftMenuMinimized === false ? 2: 4));
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getRandomIntFromInterval(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function generateMockingSetupId() {
  return generateEGDomainId('mockingsetup');
}

export function generateExampleRequestId() {
  return generateEGDomainId('examplerequest');
}

export function generateExampleSetId() {
  return generateEGDomainId('exampleset');
}

export function generateCollectionId() {
  return generateEGDomainId('collection');
}

export function generateEGDomainId(label) {
  return EASYGRAPH_DATA_NAMESPACE + label + '/' + uuid4();
}

export function extractUUIDForGraphQLFromID(id) {
  let split = id.split('/')
  let uuid = split[split.length -  1].replace(/-/g, '_')
  return uuid;
}

export function isStringDatatype (datatype) {
  return datatype && [
    'http://www.w3.org/2001/XMLSchema#string',
    'http://www.w3.org/1999/02/22-rdf-syntax-ns#langString',
    'http://www.w3.org/2001/XMLSchema#token',
    'http://www.w3.org/2001/XMLSchema#normalizedString'
  ].includes(datatype);
}

export function getMockValueForPayload(p) {
  let mockValue = getMockValueFromDefaultRegex(p)
  if(p[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
    return parseLiteralValue(mockValue);
  } else if(p[ALIAS_SH_DATATYPE] === RDF_LANGSTRING){
    let {lang, value} = parseLangStringLiteral(mockValue);
    return { [lang] : value}
  } else if (p[ALIAS_SH_DATATYPE] === XSD_TIME) {
    return formatTime(dateFromMockTime(mockValue))
  } else if (p[ALIAS_SH_DATATYPE] === XSD_DATETIME) {
    let date = new Date(mockValue);
    let dateTime = formatDateTime(date);
    return dateTime;
  } else if (p[ALIAS_SH_DATATYPE] === XSD_DATE) {
    //traceDebug(() => " mockValue "+mockValue, MODULE_NAME);
    return formatDate(new Date(mockValue))
  } else if (p[ALIAS_SH_DATATYPE] === XSD_BOOLEAN) {
    if(mockValue === 'true' || mockValue === 'false') {
      return mockValue
    } else {
      return undefined
    }
  } else {
    return mockValue;
  }
}

export function dateFromMockTime(value) {
  return new Date('1970-01-01T'+value+'.000Z');
}

export function getMockValueFromDefaultRegex(p) {
  if(p[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
    return getRegExValue(RANDOM_LITERAL_REGEX, p);
  } else if(p[ALIAS_SH_CLASS] || p[ALIAS_SH_OR] || p[ALIAS_SH_NODE_KIND] === SH_IRI) {
    return undefined;
  } else {
    return getRegExValue(getRegExForProperty(p), p);
  }
}

export function getRegExValue(regexPattern, property) {
  if(!regexPattern) {
    return null;
  }
  try {
    let value = new RandExp(new RegExp(regexPattern)).gen();
    return restrictMaxLength(value, property);
  } catch (e) {
    return null;
  }
}

export function restrictMaxLength(value, property) {
  if(property[ALIAS_SH_MAX_LENGTH]) {
    let maxLength = computeMaxLength(property);
    if(isString(value)) {
      return value.substring(0, maxLength);
    } else if (isLangLiteral(value)) {
      let obj = parseLangStringLiteral(value);
      return obj.value.substring(0, maxLength)+"@"+obj.lang;
    } else if (isDatatypeLiteral(value)) {
      let obj = parseDatatypeLiteral(value);
      return `"${obj.value.substring(1, maxLength - 1)}"^^${obj.type}`;
    }
  } else {
    return value;
  }
}

export function getRegExForProperty(property) {
  let regEx = getRegEx(property);
  if (regEx && regEx.length > 0) {
    return new RegExp(regEx[0]);
  }
  let datatype = property[ALIAS_SH_DATATYPE]
  if(datatype) {
    if(datatype === XSD_BOOLEAN) {
      if(isRequiredProperty(property)) {
        return BOOLEAN_REQUIRED_REGEX;
      } else {
        return BOOLEAN_OPTIONAL_REGEX;
      }
    } else if (REGEX_FOR_DATATYPES[datatype]){
      return REGEX_FOR_DATATYPES[datatype];
    } else {
      return REGEX_FOR_DATATYPES[XSD_STRING]
    }
  } else if (property[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
    return RANDOM_LITERAL_REGEX;
  } else {
    return REGEX_FOR_DATATYPES[XSD_STRING];
  }
}

export function getRandomValueForLangString() {
  let regexValue = new RandExp(REGEX_FOR_DATATYPES[RDF_LANGSTRING]).gen()
  return parseLangStringLiteral(regexValue);
}

export function parseLiteralValue(value) {
  return isDatatypeLiteral(value) ? parseDatatypeLiteral(value) : parseLangStringLiteral(value);
}

export function parseLangStringLiteral(langStringValue) {
  let matchArray = langStringValue.match(LANG_LITERAL_VALIDATION_REGEX)
  let value = matchArray[1]
  let lang = matchArray[2]
  return {
    lang : lang,
    value : value
  };
}

export function isLangLiteral(value) {
  return LANG_LITERAL_VALIDATION_REGEX.test(value);
}

export function isDatatypeLiteral(literalValue) {
  return DATATYPE_LITERAL_VALIDATION_REGEX.test(literalValue);
}

export function parseDatatypeLiteral(datatypeLiteralValue) {
  let matchArray = datatypeLiteralValue.match(DATATYPE_LITERAL_VALIDATION_REGEX)
  let value = matchArray[1]
  let type = matchArray[2]
  return {
    type : type,
    value : value
  };
}

export function langCodeToSelectValue(code) {
  let found = LANGUAGES.find(l => l.value === code)
  if(found) {
    return found ;
  } else {
    return toLangSelectValue(code);
  }
}

export function toLangSelectValue(code) {
  return {value: code, label: code};
}

export function prettifyGraphQLQuery(query) {
  try {
    let parsedQuery = parse(query);
    let parsedPrintQuery = print(parsedQuery);
    return parsedPrintQuery;
  } catch (e) {
    return query;
  }
}

export function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function extractVariables(content) {
  const regex = /{{(.*)}}/g;
  let m;
  let variables = {}
  while ((m = regex.exec(content)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    if (m[0] && m[1]) {
      variables[m[0]] = m[1]
    }
  }
  return variables;
}

export function replaceVariablesByString(reqBody, globals) {
  let reqBodyString = isObject(reqBody) ? JSON.stringify(reqBody) : reqBody;
  let variables = extractVariables(reqBodyString);
  let missing = new Set();

  Object.keys(variables).forEach(k => {
    let variableName = variables[k];
    let variableValue = globals.getItem(variableName);
    if(variableValue) {
      reqBodyString = reqBodyString.replaceAll(k, variableValue);
    } else {
      missing.add(variableName)
    }
  });
  return {
    missing : [...missing],
    replaced : reqBodyString
  };

}

export function replaceVariables(reqBody, globals) {
  try {
    let reqBodyString = isObject(reqBody) ? JSON.stringify(reqBody) : reqBody
    let variables = extractVariables(reqBodyString)
    let missing = new Set()
    let replaced = JSON.stringify(JSON.parse(reqBodyString, (key, value) => {
      if (isString(value)) {
        let variableName = variables[value]
        if (variableName) {
          let variableValue = globals.getItem(variableName)
          if(variableValue === undefined) {
            missing.add(variableName)
          }
          return variableValue ? variableValue : value;
        } else {
          return value;
        }
      } else {
        return value
      }
    }), null, 4)
    return {
      missing : [...missing],
      replaced : replaced
    };
  } catch (e) {
    return {
      error : e
    };
  }
}

export function centerVertically(component, containerStyle, containerDatatest, key) {
  return <div key={key} datatest={containerDatatest} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', ...containerStyle}}>{
    component
  }</div>;
}

export function isMockingSetupType(object) {
  const objectType = object[TYPE];
  let includes = [ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP, ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP].includes(objectType);
  return includes;
}


export function computePairsForExampleScenarios(configurations) {
  let ontology = getOntologyClasses(configurations)
  let shapes = getShapesData(configurations)
  let shapesSet = new Set()
  let containerData = getContainerData(configurations)
  containerData.map(c => [c[ALIAS_SYS_CLASS_IRI], ...computeAllSubClasses(c[ALIAS_SYS_CLASS_IRI], ontology)])
      .forEach(ar => ar.forEach(v => {
        let shapeToAdd = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === v)
        if(shapeToAdd) {
          shapesSet.add(shapeToAdd)
        }
      }))
  let instantiableClasses = new Set()
  containerData.filter(c => c[ALIAS_SYS_IDENTIFIER] === 'true').forEach(container => {
    instantiableClasses.add(container[ALIAS_SYS_CLASS_IRI])
    computeAllSubClasses(container[ALIAS_SYS_CLASS_IRI], ontology).forEach(c => instantiableClasses.add(c))
  })
  instantiableClasses = [...instantiableClasses]
  let allInstantiableShapes = instantiableClasses.map(c => {
    let shape = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === c)
    let shapeWithSuperClasses = computeShapeWithSuperClasses(shape, shapes, ontology)
    return shapeWithSuperClasses
  })

  let pairs = []

  allInstantiableShapes.forEach(s => {
    let leftClass = s[ALIAS_SH_TARGET_CLASS]
    let leftClassShape = s
    let leftClassSubClasses = [leftClass, ...computeAllSubClasses(leftClass)]
    let classesToExclude = leftClassSubClasses
    let properties = getShapePropertyArray(s).filter(p => isObjectArrayType(p))
    properties.forEach(p => {
      let classTypes = getInstantiableClassTypes(p, instantiableClasses, ontology, classesToExclude)
      classTypes.forEach(c => {
        let leftToPivotProperty = p
        let pivotClass = c
        let pivotClassSubClasses = [pivotClass, ...computeAllSubClasses(pivotClass)]
        let classesToExclude = [...leftClassSubClasses, ...pivotClassSubClasses]
        let pivotClassShape = allInstantiableShapes.find(s => s[ALIAS_SH_TARGET_CLASS] === pivotClass)
        let pivotClassShapeProperties = getShapePropertyArray(pivotClassShape).filter(p => p[ALIAS_SH_CLASS])
        pivotClassShapeProperties.forEach(p => {
          let classTypes = getInstantiableClassTypes(p, instantiableClasses, ontology, classesToExclude)
          classTypes.forEach(c => {
            let pivotToRightProperty = p
            let rightClass = c
            let rightClassShape = allInstantiableShapes.find(s => s[ALIAS_SH_TARGET_CLASS] === rightClass)
            pairs.push({
              leftClassShape : leftClassShape,
              leftToPivotProperty : leftToPivotProperty,
              pivotClassShape : pivotClassShape,
              pivotToRightProperty : pivotToRightProperty,
              rightClassShape : rightClassShape
            })

          })
        })
      })
    })
  })

  let inverseData = getInverseData(configurations)
  let withStringType = pairs.filter(p => findPropertyWithDatatype(p.leftClassShape, XSD_STRING) )
  let filterInverse = withStringType.filter(p => {
    let left = p.leftToPivotProperty[ALIAS_SH_PATH]
    let right = p.pivotToRightProperty[ALIAS_SH_PATH]
    let found = inverseData.find(p => (p[ID] === left && p[ALIAS_OWL_INVERSE_OF] === right) || (p[ID] === right && p[ALIAS_OWL_INVERSE_OF] === left))
    found = found && p.leftClassShape[ALIAS_SH_TARGET_CLASS] === p.rightClassShape[ALIAS_SH_TARGET_CLASS]
    return found ? false : true;
  });
  return filterInverse;

}

function getInstantiableClassTypes(p, instantiableClasses, ontology, classesToExclude) {
  let classTypes = new Set()
  let propertyClasses = getPropertyClasses(p)
  if(propertyClasses && propertyClasses.length > 0) {
    let subClasses = flatten(propertyClasses.map(c => computeAllSubClasses(c, ontology)))
    let allSubClasses = [...propertyClasses, ...subClasses]
    allSubClasses.filter(c => instantiableClasses.includes(c))
        .filter(c => ![TYPE_RDF_LIST].includes(c))
        .filter(c => !classesToExclude.includes(c))
        .forEach(c => classTypes.add(c));
  }
  return [...classTypes]
}

export function isObjectArrayType(property) {
  let is = (property[ALIAS_SH_MAX_COUNT] === undefined || property[ALIAS_SH_MAX_COUNT] !== '1') && isObjectShapeProperty(property);
  return is;
}

export function findPropertyWithDatatype(shapeForm, dataType, propertyIRIsToExclude, notMandatory) {
  let dataTypeArray = isArray(dataType) ? dataType : [dataType]
  let propertyIRIsToExcludeArray = propertyIRIsToExclude && isArray(propertyIRIsToExclude)
      ? propertyIRIsToExclude
      : propertyIRIsToExclude ? [propertyIRIsToExclude] : []
  let found = getShapePropertyArray(shapeForm)
      .find(p => {
        let result = dataTypeArray.includes(p[ALIAS_SH_DATATYPE])
            && !propertyIRIsToExcludeArray.includes(p[ALIAS_SH_PATH]);
        if(result === true) {
          if (notMandatory) {
            return p[ALIAS_SH_MIN_COUNT] === undefined;
          } else {
            return p[ALIAS_SH_MIN_COUNT] !== undefined;
          }
        } else {
          return false;
        }
      });
  return found;
}

export function getTabLabel(icon, title) {
  return <div style={{display: 'flex'}}><span style={{paddingTop:'2px', marginRight: '4px'}}>{icon}</span><span>{title}</span></div>;
}

export function scrollToView(node, behavior = 'smooth') {
  //using block value other than 'end' cause problems in safari and firefox
  node && node.scrollIntoView({block: 'end', behavior: behavior});
}

export function renderDatatypeOption(option, { selected }) {
  return <React.Fragment>
    <div datatest={option.label} style={{paddingLeft : '16px'}}>
      <div>{option.label}</div>
      {(option.group === undefined || option.group === LABEL_DATA_TYPE) && <div><Typography variant={'caption'}>{option.value}</Typography></div>}
    </div>
  </React.Fragment>;
}

export function attachToWindowForTesting(key, object) {
  // below is used for e2e testing
  let testConfig = enabledTestConfig();
  if (env === 'local' || testConfig) {
    console.log("attachToWindowForTesting", key)
    window[key] = object;
  }
}

export function handleBackendError(callerThis) {
  return (event) => callerThis.setState({loading: false, apiErrorResponse: event, apiError: true});
}

export function handleAPIResponse(callerThis, apiResponse, onSuccess, onClientError) {
  if(!apiResponse) {
    return;
  }
  let status = apiResponse.status;
  if (status >= 500) {
    try {
      apiResponse.json().then((j) => {
        callerThis.setState({loading: false, apiErrorResponse: j, apiError: true})
      })
    } catch (e) {
      callerThis.setState({loading: false, response: apiResponse, apiError: true})
    }
  } else {
    if(isRequestSuccessful(apiResponse)) {
      return onSuccess(apiResponse);
    } else if(isClientError(apiResponse)) {
      return onClientError ? onClientError(apiResponse) : handleBackendError(callerThis)(apiResponse);
    }
  }
}

export function getBackendBasePath() {
  let backendBaseEndpoint = getBackendBaseEndpoint();
  let url = new URL(backendBaseEndpoint);
  // remove '/' at end if exists
  let basePath = url.pathname.replace(/\/$/,"");
  return basePath;
}

export function getInstance() {
  let prefixPath = getRouteInstanceAndDatasetPath();
  let split = prefixPath.split("/");
  let path =  '';//split.length > 1 && split[1] === "instance" ? `/instance/${split[2]}` : "";
  //Find index of instance and it name part in path
  for(let i = 0 ;i < split.length;i++) {
    if(split[i] === 'instance' && (i + 1) < split.length ) {
      return split[i+1];
    }
  }
  return "";
}

export function getRouteInstancePath() {
  let instance = getInstance();
  return `${getBackendBasePath()}${instance ? `/instance/${instance}` : '' }`;
}

export function getDatasetLabel() {
  let prefixPath = getRouteInstanceAndDatasetPath();
  let split = prefixPath.split("/");
  let path = '';
  for(let i = 0 ;i < split.length;i++) {
    if(split[i] === 'dataset' && (i + 1) < split.length ) {
      path = split[i+1];
    }
  }
  return path;

}

export async function getDatasetId() {
  const apiResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET_SEARCH).catch(handleBackendError(this));
  const apiResponseJson = await apiResponse.json();
  let datasets = getSearchResult(apiResponseJson);
  let datasetLabel = getDatasetLabel();
  let dataset = datasets.find(dt => dt[ALIAS_MANAGEMENT_ID_LABEL] === datasetLabel);
  let datasetId = getResourceId(dataset);
  return datasetId;
}

export function getManagementHomeLink() {
  if(getDatasetLabel()) {
    return getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_HOME);
  }
  return getRouteWithInstanceAndUserDefaultDataset(ROUTE_MANAGEMENT_HOME);
}



export function getMainBarRouteAndInstanceInfo() {
  return getInstance()+ " "+ getDatasetLabel();
}

export function getRouteInstanceAndDatasetPath() {
  let url = new URL(window.location.href);
  let prefixPath = url.pathname.substr(0, url.pathname.indexOf(ROUTE_APP));
  if(!prefixPath) {
    return getBackendBasePath();
  }
  return prefixPath;
}

export function getRouteWithInstanceAndUserDefaultDataset(route) {
  let selectedDataset = getSelectedDataset();
  if(selectedDataset) {
    let routeToReturn = `${getRouteInstancePath()}/dataset/${selectedDataset}${route}`;
    return routeToReturn;
  }
  return `${getRouteWithInstanceAndDataset(route)}`;
}

export function getRouteWithInstanceAndDataset(route) {
  return `${getRouteInstanceAndDatasetPath()}${route}`;
}

export function getRouteWithInstance(route) {
  return `${getRouteInstancePath()}${route}`;
}

export function isManagementLogin() {
  return window.location.href.includes(ROUTE_MANAGEMENT_LOGIN);
}

export function isManagementAdminLogin() {
  return window.location.href.includes(ROUTE_MANAGEMENT_ADMIN_LOGIN);
}

export function isManagementAdminLogout() {
  return window.location.href.includes(ROUTE_MANAGEMENT_LOGOUT);
}

export function isAnyAccountRouteOrHome() {
  let url = new URL(window.location.href);
  return url.pathname.includes(ROUTE_ACCOUNT) || url.pathname === "/" || url.pathname === ROUTE_APP;
}

export function hasAnyDatasetLevelAuthenticationDisabled() {
  let datasetLabel = getDatasetLabel();
  return datasetLabel && getConfigForDataset(datasetLabel) && getConfigForDataset(datasetLabel).authenticationType;
}

export function hasDatasetLevelWriteAuthenticationDisabled() {
  let datasetLabel = getDatasetLabel();
  return datasetLabel && getConfigForDataset(datasetLabel) && getConfigForDataset(datasetLabel).authenticationType === EASYGRAPH_DATA_AUTHENTICATION_DISABLED;
}

export function getDataValue(v) {
  if(v[VALUE] == undefined) {
    return v;
  }
  return v[VALUE];
}

export function getResourceId(d) {
  return !d ? undefined : d.id || d[AT_ID];
}

function isEmpty(obj) {
  if (obj === '' || obj === null || JSON.stringify(obj) === '{}' || JSON.stringify(obj) === '[]' || (obj) === undefined || (obj) === {}) {
    return true
  } else {
    return false
  }
}

export function removeEmpty(o) {
  if (typeof o !== "object") {
    return o;
  }
  let oKeys = Object.keys(o)
  for (let j = 0; j < oKeys.length; j++) {
    let p = oKeys[j]
    switch (typeof (o[p])) {
      case 'object':
        if (Array.isArray(o[p])) {
          for (let i = 0; i < o[p].length; i++) {
            o[p][i] = removeEmpty(o[p][i])
            if (isEmpty(o[p][i])) {
              o[p].splice(i, 1)
              i--
            }
          }
          if (o[p].length === 0) {
            if (Array.isArray(o)) {
              o.splice(parseInt(p), 1)
              j--
            } else {
              delete o[p]
            }
          }
        } else {
          if (isEmpty(o[p])) {
            delete o[p]
          } else {
            o[p] = removeEmpty(o[p])
            if (isEmpty(o[p])) {
              delete o[p]
            }
          }
        }
        break
      default:
        if (isEmpty(o[p])) {
          delete o[p]
        }
        break
    }

  }
  if (Object.keys(o).length === 0) {
    return
  }
  return o
}

export function equalSet(xs, ys) {
  return xs.size === ys.size && [...xs].every((x) => ys.has(x));
}

export function isQuickEditDisabled(customizations) {
  return customizations?.disableQuickEdit === true ? true : false;
}

export function GetEditableClassNames(action, id, outlineClass, customizations) {
  if(isQuickEditDisabled(customizations)) {
    return undefined;
  }
  const classes = isPageEditEnabled() ? `editableComponent ${outlineClass || 'editableComponentOutline'}` : 'editableComponent';
  const settingsEditAction = getSettingsEditAction();
  const isEditableHighlight = action !== undefined && settingsEditAction?.action === action && settingsEditAction?.id === id;
  const classesToReturn = classes + ( isEditableHighlight ? " editableComponentHighlight ": "");
  return classesToReturn + ( isEditableHighlight && settingsEditAction?.className ? settingsEditAction?.className + ' ' : '' );
}