import React from "react";
import MermaidWrapper, {getPropertyValue} from "./MermaidWrapper";

const rootId = 'rootId';
const parentId = 'parentId';
const parentLabel = 'parentLabel';
const childId = 'childId';
const childLabel = 'childLabel';

export const MINDMAP_DIAGRAM_SETTINGS = [
    {label : "Root Id", stateKey : rootId},
    {label : "Parent Id", stateKey : parentId},
    {label : "Parent Label", stateKey : parentLabel},
    {label : "Child Id", stateKey : childId},
    {label : "Child Label", stateKey : childLabel}
];

export default class MindMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData: ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {
        })
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }

        let startingPoints = {};
        let rootNodeIdVale = resolvedValues.map(rv => rv[rootId]).find(r => r);
        //Now create node value object for all node ids
        let usedIdsMap = {};

        let mermaidData = 'mindmap';
        let classStyles = {};
        let mermaidDataCollector = [];
        this.process(0, rootNodeIdVale, usedIdsMap, mermaidDataCollector, resolvedValues);
        mermaidData = mermaidData + "" + mermaidDataCollector.join("");
        console.log("mermaidData", mermaidData)
        return mermaidData;
    }

    process = (level, parentIdVal, usedIdsMap, mermaidDataCollector, resolvedValues) => {
        let parentNode = resolvedValues.find(rv => rv[parentId] === parentIdVal && rv[parentLabel]);
        let indent = '';
        for(let i=0;i<level;i++) {
            indent+=' ';
        }
        if (parentNode) {
            const parentLabelVal = parentNode[parentLabel];
            mermaidDataCollector.push(`\n${indent}${parentLabelVal}`);
            resolvedValues.filter(rv => rv[parentId] === parentIdVal && rv[childId]).forEach(rv => {
                this.process(level + 1, rv[childId], usedIdsMap, mermaidDataCollector, resolvedValues);
            })
        } else {
            resolvedValues.filter(rv => rv[childId] === parentIdVal).forEach(rv => {
                const childLabelVal = rv[childLabel];
                mermaidDataCollector.push(`\n${indent}${childLabelVal}`);
            })
        }
    }

    render() {
        const {mermaidData} = this.state;

        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}}
            >{mermaidData}</MermaidWrapper>}
        </>;
    }

}