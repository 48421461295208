import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {
    centerVertically,
    computeShapesForResource,
    findShapePropertyForAlias,
    flatten,
    getDataValue,
    getLocalName,
    getPropertyName,
    getShapePropertyArray,
    isArrayOnly,
    isBlankNodeId,
    isEmptyArray,
    isObjectOnly,
    isObjectProperty,
    isObjectPropertyOrRDFConnectionProperty,
    isObjectShapeProperty,
    sort,
    sortByFunction,
    toArray
} from "../../components/util";
import {Chip, Grid, TextField as OtherTextField, Tooltip, Typography} from "@material-ui/core";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ALIAS_SYS_ETAG, AT_ID,
    ID,
    IRI_CLASS_SKOSXL_ALT_LABEL,
    IRI_CLASS_SKOSXL_HIDDEN_LABEL,
    IRI_CLASS_SKOSXL_PREF_LABEL,
    LANG,
    SH_LITERAL,
    TYPE,
    VALUE,
    XSD_STRING
} from "../../Constants";
import {isLangProperty} from "../apiplayground/SearchFilter";
import {isImageProperty, toImageSrcValue} from "./SearchResultItem";
import {loadResource} from "../../service/data-loader";
import {cloneDeep, isString} from "lodash";
import {theme} from "../../theme";
import GlobalsContext from "../../components/GlobalsContext";
import LinkResourceDialog from "./LinkResourceDialog";
import {Autocomplete} from "@material-ui/lab";
import AddDataPropertyValueDialog from "./AddDataPropertyValueDialog";
import NodeContainer from "./NodeContainer";
import WithObjectsSummary from "./WithObjectSummary";
import {isResourceRefreshEvent, isResourceUpdateEvent, registerUpdateEventListener} from "./Event";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import {PermissionService, withPermissions} from "../../service/permission-service";
import {getUiLabelTranslation, UI_LABELS_SELECT_PROPERTY_TO_ADD} from "./UILabel";
import {getPropertiesOrder, hideAllOtherProperties, isInHiddenProperties} from "./DataViewSetup";
import {RenderPropertyValue} from "./ViewDataValueDialog";
import {isObjectTypeValueOrProperty} from "./DataGridView";
import ObjectLinks from "./ObjectLinks";
import {
    isResourceViewTypeSimple,
    isSimpleViewShowDatatype,
    isSimpleViewShowLanguageCode,
    isSimpleViewShowLanguageName
} from "./BasicSetup";
import SimpleViewCard from "./SimpleViewCard";


export function getBorderColor(theme) {
    let borderConnectionColor = '#ddd';//theme.palette.grey.main
    return borderConnectionColor;
}


export function isInViewProperties(viewProperties, aliasesMap, k, settings) {

    let inHiddenProperties = isInHiddenProperties(settings, aliasesMap, k);
    if(inHiddenProperties) {
        return false;
    }
    if (viewProperties === undefined || viewProperties.length === 0) {
        return true;
    }
    let found = viewProperties.find(vp => aliasesMap[vp.value] === k || vp.value === k);
    return found;
}

export function getRootStyle(theme) {
    return {
        paddingLeft: '32px',
        borderLeft: '2px solid',
        borderColor: getBorderColor(theme)
    };
}

export function isLangValue(value) {
    let valueArray = toArray(value);
    if (isEmptyArray(valueArray)) {
        return false;
    } else {
        let langCode = valueArray[0][LANG];
        return langCode ? true : false;
    }
}

export function isDataValue(valueArray) {
    return !isEmptyArray(valueArray) && valueArray[0][TYPE] && valueArray[0][VALUE];
}

export function isNonIdDataValue(valueObject) {
    return valueObject && ![AT_ID, ID].includes(valueObject[TYPE]) && valueObject[VALUE];
}

export class StringValue extends React.Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {settings, value, langCode, datatype, shapeProperty, isImageType, customizations} = this.props;
        let bl = settings?.browseLanguages?.find(bl => bl.value === langCode) ;
        const showLanguageName = bl && !customizations?.hideLanguageName;

        let langCodeChip;
        if(langCode && !customizations?.hideLanguageCode) {
            langCodeChip = <Chip datatest={'langCode'} size={'small'} label={langCode} color={ showLanguageName ? 'secondary' : 'primary'}></Chip>
            if(!showLanguageName && bl?.label) {
                langCodeChip = <Tooltip title={bl?.label}>
                    {langCodeChip}
                </Tooltip>;
            }
        }
        let langNameChip;
        if(showLanguageName) {
            langNameChip = <Chip datatest={'langAndValue'} icon={langCodeChip} style={{marginLeft: '4px', maxWidth: '100px'}} label={bl?.label}/>;
            if(!langCodeChip) {
                langNameChip = <Tooltip title={langCode}>
                    {langNameChip}
                </Tooltip>
            }
        }
        let dataTypeChip ;
        if(datatype && !customizations?.hideDatatype) {
            dataTypeChip = <Tooltip title={datatype}>
                <Chip datatest={'datatype'} style={{marginLeft: '4px', maxWidth: '100px'}} label={getLocalName(datatype, false)}/>
            </Tooltip>
        }
        const chipToShow = langNameChip || langCodeChip || dataTypeChip;
        let chipMinWidth = '100px';
        if(langCodeChip && !langNameChip) {
            chipMinWidth = '34px';
        }
        return <React.Fragment key={value || langCode}>
            <div style={{display : 'flex', flexGrow  :'1', minHeight :'32px', overflow :'auto'}}>
                {
                    chipToShow && centerVertically(
                        <div>{chipToShow}</div>,
                        {minWidth : chipMinWidth, textAlign : 'right'}
                    )
                }

                {
                    isImageType && centerVertically( <div>
                        <img width={100} src={toImageSrcValue(value)}></img>
                    </div>, { minWidth : '100px', textAlign : 'right'} )
                }
                <React.Fragment>{
                    centerVertically(
                        <Typography
                            datatest={'value'}
                            style={{marginLeft: '8px', maxHeight : '112px', overflow : 'auto',  whiteSpace: 'pre-line'}}
                            color={'primary'}>
                            {value}
                        </Typography>, {flexGrow :'1'}
                    )
                }</React.Fragment>
            </div>
        </React.Fragment>;

    }
}

StringValue.propTypes = {
    theme: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    configurations: PropTypes.any,
    aliasesToIRIMap: PropTypes.object,
    aliasesMap: PropTypes.object,
    parentObject: PropTypes.object,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    property: PropTypes.any,
    viewProperties: PropTypes.array,
    depth: PropTypes.any,

    value: PropTypes.any,
    langCode: PropTypes.any,
    datatype: PropTypes.any,
    shapeProperty: PropTypes.any,
    isImageType : PropTypes.any
};


export function sortValues(langObjects, secondSort = LANG, direction = 'asc') {
    langObjects = sort(langObjects, VALUE, direction);
    return sort(langObjects, secondSort, direction);
}

export function isLangPropertyOrLangValue(value, shapeProperty) {
    if (isLangProperty(shapeProperty)) {
        return true;
    } else {
        return isLangValue(value);
    }
}

export function langValueArray(value) {
    let isArrayValue = isArrayOnly(value);
    let isObjectValue = isObjectOnly(value);

    let langObjects = [];
    if (isArrayValue) {
        //If data is array of lang and value type serialisation
        langObjects = value;
    } else if (isObjectValue) {
        let langCode = value[LANG];
        let langValue = value[VALUE];
        //If data is lang and value object then convert
        if (langCode || langValue) {
            return [{[langCode]: langValue}];
        }
        //Below means data is serialised as language map
        let keys = Object.keys(value);
        langObjects = keys.map((k, index) => {
            let langValue = value[k];
            let langCode = k;
            return toArray(langValue).map((lv) => {
                return {
                    [VALUE]: lv,
                    [LANG]: langCode
                };
            })
        });
        langObjects = flatten(langObjects);
    }
    return langObjects;
}

export function isLiteralOrLanguageAndDatatypeMixed(value, shapeProperty) {
    if (shapeProperty && shapeProperty[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
        return true;
    } else {
        let valueArray = toArray(value);
        let hasLang = valueArray.find(v => v[LANG]);
        let hasType = valueArray.find(v => v[TYPE]);
        let hasString = valueArray.find(v => v[TYPE] === undefined && v[LANG] === undefined);
        let all = [hasLang, hasType, hasString].filter(h => h === true);
        return all.length > 1;
    }
}

function isIdObjectValue(value) {
    let keys = Object.keys(value);
    return keys.length === 1 && keys[0] === ID;
}

export function isDatatypePropertyOrValue(value, shapeProperty, propertyKey, ontology, aliasesToIRIMap) {
    let valueArray = toArray(value);
    // Check data first
    if (!isEmptyArray(valueArray)) {
        if (isDataValue(valueArray)) {
            return true;
        }
        if (isIdObjectValue(valueArray[0])) {
            return false;
        }
    }
    if (shapeProperty?.[ALIAS_SH_DATATYPE] || shapeProperty?.[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
        return true;
    }
    let found = ontology.find(o => o[ID] === aliasesToIRIMap[propertyKey] || o[ID] === propertyKey)
    if (found && isObjectPropertyOrRDFConnectionProperty(found, aliasesToIRIMap) === false) {
        return true;
    }
    return false;

}

export function isAnyValueIdObject(value) {
    let arrayValue = toArray(value);
    if(isEmptyArray(arrayValue)) {
        return false;
    }
    let found = arrayValue.find(v => v[ID]);
    return found ? true : false;
}


const EXPAND_PROPERTY_IRIS = [IRI_CLASS_SKOSXL_ALT_LABEL, IRI_CLASS_SKOSXL_PREF_LABEL, IRI_CLASS_SKOSXL_HIDDEN_LABEL];

function shouldExpandObject(aliasesToIRIMap, propertyKey) {
    return EXPAND_PROPERTY_IRIS.includes(aliasesToIRIMap[propertyKey] || propertyKey);
}

export function langObjectToArray(value) {
    //Below means data is serialised as language map
    let keys = Object.keys(value);
    let langObjects = keys.map((k, index) => {
        let langValue = value[k];
        let langCode = k;
        return toArray(langValue).map((lv) => {
            return {
                [VALUE]: lv,
                [LANG]: langCode
            };
        })
    });
    return flatten(langObjects);
}

class Tree extends React.Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        registerUpdateEventListener(this, this.onEvent);
        this.loadResourceInState();
    }

    loadResourceInState = () => {
        let {resource, disableLoadResource} = this.props;
        let resourceId = resource[ID];
        if(isBlankNodeId(resourceId) || disableLoadResource) {
            this.setState({resourceInState : cloneDeep(resource)});
        } else {
            loadResource(resourceId).then(results => {
                let resourceInState = results.find(rs => rs && rs[ID] === resourceId);
                this.setState({resourceInState});
            })
        }
    }


    onEvent = (event) => {
        let {resourceInState} = this.state;
        let {payload, response}= event;
        let isResourceUpdate = isResourceUpdateEvent(event) || isResourceRefreshEvent(event)
        if(resourceInState && isResourceUpdate === true && payload?.[ID] === resourceInState[ID]) {
            this.loadResourceInState();
        }
    }

    isObjectProperty = (shapeProperty, propertyKey) => {
        if (isObjectShapeProperty(shapeProperty)) {
            return true;
        }
        let {ontology, aliasesToIRIMap} = this.props;
        let found = ontology.find(o => o[ID] === aliasesToIRIMap[propertyKey] || o[ID] === propertyKey)
        if (found && isObjectPropertyOrRDFConnectionProperty(found, aliasesToIRIMap)) {
            return true;
        }
        return false;
    }





    renderValue = (value, langCode, datatype, shapeProperty, isImageType) => {
        let {settings, theme} = this.props;
        return <StringValue settings={settings} theme={theme} value={value} langCode={langCode} datatype={datatype} shapeProperty={shapeProperty} isImageType={isImageType}/>
    }

    getPropertyName = (propertyKey) => {
        if (!propertyKey) {
            return;
        }
        let {ontology, aliasesToIRIMap, browseLanguage} = this.props;
        return getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);
    }




    renderLanguage = (value, shapeProperty, langCode, depth, parentObject, nodeDataEditOnFocus, context) => {
        let nodeSubTree;
        let nodeValue;
        let isArrayValue = isArrayOnly(value);
        let isObjectValue = isObjectOnly(value);

        if(isArrayValue || isObjectValue) {
            let langObjects = [];
            if (isArrayValue) {
                //If data is array of lang and value type serialisation
                langObjects = value;
            } else if (isObjectValue) {
                let langCode = value[LANG];
                let langValue = value[VALUE];
                //If data is lang and value object then convert
                if (langCode || langValue) {
                    value = {[langCode]: langValue}
                }
                langObjects = langObjectToArray(value);
            }

            let rootStyle = getRootStyle(theme);
            langObjects = sortValues(langObjects);
            nodeSubTree = langObjects.map((v, index) => {
                let langValue = v[VALUE];
                let langCode = v[LANG];
                let contextClone = cloneDeep(context);
                contextClone.dataValue = v;
                contextClone[LANG] = langCode;
                contextClone[VALUE] = langValue;
                contextClone.valueIndexInArray = index;
                return <React.Fragment key={langCode + langValue + index}>{this.renderTree({
                    value: langValue,
                    valueIndexInArray : index,
                    depth: depth + 1,
                    shapeProperty,
                    parentObject,
                    langCode: langCode,
                    nodeDataEditOnFocus,
                    context : contextClone
                })}</React.Fragment>
            });
            nodeSubTree = <div style={rootStyle}>{nodeSubTree}</div>

        }
        else {
            nodeValue = this.renderValue(value, langCode, undefined, shapeProperty);
        }

        return {
            nodeSubTree,
            nodeValue
        }

    }

    renderDataValue = (value, shapeProperty, depth, isImageType, parentObject, nodeDataEditOnFocus, context) => {
        let valueArray = toArray(value);
        let rootStyle = getRootStyle(theme);
        if(shapeProperty?.[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
            let dataType = valueArray.filter(vl => vl[TYPE] || isString(vl)).map(vl => vl[TYPE] ? vl : {[VALUE] : vl , [TYPE] : XSD_STRING});
            let langType = valueArray.filter(vl => vl[LANG]);
            langType = sortValues(langType);
            dataType = sortValues(dataType, TYPE);
            valueArray = [...dataType, ...langType];
        } else {
            valueArray = sortByFunction(valueArray, (obj) => getDataValue(obj))
        }
        let nodeSubTree = valueArray.map((v, index) => {
            let dataValue = getDataValue(v);
            let langCode = v[LANG];
            let datatype = v[TYPE];
            let contextClone = cloneDeep(context);
            contextClone.dataValue = v;
            contextClone[LANG] = langCode;
            contextClone[TYPE] = datatype;
            contextClone[VALUE] = dataValue;
            contextClone.valueIndexInArray = index;
            return <React.Fragment key={index}>{
                this.renderTree({
                    value: dataValue,
                    valueIndexInArray: index,
                    depth: depth + 1,
                    shapeProperty,
                    isImageType:  isImageType,
                    parentObject,
                    langCode,
                    datatype,
                    nodeDataEditOnFocus,
                    context : contextClone
                })}</React.Fragment>;
        });
        return <div style={rootStyle}>{nodeSubTree}</div>;

    }


    renderTree = ({
                      value,
                      valueIndexInArray,
                      propertyKey,
                      depth,
                      shapeProperty,
                      langCode,
                      datatype,
                      parentObject,
                      isImageType,
                      nodeDataEditOnFocus,
        context
                  }) => {
        let {theme, customizations, viewProperties, permissionService, configurations, aliasesToIRIMap, aliasesMap, settings, browseLanguage, location, ontology} = this.props;
        let isArrayValue = isArrayOnly(value);
        let isObjectValue = isObjectOnly(value);

        let nodeValue;
        let nodeSubTree;
        let rootStyle = getRootStyle(theme)
        if(propertyKey === undefined && isString(value)) {
            nodeValue = this.renderValue(value, langCode, datatype, shapeProperty, isImageType);

        } else {
            if (propertyKey === TYPE) {
                let valueArray = toArray(value).map(v => aliasesToIRIMap[v]||v);
                let cloned = {[TYPE] : valueArray}
                let customizationsClone = customizations
                    ? cloneDeep(customizations)
                    : {readOnly : true};
                customizationsClone.readOnly = true;
                return <WithObjectsSummary
                    expand={true}
                    customizations={customizationsClone}
                    property={propertyKey}
                    shapeProperty={shapeProperty}
                    theme={theme}
                    location={location}
                    settings={settings}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    resourceIds={valueArray}
                    browseLanguage={browseLanguage}
                    parentObject={cloned}
                    ontology={ontology}
                    depth={depth}
                    configurations={configurations}
                    viewProperties={viewProperties}
                    context={context}
                />;

            } else if (isLiteralOrLanguageAndDatatypeMixed(value, shapeProperty)) {
                nodeSubTree = this.renderDataValue(value, shapeProperty, depth, isImageType, parentObject, nodeDataEditOnFocus, context);
            } else if (isLangPropertyOrLangValue(value, shapeProperty)) {
                let languageTree = this.renderLanguage(value, shapeProperty, langCode, depth, parentObject, nodeDataEditOnFocus, context);
                nodeValue = languageTree.nodeValue;
                nodeSubTree = languageTree.nodeSubTree;
            } else if (isDatatypePropertyOrValue(value, shapeProperty, propertyKey, ontology, aliasesToIRIMap)) {
                nodeSubTree = this.renderDataValue(value, shapeProperty, depth, isImageType, parentObject, nodeDataEditOnFocus, context);

            } else if (isAnyValueIdObject(value) || this.isObjectProperty(shapeProperty, propertyKey)) {
                let valueArray;
                if (isAnyValueIdObject(value)) {
                    valueArray = toArray(value).map(v => v[ID]);
                } else {
                    valueArray = value;
                }
                let expand = shouldExpandObject(aliasesToIRIMap, propertyKey);
                return <WithObjectsSummary
                    expand={expand}
                    customizations={customizations}
                    property={propertyKey}
                    shapeProperty={shapeProperty}
                    theme={theme}
                    location={location}
                    settings={settings}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    resourceIds={valueArray}
                    browseLanguage={browseLanguage}
                    parentObject={parentObject}
                    ontology={ontology}
                    depth={depth}
                    configurations={configurations}
                    viewProperties={viewProperties}
                    context={context}
                />;

            } else {
                // Check the value type and render
                // If value object or object array then render as language
                if (isObjectValue || (isArrayValue && !isEmptyArray(value) && isObjectOnly(value[0]) && !isAnyValueIdObject(value))) {
                    let languageTree = this.renderLanguage(value, shapeProperty, langCode, depth, parentObject, nodeDataEditOnFocus, context);
                    nodeValue = languageTree.nodeValue;
                    nodeSubTree = languageTree.nodeSubTree;
                } else if (propertyKey) {
                    nodeSubTree = this.renderDataValue(value, shapeProperty, depth, isImageType, parentObject, nodeDataEditOnFocus, context);
                }
            }
        }

        let required = shapeProperty?.[ALIAS_SH_MIN_COUNT] ? '*' : '';
        let propertyName = this.getPropertyName(propertyKey);
        let keyTitle = required + (propertyName || '');

        return <NodeContainer
            customizations={customizations}
            location={location}
            settings={settings}
            browseLanguage={browseLanguage}
            parentObject={parentObject}
            valueIndexInArray={valueIndexInArray}
            renderedDataValueObject={{
                [VALUE] : value,
                [LANG] : langCode,
                [TYPE] : datatype
            }}
            fragmentKey={propertyKey}
            propertyKey={propertyKey}
            shapeProperty={shapeProperty}
            theme={theme}
            aliasesToIRIMap={aliasesToIRIMap}
            aliasesMap={aliasesMap}
            configurations={configurations}
            nodeValue={nodeValue}
            nodeActions={undefined}
            nodeDataEditOnFocus={ propertyKey === undefined && nodeDataEditOnFocus}
            nodeSubTree={nodeSubTree}
            propertyKeyTitle={keyTitle}
            ontology={ontology}
            context={context}
        />;


    }

    closeLinkDialog = () => {
        let {permissionService} = this.props;
        this.setState({openLink : false, newProperty : undefined});
    }

    sortObjectKeys = (keys) => {
        let {aliasesToIRIMap, ontology, browseLanguage} = this.props;
        let sorted = keys.sort((aItem, bItem) => {
            //console.log(aItem.columnLabel, bItem.columnLabel)
            if(bItem == ID) {
                return 1;
            }
            if(bItem == TYPE && aItem != ID) {
                return 1;
            }
            if(bItem == ALIAS_SYS_ETAG && [ID, TYPE].includes(aItem) === false) {
                return 1;
            }
            let aItemName = getPropertyName(aliasesToIRIMap, aItem, ontology, browseLanguage);
            let bItemName = getPropertyName(aliasesToIRIMap, bItem, ontology, browseLanguage);
            if(aItemName > bItemName) {
                return 1;
            }
            if(aItemName < bItemName) {
                return -1;
            }
            return 0;
        });
        return sorted;

    }

    renderObject = (resource, depth, isLastObject, isResourceObject = false) => {
        let {theme, customizations, configurations, onConceptClick, aliasesToIRIMap, ontology, settings, browseLanguage, aliasesMap, location, permissionService} = this.props;
        let {newProperty, openLink, updateFailed, addFeedback, feedBackContext} = this.state;
        let keys = Object.keys(resource).filter(k => {
            if(customizations?.disableEmptyCheck === true) {
                return true;
            }
            //Check that value exist and is not empty array as list might be serialised as empty array
            let arrayValues = toArray(resource[k]);
            return isEmptyArray(arrayValues) ? false : true;
        });
        keys = this.sortObjectKeys(keys);
        let canUpdateResource = permissionService.canUpdateResource(resource);

        if(canUpdateResource === false || customizations?.readOnly === true) {
            let types = toArray(resource[TYPE]);
            let hideOtherProperties = types.find(t => {
                let typeIRI = aliasesToIRIMap[t];
                return hideAllOtherProperties(settings, typeIRI) === true;
            });
            let hasPropertyOrder = types.find(t => {
                let typeIRI = aliasesToIRIMap[t];
                return toArray(getPropertiesOrder(settings, typeIRI)).length > 0;
            });

            if(hasPropertyOrder) {
                let allPropertiesToShow = [];
                for(let i=0; i < types.length;i++) {
                    const type = types[i];
                    let typeIRI = aliasesToIRIMap[type];
                    let propertiesToShow = getPropertiesOrder(settings, typeIRI);
                    propertiesToShow.forEach(p => {
                        let found = allPropertiesToShow.find(ap => ap === p);
                        if(!found) {
                            allPropertiesToShow.push(p);
                        }
                    })
                };
                let newKeys = [];
                allPropertiesToShow.forEach(ap => {
                    let keyInResource = keys.find(k => k === ap || aliasesToIRIMap[k] === ap);
                    if(keyInResource) {
                        newKeys.push(keyInResource);
                    }
                });
                if(hideOtherProperties) {
                    keys = newKeys;
                } else {
                    let restOfTheKeys = [];
                    keys.forEach(k => {
                        let keyInNewKeys = newKeys.includes(k);
                        if(!keyInNewKeys) {
                            restOfTheKeys.push(k);
                        }
                    });
                    keys = [...newKeys, ...restOfTheKeys];
                }
            }
        }

        let rootStyle = depth === 0 ? {paddingLeft: '16px'} : getRootStyle(theme)
        let shapesForResource;
        if (isResourceObject) {
            shapesForResource = computeShapesForResource(resource, aliasesToIRIMap, configurations, ontology);
        }


        let keysIris = keys.map(k => aliasesToIRIMap[k]);
        let allShapesProperties = flatten(shapesForResource.map(sh => getShapePropertyArray(sh))).filter(p => {
            let path = p[ALIAS_SH_PATH];
            let found = keysIris.includes(path);
            return found ? false : true;
        });
        let allShapesPropertiesOptions = sort(allShapesProperties.map(p => {
            return {
                value : p[ALIAS_SH_PATH],
                label : getPropertyName(aliasesToIRIMap, p[ALIAS_SH_PATH], ontology, browseLanguage),

            }
        }), 'label');
        let propertyToAddOption = getUiLabelTranslation(settings, UI_LABELS_SELECT_PROPERTY_TO_ADD, browseLanguage, UI_LABELS_SELECT_PROPERTY_TO_ADD);
        let propertyValue = newProperty
        let keyComponent = <Autocomplete
            key={JSON.stringify(propertyValue)}
            datatest={'addProperty'}
            value={undefined}
            options={allShapesPropertiesOptions}
            getOptionLabel={option => {
                let label = option.label ? option.label : '';
                return label;
            }}
            renderOption={(option, { selected }) => (
                <div>
                    <div datatest={option.label} style={{paddingLeft : '16px'}}>{option.label}</div>
                    <div style={{paddingLeft : '16px'}}>
                        <Typography variant={'caption'}>{option.value}</Typography>
                    </div>
                </div>
            )}
            onChange={(event, val) => {
                this.setState({newProperty: val, openLink : true})
            }}
            renderInput={params => (
                <OtherTextField
                    {...params}
                    placeholder={propertyToAddOption}
                    margin={"dense"}
                    variant="outlined"
                    fullWidth
                />
            )}
            size={"small"}
            disableClearable
        />
        let lastNode = isEmptyArray(allShapesPropertiesOptions) || customizations?.readOnly === true || canUpdateResource === false
            ? <></>
            : <NodeContainer
                customizations={{
                    ...customizations,
                    feedback : false
                }}
                settings={settings}
                browseLanguage={browseLanguage}
                ontology={ontology}
                location={location}
                fragmentKey={'add'}
                propertyKey={'add'}
                theme={theme}
                aliasesToIRIMap={aliasesToIRIMap}
                aliasesMap={aliasesMap}
                nodeValue={undefined}
                nodeSubTree={undefined}
                propertyKeyTitle={undefined}
                keyComponent={keyComponent}
                nodeContainerStyle={{
                    borderLeftColor : theme.palette.success.main
                }}
            />;

        let propertyShapeForNewProperty = propertyValue && allShapesProperties.find(sp => sp[ALIAS_SH_PATH] === propertyValue.value);

        const isSimpleView = canUpdateResource === false && isResourceViewTypeSimple(settings);
        if(isSimpleView && !customizations) {
            customizations = {};
        }
        if(isSimpleView && !isSimpleViewShowLanguageCode(settings)) {
            customizations.hideLanguageCode = true;
        }
        if(isSimpleView && !isSimpleViewShowDatatype(settings)) {
            customizations.hideDatatype= true;
        }
        if(isSimpleView && !isSimpleViewShowLanguageName(settings)) {
            customizations.hideLanguageName= true;
        }
        const resourceDetails = keys.filter(k => k !== ID && this.isInViewProperties(k)  ).map((propertyKey, index) => {
            const propertyValue = resource[propertyKey];
            let value = propertyValue;
            let last = isLastObject && index === keys.length - 1;
            let shapeProperty = isResourceObject && findShapePropertyForAlias(shapesForResource, propertyKey, aliasesToIRIMap);
            let isImageType = isImageProperty(resource, settings, aliasesToIRIMap, propertyKey);
            let nodeDataEditOnFocus = customizations?.readOnly === true ? false :  canUpdateResource === true && [ALIAS_SYS_ETAG, ID, TYPE].includes(propertyKey) === false;

            let context = {
                propertyKey,
                resource,
                shapeProperty
            }
            if(isSimpleView) {
                let isLinkType = [ID, TYPE].includes(propertyKey);
                let column = {
                    property : shapeProperty
                }
                let propertyIRI = aliasesToIRIMap[propertyKey] || propertyKey;
                let ontologyProp = ontology.find(o => o[ID] === propertyIRI);
                const objectTypeValueOrProperty = isObjectTypeValueOrProperty(propertyValue, ontology, column);
                const objectProperty = ontologyProp && isObjectProperty(ontologyProp);
                const isStringTypeValue = isString(propertyValue) && shapeProperty?.[ALIAS_SH_NODE_KIND] === SH_LITERAL;
                const objectPropertyOrRDFConnectionProperty = !isStringTypeValue && !isNonIdDataValue(propertyValue) && ontologyProp && isObjectPropertyOrRDFConnectionProperty(ontologyProp, aliasesToIRIMap);
                const linkedResource = isLinkType
                    || objectTypeValueOrProperty
                    || ontologyProp && (objectProperty || objectPropertyOrRDFConnectionProperty);

                let nodeSubTree = linkedResource ? <ObjectLinks
                        configurations={configurations}
                        settings={settings}
                        location={location}
                        resource={resource}
                        browseLanguage={browseLanguage}
                        ontology={ontology}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        propertyKey={propertyKey}
                        shapeProperty={shapeProperty}
                        onConceptClick={onConceptClick}
                        context={context}
                        customizations={customizations}

                    />: <RenderPropertyValue
                    sourceResource={resource}
                    dataProperty={propertyKey}
                    propertyShape={shapeProperty}
                    theme={theme}
                    columnKey={propertyKey}
                    location={location}
                    aliasesToIRIMap={aliasesToIRIMap}
                    ontology={ontology}
                    browseLanguage={browseLanguage}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    configurations={configurations}
                    context={context}
                    customizations={customizations}
                />
                return <SimpleViewCard
                    key={propertyKey+index}
                    index={index}
                    configurations={configurations}
                    settings={settings}
                    location={location}
                    resource={resource}
                    browseLanguage={browseLanguage}
                    ontology={ontology}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    propertyKey={propertyKey}
                    customizations={customizations}
                    context={context}
                    nodeSubTree={nodeSubTree}
                />;
            }
            return <React.Fragment key={propertyKey + index}>

                {
                    this.renderTree({
                        value,
                        propertyKey,
                        depth,
                        shapeProperty,
                        parentObject: resource,
                        isImageType,
                        nodeDataEditOnFocus,
                        context

                    })
                }</React.Fragment>;
        });

        if(isSimpleView) {
            return <div datatest={'simpleViewContainer'} style={{padding : '8px'}}>
                <Grid datatest={'simpleViewGrid'} container spacing={1}>
                    {resourceDetails}
                </Grid>
            </div>;
        }

        return <div datatest={'propertyContainer'} style={rootStyle}>
            {
                resourceDetails
            }
            { lastNode }
            {
                updateFailed && <AlertSnackbarContent
                    onClose={() => this.setState({updateFailed : undefined})}
                    variant={'error'}
                    open={true}
                    autoHide={true}
                    message={updateFailed}
                />
            }
            {
                openLink === true && propertyValue && propertyShapeForNewProperty && (
                    isObjectShapeProperty(propertyShapeForNewProperty) ?
                        <LinkResourceDialog
                            onClose={this.closeLinkDialog}
                            onSaveSuccess={this.closeLinkDialog}
                            onSaveFailure={(updateFailed) => {
                                this.closeLinkDialog();
                                this.setState({updateFailed : updateFailed })
                            }}
                            sourceResource={resource}
                            linkProperty={aliasesMap[propertyValue.value] || propertyValue.value}
                            shapeProperty={propertyShapeForNewProperty}
                            settings={settings}
                            ontology={ontology}
                            configurations={configurations}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            browseLanguage={browseLanguage}
                            location={location}
                        /> : <AddDataPropertyValueDialog
                            configurations={configurations}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            ontology={ontology}
                            settings={settings}
                            location={location}
                            sourceResource={resource}
                            browseLanguage={browseLanguage}
                            onClose={this.closeLinkDialog}
                            onSaveSuccess={this.closeLinkDialog}
                            propertyLabel={propertyValue.label}
                            propertyShape={propertyShapeForNewProperty}
                        />
                )
            }

        </div>;
    }

    isInViewProperties = (k) => {
        let {viewProperties, aliasesMap, settings} = this.props;
        return isInViewProperties(viewProperties, aliasesMap, k, settings);
    }

    render() {
        let {depth} = this.props;
        let {resourceInState} = this.state;
        return resourceInState
            ? this.renderObject(resourceInState, depth === undefined ? 0 : depth, true, true)
            : <></>
    }

}

Tree.propTypes = {
    permissionService: PropTypes.instanceOf(PermissionService),
    customizations: PropTypes.object,
    location: PropTypes.object,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    settings: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    depth: PropTypes.any,
    viewProperties: PropTypes.any,
    resource: PropTypes.object,
    disableLoadResource: PropTypes.any,
    onConceptClick: PropTypes.func,
};

export default (withStyles(styles, {withTheme: true})(withPermissions(Tree)));
