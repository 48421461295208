import {FormControlLabel, IconButton, InputLabel, MenuItem, RadioGroup, Typography} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import React, {useState} from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import YasqeWrapper from "./sparql/YasqeWrapper";
import H3Title from "../../components/H3Title";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {centerVertically} from "../../components/util";
import {ClearOutlined, DeleteForeverOutlined, FormatColorResetOutlined, RotateLeftOutlined} from "@material-ui/icons";
import ErrorMessage from "../../components/ErrorMessage";
import uuid4 from "uuid/v4";
import FieldContainer from "../../components/FieldContainer";

export const VIS_RADIO_VALUE_DATA = "data";
const VALUES = [VIS_RADIO_VALUE_DATA, "sparql"]
export function VisualisationSetting({label, valueObject, sparqlVars, defaultValue, onChange}) {
    const [radioValue, setRadioValue] = useState(valueObject?.['radioValue'] || VALUES[0]);
    const [selected, setSelected] = useState(valueObject?.['selectedVariable']);
    const [yasqe, setYasqe] = useState();
    let [bindings, setBindings] = useState(valueObject?.['bindings'] || []);
    let [uuid, setUuid] = useState();

    const handleChange = (radioValue, selected, query) => {
        onChange?.({
            radioValue,
            selectedVariable : selected,
            query : query || yasqe?.getValue(),
            bindings
        })
    }

    const validateBinding = (bd, queryValue) => {
        let value = bd.value;
        let valueToCheck = value ? value.replace('?', '\\?').replace('$', '\\$') : value;
        if(value && ! new RegExp('[\\s\\r\\n{(.;]'+valueToCheck+'[\\s\\r\\n}).;]').test(queryValue)) {
            bd.error = "Variable string not found in query."
        } else {
            delete bd.error;
        }
    }

    const getBindings = () => {
        return bindings;
    }

    const onYasqeSetup = (yasqe) => {
        yasqe.on("beforeChange", (instance, changeObj) => {
        });
        yasqe.on("change", (instance) => {
            const value = yasqe.getValue();
            handleChange(radioValue, selected, value);
            const bindings1 = getBindings();
            console.log("bin", bindings1);
            if(bindings1.length > 0) {
                bindings.map(bd => validateBinding(bd, value));
                setBindings([...bindings]);
            }
        });
        yasqe.on("query", () => {
            yasqe.gsStart = Date.now();
            yasqe.options.readOnly = true;
        });
        yasqe.on("queryResponse", async (instance, req) => {
            const runTime = yasqe.gsStart ? Date.now() - yasqe.gsStart : 0;
        });
        setYasqe(yasqe);
    };

    const hasDefault = sparqlVars.includes(defaultValue);
    const selectedValue = selected || (hasDefault ? defaultValue : "");
    return <FieldContainer datatest={label}>
        <H3Title>{label}</H3Title>
        <RadioGroup row={true} onChange={(ev) => {
            let value = ev.target.value;
            setRadioValue(value);
            handleChange(value, selected);
        }} value={radioValue}>
            <FormControlLabel
                value={VALUES[0]}
                control={<Radio size={'small'}/>}
                label="From Data"
                labelPlacement="end"
            />
            <FormControlLabel
                value={VALUES[1]}
                control={<Radio size={'small'}/>}
                label="From SPARQL"
                labelPlacement="end"
            />
        </RadioGroup>
        {
            radioValue === VALUES[0] ?
                <div>
                <div style={{display : "flex", gap : "8px"}}>
                    <FormControl size={'small'} variant="outlined" margin={'dense'} style={{minWidth : '400px'}} >
                        <InputLabel htmlFor="xAxis-type-select-label">{label}</InputLabel>
                        <Select
                            datatest={"select-"+label}
                            label={label}
                            labelId={"xAxis-type-select-"+ label}
                            value={selectedValue}
                            name={'classLabel'}
                            onChange={(ev) => {
                                const value = ev.target.value;
                                setSelected(value);
                                handleChange(radioValue, value);
                            }}
                        >{
                            sparqlVars.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                        }</Select>
                    </FormControl>
                    {
                        centerVertically(
                        <IconButton datatest={'resetValueButton'} disabled={(hasDefault && selectedValue === defaultValue) || !selectedValue} size={'small'} onClick={() => {
                            setSelected("");
                            handleChange(radioValue, undefined);
                        }}>{hasDefault ? <RotateLeftOutlined/> : <ClearOutlined/>}</IconButton>
                        , {})
                    }
                </div>
                    <Typography variant={'caption'}>Default variable for this is '{defaultValue}'</Typography>
                </div>
                : <div>
                    <YasqeWrapper
                        containerStyle={{minWidth : '300px'}}
                        classList={[]}
                        propertyList={[]}
                        key={""}
                        initialQuery={valueObject?.query}
                        onSetup={onYasqeSetup}
                    />
                    <div style={{display : "flex", flexDirection : 'column', gap : '8px'}}>
                    {
                        bindings.map((bd, index) => {
                            return <React.Fragment key={index}><div key={"-"+index} style={{display : "flex", gap : "24px"}}>
                                {centerVertically(<TextField style={{width : "300px"}} value={bd.value} label={'variable'} onChange={(ev) => {
                                    bd.value = ev.target.value;
                                    validateBinding(bd, yasqe.getValue());
                                    setUuid(uuid4());
                                }}></TextField>)}
                                <FormControl size={'small'} variant="outlined" margin={'dense'} style={{minWidth : '300px'}} >
                                    <InputLabel htmlFor="xAxis-type-select-label">{'Value From'}</InputLabel>
                                    <Select
                                        datatest={'Value From'}
                                        label={'Value From'}
                                        labelId={"xAxis-type-select-"+ 'Value From'}
                                        value={bd.bindTo || ""}
                                        name={'classLabel'}
                                        onChange={(ev) => {
                                            const value = ev.target.value;
                                            bd.bindTo = value;
                                            setUuid(uuid4());
                                        }}
                                    >{
                                        sparqlVars.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                                    }</Select>
                                </FormControl>
                                {
                                    centerVertically(
                                        <IconButton size={'small'} onClick={() => {
                                            bindings.splice(index, 1);
                                            setUuid(uuid4());
                                        }}>
                                            <DeleteForeverOutlined/>
                                        </IconButton>
                                    )
                                }

                            </div>
                                <ErrorMessage key={bd.error} error={bd.error}></ErrorMessage>
                            </React.Fragment>
                        })
                    }
                    <div>
                        <Button disabled={!yasqe?.getValue()} fullWidth={false} variant={'outlined'} size={'small'} onClick={() => {
                            bindings.push({value : ""})
                            setBindings(bindings);
                            setUuid(uuid4());
                        }}>Add Binding</Button>
                    </div>
                    </div>

                </div>
        }

    </FieldContainer>
}