import React from "react";
import MermaidWrapper, {getPropertyValue} from "./MermaidWrapper";

const diagramData = 'diagramData';

export const MERMAID_DIAGRAM_SETTINGS = [
    {label : "diagramData", stateKey : diagramData}
];

export default class MermaidDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData: ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {
        })
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let themeSetting = diagramSettings?.['theme'] ? `
%%{
  init: {
    'theme': '${diagramSettings['theme']}'
  }
}%%
` : '';
        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }

        let usedIdsMap = {};
        let mermaidData = themeSetting +'';

        let mermaidDataCollector = [];
        this.process(usedIdsMap, mermaidDataCollector, resolvedValues);
        mermaidData = mermaidData + "" + mermaidDataCollector.join("");
        console.log("mermaidData", mermaidData)
        return mermaidData;
    }

    process = (usedIdsMap, mermaidDataCollector, resolvedValues) => {
        resolvedValues.forEach(rv => {
            const data = rv[diagramData];
            mermaidDataCollector.push( "\n"+data);
        });
    }

    render() {
        const {mermaidData} = this.state;

        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}} >{mermaidData}</MermaidWrapper>}
        </>;
    }

}