import AllPartsLayout from "../AllPartsLayout";
import React, {useRef, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import {styles} from "../../components/styles";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    InputLabel,
    LinearProgress,
    makeStyles,
    MenuItem,
    Popper,
    RadioGroup,
    TextField as OtherTextField,
    TextField as MuiTextField,
    Tooltip,
    useTheme
} from "@material-ui/core";
import TextField, {CustomLockIcon} from "../../components/TextField.jsx"
import {
    AddCircleOutlined,
    AddOutlined,
    ArrowUpwardOutlined,
    AssistantOutlined,
    BlockOutlined,
    CheckCircleOutline,
    CloseOutlined,
    ComputerOutlined,
    DeleteForeverOutlined,
    DnsOutlined,
    ErrorOutline,
    FileCopyOutlined,
    KeyboardVoiceOutlined,
    PlayArrowOutlined,
    QuestionAnswerOutlined,
    RecordVoiceOverOutlined,
    SaveOutlined,
    StorageOutlined,
    ThumbDownOutlined,
    ThumbUpOutlined,
    ViewListOutlined
} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {default as CustomIconButton} from "../../components/IconButton.jsx";
import {
    chatCompletion,
    createModel,
    deleteMessages,
    deleteModel,
    generateQuery,
    getModelMetricsData,
    getModels,
    getModelTrainingData,
    patchMessages,
    postBatchMessages,
    postMessages,
    runSPARQLQuery
} from "../../service/ai";
import {
    centerVertically,
    getBrowseLanguageCode,
    getDatasetId,
    getDatasetLabel,
    getGraph,
    getResourceId,
    getSearchResult,
    handleBackendError,
    isRequestSuccessful,
    scrollToView,
    toArray
} from "../../components/util";
import FieldContainer from "../../components/FieldContainer";
import {
    getDefaultAIModel,
    getFeedbackBackgroundColor,
    getFeedbackBorderColor,
    getUserMessageTemplate
} from "./BasicSetup";
import {getBindingValue, searchTaxonomyConcepts, searchTaxonomyXLLabels} from "../../service/sparql-queries";
import SearchResult from "./SearchResult";
import {loadResource} from "../../service/data-loader";
import {
    ALIAS_MANAGEMENT_ID_LABEL,
    ALIAS_MANAGEMENT_LINKED_TO,
    ALIAS_MANAGEMENT_TYPE_AI_MODEL,
    ALIAS_MANAGEMENT_TYPE_SESSION,
    ALIAS_MANAGEMENT_TYPE_TRAINING_SET,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_HAS_NEXT_PAGE,
    ALIAS_SYS_PATCH,
    ALIAS_SYS_POST,
    ALIAS_SYS_RESULTS,
    AT_CONTEXT,
    AT_GRAPH,
    COUNT_UPTO,
    EASYGRAPH_DATA_NAMESPACE,
    GRAPH,
    ID,
    PAGE,
    PAGE_SIZE,
    QUERY,
    SORT_BY,
    TYPE
} from "../../Constants";
import {getSiteHomePath, isAIViewPath, RESULT_VIEW} from "./Workspace";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorMessage from "../../components/ErrorMessage";
import {
    getUiLabelTranslation,
    UI_LABELS_SAVE,
    UI_LABELS_SPARQL_RUN,
    UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC,
    UI_LABELS_TO_SAVE_PRESS_ENTER_OR_ESC
} from "./UILabel";
import Select from "@material-ui/core/Select";
import {DataGridWithFilters, initSPARQLVariableToTitle, StyledDataGrid} from "./DataVisualisationView";
import FormControl from "@material-ui/core/FormControl";
import {getNavigateToChatSessionLink} from "./LeftTreeComponents";
import history from "../../history";
import {findNodeInTreeData} from "./LabelTree";
import throttle from "lodash/throttle";
import {getValuesObject} from "./SearchResultItem";
import {Autocomplete} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SaveDialogWithTitle from "./SaveDialogWithTitle";
import uuid4 from "uuid/v4";
import queryString from "query-string";
import YasqeWrapper from "./sparql/YasqeWrapper";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {getBaseEndpointWithInstance, getData, getManagementContextURL} from "../../service/graph-api";
import {
    BACKEND_PATH_MANAGEMENT_DATASET_SEARCH,
    BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH
} from "../../service/backend-paths";
import {getUserIri, isSuperadmin} from "../common/Profile";
import H4Title from "../../components/H4Title";
import LockIcon from "@material-ui/icons/Lock";
import DetailsViewButton from "../../components/DetailsViewButton";
import {Legend, Line, LineChart, ResponsiveContainer, Tooltip as RecTooltip, XAxis, YAxis} from "recharts";
import EditableTextField from "../../components/EditableTextField";
import EditableTextArea from "../../components/EditableTextArea";
import ListSubheader from "@material-ui/core/ListSubheader";
import {getBaseAIModels, isMultipleDatasetsInstance} from "../../Configs";
import {cloneDeep} from "lodash";
import Radio from "@material-ui/core/Radio";
import TrainModelDialog, {TARGET_CLASS_BASE_IRIS, TARGET_CLASS_IRIS, TYPE_AI_MODEL} from "./TrainModelDialog";
import H3Title from "../../components/H3Title";
import {setLinkedToId} from "./Workspaces";

export function getNewIdForSession(uuid) {
    return EASYGRAPH_DATA_NAMESPACE + 'session/' + uuid;
}

export function getNewIdForTrainingSet(uuid) {
    return EASYGRAPH_DATA_NAMESPACE + 'trainingset/' + uuid;
}


export function getNewIdForUserMessage(uuid) {
    return EASYGRAPH_DATA_NAMESPACE + 'usermessage/' + uuid;
}

export function getNewIdForAssistantMessage(uuid) {
    return EASYGRAPH_DATA_NAMESPACE + 'assistantmessage/' + uuid;
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

const Search = ({conceptSchemeId,
                    aliasesToIRIMap,
                    location,
                    settings,
                    ontology,
                    onSelect,
                    aliasesMap, browseLanguage, treeTab, mainStyle = {}}) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                let browseLanguageCode = getBrowseLanguageCode(browseLanguage);
                let searchFunction;
                if (true) {
                    searchFunction = searchTaxonomyConcepts;
                } else {
                    searchFunction = searchTaxonomyXLLabels;
                }
                searchFunction(conceptSchemeId, request.input, browseLanguageCode).then(ids => {
                    loadResource(ids).then(resources => {
                        Promise.all(resources.map(async r => {
                            let valuesObject = await getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology);
                            if(valuesObject.title === undefined) {
                                valuesObject.title = getResourceId(r);
                            }
                            valuesObject.backingObject = r;
                            return valuesObject;
                        })).then(r => {
                            callback(r);
                        })
                    })
                })
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            if(value) {
                setOptions(value ? [value] : []);
            } else {
                fetch({ input: inputValue }, (results) => {
                    if (active) {
                        setOptions(results);
                    }
                });
            }
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            datatest={'taxonomySearch'}
            id="free-solo-demo"
            freeSolo
            value={value}
            style={{ marginRight: 16, ...mainStyle }}
            options={options}
            filterOptions={(x) => x}

            getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
            onChange={(event, newValue, reason) => {
                if (reason === 'select-option') {
                    onSelect(newValue.backingObject);
                } else {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            renderInput={(params) => (
                <MuiTextField {...params} label="Search" margin="normal" variant="outlined" />
            )}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="h3" color="textSecondary">
                                {option.title || getResourceId(option)}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}


export const models = [
    "gpt-4o-2024-05-13",
    "ft:gpt-3.5-turbo-1106:personal:skos-fine-tuning:9lDqZCnj" ,
    "ft:gpt-3.5-turbo-0125:personal:skos-fine-tuning:9lFE5xUZ"
]

const SPARQL_RESULTS = 'sparql-results';
const STORAGE_KEY_CHAT_SESSIONS = 'chatSessions';
const STORAGE_KEY_CHAT_MESSAGES = 'chatMessages';
const STORAGE_KEY_TRAINING_SETS = 'trainingSets';
const STORAGE_KEY_TRAINING_DATA = 'trainingData';
const STORAGE_KEY_MODELS = 'models';

async function getItemsArray(storageKey) {
    const items = localStorage.getItem(storageKey);
    return items ? toArray(JSON.parse(items)) : [];
}

async function storeMessages(messages) {
    const items = await getItemsArray(STORAGE_KEY_CHAT_MESSAGES);
    let toStore = [...items, ...toArray(messages)];
    localStorage.setItem(STORAGE_KEY_CHAT_MESSAGES, JSON.stringify(toStore));
}

export const CHAT_SESSIONS_VIEW = "qa";
export const TRAINING_SETS_VIEW = "training";
export const MODELS_VIEW = "models";

function isChatSessionsView(treeTab) {
    return treeTab === CHAT_SESSIONS_VIEW;
}

function isTrainingSetsView(treeTab) {
    return treeTab === TRAINING_SETS_VIEW;
}

function isModelsView(treeTab) {
    return treeTab === MODELS_VIEW;
}

export const SPARQL_SELECT_RESULTS = 'sparqlSelectResults';

const TYPE_USER_MESSAGE = "UserMessage";

const TYPE_ASSISTANT_MESSAGE = "AssistantMessage";

function UserMessage({settings, browseLanguage, message, onQueryRun, onDelete}) {
    const theme = useTheme();
    const inputRef = useRef(null);
    const [edit, setEdit] = useState(false);

    const sendMessage = () => {
        setEdit(false);
        onQueryRun();
    }

    return <div style={{display : 'flex', gap : '16px', width : '100%'}}>
        {centerVertically(<RecordVoiceOverOutlined  style={{color : theme.palette.grey.level3}}/>)}
        {
            onQueryRun === undefined ? <MuiTextField
                    fullWidth={true}
                    readOnly={true}
                    value={message.content}
                    InputProps={{
                        endAdornment : <CustomLockIcon theme={theme}/>
                    }}
                /> :
            centerVertically(
                <EditableTextField
                    datatest={'userMessageText'}
                    fullWidth={true}
                    value={message.content}
                    onConfirm={(label, value) => {
                        message.content = value;
                        sendMessage();
                    }}
                    inputRef={inputRef}
                /> ,
                {flexGrow  : '1'}
            )
        }
        {onDelete && centerVertically(<IconButton datatest={'messageDelete'} color={'primary'} size={'small'} onClick={onDelete}><DeleteForeverOutlined></DeleteForeverOutlined></IconButton>)}
    </div>;
}

function getFeedbackValue(assistantMessage) {
    const userFeedbackArray = toArray(assistantMessage.userFeedback);
    const userFeedback = userFeedbackArray.length > 0 ? userFeedbackArray[0] : undefined;

    return userFeedback ? Number(userFeedback) : undefined;
}

export function AIFeedback({assistantMessage, readOnly}) {
    const [uuid, setUUID] = useState();
    const handleFeedback = (feedbackValue) => {
        assistantMessage.userFeedback = feedbackValue;
        updateResource(assistantMessage, [assistantMessage]);
        setUUID(uuid4());
    }

    const userFeedbackValue = getFeedbackValue(assistantMessage);

    const thumbsUp = userFeedbackValue === 1;
    const thumbsDown = userFeedbackValue === -1;
    return <React.Fragment key={uuid}>
        <CustomIconButton
            size={'small'}
            datatest={'thumbsUp'}
            onClick={() => handleFeedback("1")}
            selected={thumbsUp}
            disabled={readOnly}
        >
            <ThumbUpOutlined/>
        </CustomIconButton>
        <CustomIconButton
            size={'small'}
            datatest={'thumbsDown'}
            onClick={() => handleFeedback("-1")}
            selected={thumbsDown}
            disabled={readOnly}
        >
            <ThumbDownOutlined/>
        </CustomIconButton>
    </React.Fragment>;
}

export function MessageCopy({userMessage, assistantMessage, location}) {
    const theme = useTheme();
    const [open, setOpen] = useState();
    const [copyTo, setCopyTo] = useState('session');
    const [sessions, setSessions] = useState([]);
    const [sessionsLoaded, setSessionsLoaded] = useState(false);
    const [session, setSession] = useState();
    const [trainingSets, setTrainingSets] = useState([]);
    const [trainingSetsLoaded, setTrainingSetsLoaded] = useState(false);
    const [trainingSet, setTrainingSet] = useState();
    const [result, setResult] = useState();

    const handleCopyClick = async () => {
        setOpen(true);

        let containedIn = [...toArray(userMessage?.containedIn), ...toArray(assistantMessage?.containedIn)]
        let idFilter = containedIn.length > 0 ? 'id(neq:["'+containedIn.join('" "')+'"])' : "";

        let datasetId = isMultipleDatasetsInstance() && await getDatasetId();
        let query1 = `{${idFilter} type(eq:["${ALIAS_MANAGEMENT_TYPE_SESSION}"]) ${datasetId ? ` ${ALIAS_MANAGEMENT_LINKED_TO}(eq:"${datasetId}") ` : ''} createdBy(eq:["${getUserIri()}"]) }`;
        const searchParams = {
            [QUERY] : query1,
            [PAGE] : 1,
            [PAGE_SIZE] : 1000,
            [COUNT_UPTO] : 1000
        }
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams).then(async searchData => {
            if(isRequestSuccessful(searchData)) {
                const items = await searchData.json();
                setSessions(items[ALIAS_SYS_RESULTS]);
                setSessionsLoaded(true);
            }
        }).catch(handleBackendError(this));
        if(isSuperadmin()) {
            let query2 = `{${idFilter} type(eq:["${ALIAS_MANAGEMENT_TYPE_TRAINING_SET}"]) ${datasetId ? ` ${ALIAS_MANAGEMENT_LINKED_TO}(eq:"${datasetId}") ` : ''} createdBy(eq:["${getUserIri()}"]) }`;
            const searchParams2 = {
                [QUERY]: query2,
                [PAGE]: 1,
                [PAGE_SIZE]: 1000,
                [COUNT_UPTO]: 1000
            }
            getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams2).then(async searchData => {
                if (isRequestSuccessful(searchData)) {
                    const items = await searchData.json();
                    setTrainingSets(items[ALIAS_SYS_RESULTS]);
                    setTrainingSetsLoaded(true);
                }
            }).catch(handleBackendError(this));
        }
    }

    const isCopyButtonDisabled = () => {
        if(sessionsLoaded === false) {
            return true;
        }
        if(isSuperadmin() && trainingSetsLoaded === false) {
            return true;
        }
        if(sessionsLoaded && copyTo !== 'trainingSet' && sessions.length === 0) {
            return true;
        }
        if(trainingSetsLoaded && copyTo === 'trainingSet' && trainingSets.length === 0) {
            return true;
        }
        if(copyTo === 'trainingSet') {
            return trainingSet ? false : true;
        }
        return !session;
    }

    const aiButton = (treeTab) => <IconButton
        datatest={'aiViewButton'}
        onClick={() => {
            let path = getSiteHomePath(location) + '/view/pageForAI'+ (treeTab ? '?treeTab='+treeTab : '');
            history.push(`${path}`);
        }}
    >

        <AssistantOutlined/>
    </IconButton>;

    return <>
        <IconButton
            datatest={'messageCopyButton'}
            size={'small'}
            color={'primary'}
            onClick={handleCopyClick}
        ><FileCopyOutlined></FileCopyOutlined></IconButton>
        {open && <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={'Copy to'}/></DialogTitle>
            <DialogContent style={{minHeight : '100px'}}>
                {
                    result !== undefined ?
                        result === 'success'
                            ?  <div datatest={'success'} style={{display : 'flex', gap : '16'}}>
                                {centerVertically(<CheckCircleOutline style={{color : theme.palette.success.main}}>Copied successfully</CheckCircleOutline>)}
                                {centerVertically(<Typography> Success</Typography>)}
                            </div>
                            : <div datatest={'failed'} style={{display : 'flex', gap : '16'}}>
                                {centerVertically(<ErrorOutline style={{color : theme.palette.error.main}}></ErrorOutline>)}
                                {centerVertically(<Typography> Failed</Typography>)}
                            </div>
                        :
                    <React.Fragment>

                        {
                            isSuperadmin() &&
                            <RadioGroup datatest={'copyToRadio'} row={true} aria-label="copyTo" name="copyTo"
                                        value={copyTo}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setCopyTo(value);
                                        }}>
                                <FormControlLabel datatest={'copyToSession'} value={'session'} control={<Radio/>}
                                                  label={'Session'}/>
                                <FormControlLabel datatest={'copyToTrainingSet'} value={'trainingSet'}
                                                  control={<Radio/>} label={'Training Set'}/>
                            </RadioGroup>
                        }
                        <React.Fragment key={copyTo}>
                            {
                                copyTo === 'trainingSet' ? trainingSetsLoaded && trainingSets.length === 0 ? <Typography>No training set found. To create one click {aiButton('training')} </Typography> : <Autocomplete
                                        datatest={'trainingSetSelect'}
                                        value={trainingSet}
                                        options={trainingSets}
                                        getOptionLabel={option => option.title ? option.title : ''}
                                        getOptionSelected={(option, value) => {
                                            return option.id === value.id;
                                        }}
                                        multiple={false}
                                        onChange={(event, newValue, reason) => {
                                            setTrainingSet(newValue)
                                        }}
                                        renderInput={params => (
                                            <OtherTextField
                                                label={'Select Training Set'}
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        renderOption={(option, {selected}) => (
                                            <div datatest={option.title} style={{paddingLeft: '0px'}}>{option.title}</div>
                                        )}
                                        size={"small"}
                                        disableClearable={true}
                                    />
                                    : sessionsLoaded && sessions.length === 0 ? <Typography>No session found. To create one click {aiButton()}</Typography> :
                                    <Autocomplete
                                        datatest={'sessionSelect'}
                                        value={session}
                                        options={sessions}
                                        getOptionLabel={option => option.title ? option.title : ''}
                                        getOptionSelected={(option, value) => {
                                            return option.id === value.id;
                                        }}
                                        multiple={false}
                                        onChange={(event, newValue, reason) => {
                                            setSession(newValue)
                                        }}
                                        renderInput={params => (
                                            <OtherTextField
                                                label={'Select Session'}
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        renderOption={(option, {selected}) => (
                                            <div datatest={option.title}
                                                 style={{paddingLeft: '0px'}}>{option.title}</div>
                                        )}
                                        size={"small"}
                                        disableClearable={true}
                                    />
                            }
                        </React.Fragment>

                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    datatest={'cancelButton'}
                    variant={"outlined"}
                    color="secondary"
                    onClick={() => {
                        setOpen(false);
                        setResult(undefined);
                    }}
                >{result !== undefined ? 'Close' : 'Cancel'}</Button>
                <div style={{flexGrow :1}}></div>
                {
                    result === undefined &&
                    <Button
                        datatest={'copyButton'}
                        disabled={isCopyButtonDisabled()}
                        variant={"contained"}
                        color="secondary"
                        onClick={() => {
                            const containedIn = copyTo === 'trainingSet' ? getResourceId(trainingSet) : getResourceId(session);
                            const userMessageNew = createUserMessage(containedIn, userMessage.content, userMessage.model, userMessage[ALIAS_MANAGEMENT_LINKED_TO]);
                            const assistantMessageNew = createAssistantMessage(containedIn, assistantMessage.content, getResourceId(userMessageNew), assistantMessage.model, assistantMessage[ALIAS_MANAGEMENT_LINKED_TO]);
                            userMessageNew.assistantMessage = getResourceId(assistantMessageNew);

                            updateResource([userMessageNew, assistantMessageNew], [], () => {
                                setResult('success');
                            }, () => {
                                setResult('failed');
                            })
                        }}
                    >Copy</Button>
                }
            </DialogActions>
        </Dialog>}
    </>;
}

export function Message({settings, defaultShowQuery, browseLanguage, message, content, feedbackBGColor, feedbackBorderColor, runningQuery, onQueryRun, isLoadDataButton, messageBoxStyleOverride, onSaveQuery, actionProvider}) {
    const theme = useTheme();
    const [showQuery, setShowQuery] = useState(defaultShowQuery);
    const [yasqeObj, setYasqeObj] = useState(false);
    let isAssistantMessage = message.type === TYPE_ASSISTANT_MESSAGE;

    let messageBoxStyle = {
        margin : '16px',
        marginLeft : isAssistantMessage ?  '64px' : "16px" ,
        padding : '8px',
        border : '1px solid '+feedbackBorderColor,
        backgroundColor : (feedbackBGColor ||  theme.palette.white.main),
        borderRadius : '24px',
        borderTopLeftRadius : '0px',
        borderTopRightRadius : '0px',
    };
    if(!isAssistantMessage) {
        messageBoxStyle['backgroundColor'] = theme.palette.grey.main
        messageBoxStyle['borderBottomRightRadius'] = "0px"
        messageBoxStyle['borderRadius'] = "24px"
        messageBoxStyle['borderTopLeftRadius'] = "24px"

    }
    if(message['error']) {
        messageBoxStyle['backgroundColor'] = theme.palette.warning.contrastText
    }
    if(messageBoxStyleOverride) {
        messageBoxStyle = {
            ...messageBoxStyle,
            ...messageBoxStyleOverride
        }
    }
    const innerContainerStyle = {overflow : 'auto', padding : '24px', maxWidth : '100%', display : 'flex', gap : '24px'};
    if(!isAssistantMessage) {
        innerContainerStyle['padding'] = "8px"
    }

    const runQuery = () => {
        try {
            onQueryRun(yasqeObj.getValue());
            //yasqeObj.query();
        }
        catch (e) {
            console.log("Error :", e)
            this.setState({
                error: true,
                errorMessage : "Could not run query."
            });
        }
    };

    const isRunningQuery = runningQuery === getResourceId(message)

    return <FieldContainer key={getResourceId(message)}  datatest={'chatMessage'} style={messageBoxStyle}>
        {isAssistantMessage && <div style={{display : 'flex', gap : '16px'}}>
        <AssistantOutlined style={{color : theme.palette.grey.level3}}/>
            {
                centerVertically(
                <div style={{marginLeft: '8px'}}>
                    <FormControlLabel
                        control={
                            <Switch
                                datatest={'showQuery'}
                                size={"small"}
                                checked={showQuery}
                                value={true}
                                onChange={() => {
                                    setShowQuery(!showQuery);
                                }}
                                name="showQuery"
                            ></Switch>
                        }
                        label={<Typography style={{color: theme.palette.primary.main}}>Show Query</Typography>}
                    />
                </div>
                )
            }
            {actionProvider && actionProvider()}
        </div>}
        {isAssistantMessage && <div style={{...innerContainerStyle, paddingTop : '0px', paddingBottom : '0px'}}>
            <div key={message.content} style={{flexGrow : '1'}}>

            {
                showQuery &&
                <>
                    <YasqeWrapper
                        containerStyle={{marginTop :'8px'}}
                        classList={[]}
                        propertyList={[]}
                        key={'yasque-'+getResourceId(message.id)}
                        initialQuery={message.content}
                        onSetup={(yasque) => {
                            //yasque.options.readOnly = true;
                            setYasqeObj(yasque);
                            //yasque.setValue(message.content)
                            yasque.on("change", (instance) => {
                                message.content = yasque.getValue();
                            });

                        }}
                    />
                    <div style={{display : 'flex', gap : '16px'}}>
                    <Button
                        datatest={'runQueryButton'}
                        disabled={isRunningQuery}
                        startIcon={isRunningQuery ? <CircularProgress size={16} color="secondary" /> : <PlayArrowOutlined></PlayArrowOutlined>}
                        color={'secondary'}
                        variant={'outlined'}
                        onClick={runQuery}
                    >{getUiLabelTranslation(settings, UI_LABELS_SPARQL_RUN, browseLanguage, UI_LABELS_SPARQL_RUN)}</Button>
                    {
                        onSaveQuery && <Button
                            datatest={'saveQueryButton'}
                            startIcon={<SaveOutlined></SaveOutlined>}
                            color={'secondary'}
                            variant={'outlined'}
                            onClick={onSaveQuery}
                        >{getUiLabelTranslation(settings, UI_LABELS_SAVE, browseLanguage, UI_LABELS_SAVE)}</Button>

                    }
                    </div>
                </>
            }
            </div>
        </div>}
        <div key={message.loadedAt ||""} style={innerContainerStyle}>
            {isLoadDataButton && showQuery ? <></> :content}
        </div>
    </FieldContainer>;
}


export const NATURAL_LANGUAGE_RESPONSE = "NATURAL_LANGUAGE_RESPONSE";

export async function loadDataForQuery(userMessage, assistantMessage, naturalise=false) {
    const sparql = assistantMessage.content;
    const result = await runSPARQLQuery(sparql);
    let bindings = result["results"]?.["bindings"];
    userMessage['assistantMessage'] = getResourceId(assistantMessage);

    const varname = bindings.length > 0 && Object.keys(bindings[0]).length === 1 ? Object.keys(bindings[0])[0] : undefined;
    const ids = varname ? bindings.filter(bn => bn[varname]?.['type'] === 'uri').map(bn => getBindingValue(bn, varname)) : [];
    let results ;
    delete assistantMessage[ALIAS_SYS_RESULTS];
    delete assistantMessage[SPARQL_SELECT_RESULTS];

    if(ids.length < 1) {
        results = result;
        assistantMessage[SPARQL_SELECT_RESULTS] = results;
        if(naturalise) {
            let messages = [
                {
                    "role": "user",
                    "message": `
A user asked question "${userMessage.content}"

A SPARQL query generated below response 

"""
${JSON.stringify(result, null, 2)}
"""

From the query result above answer the question in plain English.
                `
                }
            ]
            try {
                const completions = await chatCompletion(messages, assistantMessage.model);
                const completionsJSON = await completions.json();
                if (completionsJSON.choices && toArray(completionsJSON.choices).length > 0) {
                    assistantMessage[NATURAL_LANGUAGE_RESPONSE] = completionsJSON.choices[0]?.message?.content;
                }
            } catch (e) {
                console.log("Failed to generate natural language response", e);
            }
        }
    } else {
        let idsToUse = [];
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            if (idsToUse.length < 30 && !idsToUse.includes(id)) {
                idsToUse.push(id);
            }
        }
        results = await loadResource(idsToUse);
        assistantMessage[ALIAS_SYS_RESULTS] = results.filter(r => r);
    }
    assistantMessage['loadedAt'] = new Date();
}


function createAssistantMessage(containedIn, sparql, userMessageId, model, linkedTo) {
    const resource = {
        id: getNewIdForAssistantMessage(uuid4()),
        type: TYPE_ASSISTANT_MESSAGE,
        containedIn: containedIn,
        createdOn: new Date(),
        content: sparql,
        userMessage: userMessageId,
        model: model,
        createdBy: getUserIri()
    };
    if(linkedTo) {
        setLinkedToId(resource, linkedTo);
    }
    return resource;
}

function createUserMessage(containedIn, message, model, linkedTo) {
    const resource = {
        id: getNewIdForUserMessage(uuid4()),
        type: TYPE_USER_MESSAGE,
        containedIn: containedIn,
        createdOn: new Date(),
        content: message,
        model: model,
        createdBy: getUserIri()
    };
    if(linkedTo) {
        setLinkedToId(resource, linkedTo);
    }
    return resource;
}

export function sendMessageToAI(message, resource, model, onComplete, linkedTo, naturalise, userMessageTemplate) {
    const containedIn = getResourceId(resource);
    let userMessage = createUserMessage(containedIn, message, model.model, linkedTo);
    generateQuery(message, model?.[TARGET_CLASS_BASE_IRIS], model?.[TARGET_CLASS_IRIS], undefined, model?.model, userMessageTemplate).then(async r => {
        const sparql = r['sparql'];
        let assistantMessage = createAssistantMessage(containedIn, sparql, getResourceId(userMessage), model?.model, linkedTo)
        try {
            await loadDataForQuery(userMessage, assistantMessage, naturalise);
        } catch (e) {
            assistantMessage['error'] = "Failed to generate response";
        } finally {
            onComplete(userMessage, assistantMessage);
        }
    }).catch(e => {
        onComplete(undefined, undefined, e);
    })
}

function processForBackendAPI(data) {
    delete data[ALIAS_SYS_RESULTS];
    delete data[SPARQL_SELECT_RESULTS];
    return data;
}

async function updateEtags(apiResponseData, inMemResourceToUpdate) {
    const postJSON = await apiResponseData.json();
    let graph = getGraph(postJSON);
    graph.forEach(rs => {
        inMemResourceToUpdate.forEach(imr => {
            if(imr[ID] === getResourceId(rs)) {
                imr[ALIAS_SYS_ETAG] = rs[ALIAS_SYS_ETAG];
            }
        });
    });
}

export function updateResource(resourceArray, inMemResourceToUpdate, onSuccess, onFailure, container) {
    const resourceArrayInternal = toArray(resourceArray);
    const processedForAPI = cloneDeep(resourceArrayInternal).map(r => processForBackendAPI(r));
    processedForAPI.forEach(r => {
        if(!r.createdBy) {
            r.createdBy = getUserIri();
        }
    });
    let payload = {
        [AT_CONTEXT] : getManagementContextURL(),
        [ALIAS_SYS_POST] : [],
        [ALIAS_SYS_PATCH] : []
    }
    const toPost = processedForAPI
        .filter(c => !c[ALIAS_SYS_ETAG])
        .map(c => {
            if(getResourceId(container)) {
                c.containedIn = getResourceId(container);
            }
            return c;
        });
    payload[ALIAS_SYS_POST] = toPost;
    processedForAPI.forEach(c => {
        if(c[ALIAS_SYS_ETAG]) {
            payload[ALIAS_SYS_PATCH].push(c);
        }
    });
    const nothingToPatch = payload[ALIAS_SYS_PATCH].length === 0;
    const nothingToPost = payload[ALIAS_SYS_POST].length === 0;
    if(nothingToPatch) {
        let postPayload = {
            [AT_CONTEXT] : getManagementContextURL(),
            [GRAPH] : payload[ALIAS_SYS_POST]
        }
        postMessages(JSON.stringify(postPayload)).then(async d => {
            if (d.status === 200) {
                await updateEtags(d, inMemResourceToUpdate);
                onSuccess?.();
            } else {
                onFailure?.(d);
            }
        });
    } else if (nothingToPost) {
        let patchPayload = {
            [AT_CONTEXT] : getManagementContextURL(),
            [GRAPH] : payload[ALIAS_SYS_PATCH]
        }
        patchMessages(JSON.stringify(patchPayload)).then(async d => {
            if (d.status === 200) {
                await updateEtags(d, inMemResourceToUpdate);
                onSuccess?.();
            } else {
                onFailure?.(d);
            }
        });

    } else {
        postBatchMessages(JSON.stringify(payload)).then(async d => {
            if (isRequestSuccessful(d)) {
                onSuccess?.();
            } else {
                onFailure?.(d);
            }
        })
    }
}

function ModelUsageData({model}) {
    return
}

const TRAINING_SET_COMPACT_VIEW = "TRAINING_SET_COMPACT_VIEW";
const TRAINING_SET_DETAIL_VIEW = "TRAINING_SET_DETAIL_VIEW";

const TRAINING_DATA_VIEW = "TRAINING_DATA_VIEW";

const MODEL_METRICS_VIEW = "METRICS_VIEW";
const MODEL_USAGE_VIEW = "USAGE_VIEW";
const MODEL_TEST_VIEW = "TEST_VIEW";

class AIInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid : uuid4(),
            chatData : [],
            listening: false,
            model : getDefaultAIModel(this.props.settings),
            treeData : [],
            selectedNodeId : undefined,
            expandedNodeIds : []

        }
        this.chatEndRef = React.createRef();
        this.chatStartRef = React.createRef();
        this.gridRef = [];

        //Below handles browser back/forward button
        this.backListener = this.props.history.listen( (location, action) => {
            if(isAIViewPath(location)) {
                let params = queryString.parse(location.search);
                let treeTabFromParam = params['treeTab'];
                let newResourceId = params['treeNodeId'];
                this.loadAndSelectResource(newResourceId, treeTabFromParam).catch(() => {});
            }

        })
    }

    componentDidMount() {
        let {location} = this.props;
        let params = queryString.parse(location.search);
        let resourceId = params['treeNodeId'];
        const treeTabValue = this.getTreeTabValue();
        this.loadDatasets().then(() => {
            this.refreshModelsData(() => {
                this.loadAndSelectResource(resourceId, treeTabValue).catch(() => {
                });
            });
        });
    }

    loadDatasets = async () => {
        if(isMultipleDatasetsInstance()) {
            const apiResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET_SEARCH).catch(handleBackendError(this));
            let json = await apiResponse.json();
            let datasets = getSearchResult(json);
            this.setState({datasets : datasets})
        }
    }


    refreshModelsData = (onComplete) => {
        getModels(this.getDatasetId()).then(async result => {
            if(isRequestSuccessful(result)) {
                const items = await result.json();
                const models = toArray(items[ALIAS_SYS_RESULTS]);
                this.setState({models : models}, () => {
                    onComplete?.();
                });
            } else {
                this.setState({apiErrorResponse : result});
            }
        })
    }

    componentWillUnmount() {
        this.backListener?.();
    }

    loadAndSelectResource = async (resourceId, treeTab) => {
        //By default, set the middle resource to ontology
        let resource = undefined;
        let {treeData} = this.state;
        let currentResource = this.state.resource;
        //Load relevant tree data only if needed
        if (resourceId) {
            resource = treeData.find(r => r && getResourceId(r) === resourceId);
        }
        if(!resource) {
            treeData = await this.getTopLevelTreeData(treeTab);
            resource = treeData.find(r => r && getResourceId(r) === resourceId);
        }
        if(resource) {
            if (getResourceId(currentResource) !== getResourceId(resource)) {
                let chatData = await this.getMessages(resourceId);
                //let chatData = chatMessages.filter(cm => toArray(cm['containedIn']).includes(getResourceId(resource)));
                this.setState({chatData})
            }
        } else {
            this.setState({chatData: [], selectedNodeId: undefined});
        }
        this.setState(
            {
                modelMetricsData : [],
                modelMetricsLoading : false,
                modelSubView: undefined,
                treeData: treeData,
                resource: resource,
                treeLoading: false,
                selectedNodeId: getResourceId(resource)
            },
            () => {
                if (resource) {
                    this.chatEndRef.current?.scrollIntoView({ block: 'end',behavior: "smooth" });

                    const nodeElement2 = document.getElementById('middleContentId');
                    nodeElement2 && scrollToView(nodeElement2, 'instant');
                    this.handleResourceSelect(treeData, resource);
                }
            }
        )
    }

    handleResourceSelect = async (treeData, newResourceToSelect) => {
        let {selectedNodeId, expandedNodeIds} = this.state;
        const foundNode = findNodeInTreeData(treeData, newResourceToSelect, selectedNodeId, expandedNodeIds);
        if(foundNode) {
            return;
        }
    }

    handleAIError = (error) => {
        if(error instanceof Promise) {
            error.then(e => {
                this.handleAIError(e);
            });
            return;
        }
        if(error.message) {
            this.setState({apiErrorResponse: error.message});
        } else {
            this.setState({apiErrorResponse: error});
        }
    }

    sendMessage = () => {
        this.setState({loading: true});
        let {model, resource} = this.state;
        let {settings} = this.props;

        sendMessageToAI(this.message, resource, {model: model}, (userMessage, assistantMessage, error) => {
            if(error) {
                return this.handleAIError(error);
            }
            if (resource) {
                const grapData = cloneDeep([userMessage, assistantMessage]).map(d => processForBackendAPI(d));
                let payload = {
                    [AT_CONTEXT]: getManagementContextURL(),
                    [GRAPH]: grapData,
                }

                postMessages(JSON.stringify(payload)).then(async d => {
                    if (d.status === 200) {
                        const postJSON = await d.json();
                        let graph = getGraph(postJSON);
                        graph.forEach(rs => {
                            let found = [userMessage, assistantMessage].find(cd => cd[ID] === getResourceId(rs));
                            found[ALIAS_SYS_ETAG] = rs[ALIAS_SYS_ETAG];
                        });
                        this.setState({
                            loading: false,
                            chatData: [...this.state.chatData, userMessage, assistantMessage]
                        }, () => {
                            this.chatEndRef.current?.scrollIntoView({block: 'end', behavior: "smooth"})
                        })
                    } else {
                        this.setState({apiErrorResponse: d});
                    }
                });
            } else {
                this.setState({
                    loading: false,
                    chatData: [...this.state.chatData, userMessage, assistantMessage]
                }, () => {
                    this.chatEndRef.current?.scrollIntoView({block: 'end', behavior: "smooth"})
                })
            }
        }, this.getDatasetId(), getUserMessageTemplate(settings));

    }

    runQuery = (data) => {
    }

    renderDataGrid = (message) => {
        const {settings, theme, configurations, graphViewer, location, aliasesMap, aliasesToIRIMap, browseLanguage, ontology, onResourceItemCardFocus, } = this.props;
        let bindings = message[SPARQL_SELECT_RESULTS]?.["results"]?.["bindings"];
        const ref = React.createRef();
        this.gridRef.push(ref);

        return <div datatest={'dataGrid'} style={{height : '350px', width : '100%'}}>
            <DataGridWithFilters
                settings={settings}
                browseLanguage={browseLanguage}
                sparqlVarsToTitle={initSPARQLVariableToTitle(message[SPARQL_SELECT_RESULTS], [], false)}
                bindings={bindings}
                ref={ref}
                aliasesMap={aliasesMap}
                location={location}
            />
        </div>;
    }


    getChatDataForSave = () => {
        const {chatData, treeData} = this.state
        return chatData.map(c => {
            let newObj = {...c};
            delete newObj[ALIAS_SYS_RESULTS];
            delete newObj[SPARQL_SELECT_RESULTS];
            newObj.createdBy = getUserIri();
            setLinkedToId(newObj, this.getDatasetId());
            return newObj;
        });
    }

    renderStatus = () => {
        let {resource, chatData} = this.state;
        let {theme} = this.props;
        let icon = resource?.backendJobStatus === 'failed'
            ? <ErrorOutline style={{color : theme.palette.error.main}}></ErrorOutline>
            : resource?.backendJobStatus === 'succeeded'
                ? <><CheckCircleOutline style={{color : theme.palette.success.main, marginRight : '8px'}}></CheckCircleOutline></>
                : resource?.backendJobStatus === 'cancelled' ? <BlockOutlined style={{ marginRight : '8px'}}></BlockOutlined>
                    : <><LinearProgress style={{width : "32px", marginRight : '8px'}}></LinearProgress></>;
        let chatFieldHeight = '72px';

        return  <div style={{
            borderRadius: '4px',
            backgroundColor: theme.palette.white.main,
            marginBottom : '16px'
        }}>
            { resource &&
                <div style={{display: 'flex', gap: '16px', padding: '16px'}}>
                    <TextField
                        datatest={'textField-Status'}
                        innerTextLabel={"Status"}
                        value={resource?.backendJobStatus}
                        readOnly={true}
                        InputProps = {{
                            readOnly: true,
                            endAdornment: <LockIcon datatest={'readOnly'} style={{color: theme.palette.grey.level2}} fontSize={"small"}/>,
                            startAdornment : icon
                        }}
                    />
                    <TextField
                        datatest={'textField-Created On'}
                        innerTextLabel={"Created On"}
                        value={resource?.createdOn}
                        readOnly={true}
                    />
                    <TextField
                        datatest={'textField-Base Model'}
                        innerTextLabel={"Base Model"}
                        value={resource?.backendBaseModelId}
                        readOnly={true}
                    />
                </div>
            }
        </div>;
    }

    isCompactView = () => {
        const {trainingSetViewType} = this.state;
        return trainingSetViewType === TRAINING_SET_COMPACT_VIEW;
    }

    isModelTrainingDataView = () => {
        const {modelSubView} = this.state;
        return modelSubView === undefined || modelSubView === TRAINING_DATA_VIEW;
    }

    isModelMetricsDataView = () => {
        const {modelSubView} = this.state;
        return modelSubView === MODEL_METRICS_VIEW;
    }

    isModelUsageDataView = () => {
        const {modelSubView} = this.state;
        return modelSubView === MODEL_USAGE_VIEW;
    }

    isModelTestView = () => {
        const {modelSubView} = this.state;
        return modelSubView === MODEL_TEST_VIEW;
    }

    hasModelTrainingSucceeded = (resource) => {
        return resource?.backendJobStatus === 'succeeded';
    }

    renderModelMetricsData = () => {
        let {modelMetricsData, modelMetricsLoading, resource} = this.state;
        let {settings, browseLanguage} = this.props;
        if(modelMetricsLoading) {
            return <LinearProgress></LinearProgress>;
        }
        if(modelMetricsData.error) {
            if(["failed", "succeeded", "cancelled"].includes(resource?.backendJobStatus)) {
                return <ErrorMessage datatest={'errorMessage'} error={'Failed to load metrics'}></ErrorMessage>;
            } else {
                return <Typography datatest={'inProgressMessage'}>Training in progress</Typography>;

            }
        }
        if(toArray(modelMetricsData?.data).length < 1 ) {
            return <ErrorMessage error={'No results'}></ErrorMessage>;
        }
        let columns = modelMetricsData.data[0].map(k => {
            return {
                width : 160,
                key : k,
                name : k,
                resizable : true,
                editor : (cellEvent) => {
                    let { row, column, isCellSelected } = cellEvent;

                    return <input
                        style={{
                            appearance: "none",
                            boxSizing: "border-box",
                            inlineSize: "100%",
                            blockSize: "100%",
                            paddingBlock: 0
                        }}
                        value={row[column.key]}
                    />;
                }
            }
        });
        let rows = modelMetricsData.data.map((d , i) => {
            if(i > 0 && toArray(d).length === columns.length) {
                let obj = {};
                d.forEach((dc, dci) => {
                    obj[columns[dci].key]= Number(dc) ? Number(dc) : "";
                    obj.id = i+"-"+dci;
                })
                return obj;
            }
        }).filter(o => o);
        return <div datatest={'metricsContainer'}>
            <div style={{width : '100%', height : '300px'}}>
            <ResponsiveContainer datatest={'responsiveContainer-'} width="100%" height="100%">
                <LineChart
                    width={300}
                    height={300}
                    data={rows}
                    margin={{
                        top: 32,
                        right: 32,
                        left: 32,
                        bottom: 16,
                    }}
                >
                    <XAxis dataKey="step" />
                    <YAxis />
                    <Legend />
                    <RecTooltip/>
                    <Line type="monotone" dataKey="train_loss" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="train_accuracy" stroke="#82ca9d" activeDot={{ r: 8 }}/>
                </LineChart>
            </ResponsiveContainer>
            </div>
            <StyledDataGrid
                style={{margin:   '32px 32px 16px 80px'}}
                rowKeyGetter={(row) => row.id}
                columns={columns}
                rows={rows}
                rowHeight={24}
            />
        </div>;
    }

    renderModelUsageData = () => {
        let {modelUsageLoading} = this.state;
        if(modelUsageLoading) {
            return <LinearProgress></LinearProgress>;
        }
        return this.renderCompactView();
    }

    renderModelTest = () => {
        let {modelTestLoading, modelTestsData, selectedModelTest, selectedModelTestMessagesData, chatData, resource, modelTestsInProgress} = this.state;
        let {browseLanguage, settings, aliasesToIRIMap, aliasesMap, ontology} = this.props;
        if(modelTestLoading) {
            return <LinearProgress></LinearProgress>;
        }
        return <div datatest={'modelTestContainer'} style={{padding  :'0px 8px'}}>
            <div style={{display  :'flex', gap : '16px'}}>
                <Autocomplete
                    style={{minWidth : '200px', maxWidth : '200px'}}
                    PopperComponent={(props) => {
                        return (<Popper {...props} style={{ width: 360 }} placement='bottom-start' />);
                    }}
                    datatest={'modelTestSelect'}
                    id="textSearchPropertiesValueSelect"
                    value={selectedModelTest}
                    options={modelTestsData.filter(m => m.viewStatus !== 'deleted')}
                    getOptionLabel={option => option.title ?  option.title : ''}
                    getOptionSelected={(option, value) => {
                        return option.id === value.id;
                    }}
                    multiple={false}
                    onChange={(event, newValue, reason) => {
                        this.setState({modelTestsInProgress : [], selectedModelTest : newValue, selectedModelTestMessagesDataLoading : true}, () => {
                            const query = this.getMessageContainedInQuery(getResourceId(newValue));
                            this.searchMessagesData(query).then((dt) => {
                                let pairs = this.createMessagePairs(dt);
                                this.setState({ chatData : dt, selectedModelTestMessagesData : pairs, selectedModelTestMessagesDataLoading : false});
                            })
                        });
                    }}
                    renderInput={params => (
                        <OtherTextField
                            label={'Open'}
                            {...params}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    renderOption={(option, { selected }) => (
                        <div datatest={option.title} style={{paddingLeft : '0px'}}>{option.title}</div>
                    )}
                    size={"small"}
                    disableClearable={true}
                />
                <div style={{flexGrow  :"1"}}></div>
                {centerVertically( <Typography>{toArray(selectedModelTestMessagesData).length+ " Examples"}</Typography> )}
                {
                    toArray(selectedModelTestMessagesData).length > 0 &&  centerVertically(<Button
                        datatest={'generateAllButton'}
                        size={'small'}
                        variant={'outlined'}
                        color={'secondary'}
                        disabled={toArray(modelTestsInProgress).length > 0}
                        onClick={() => {
                            const ids = toArray(selectedModelTestMessagesData).map(md => getResourceId(md["User Message"]));
                            this.setState({modelTestsInProgress : ids}, () => {
                                toArray(selectedModelTestMessagesData).forEach(md => {
                                    let userMessage = md["User Message"];
                                    sendMessageToAI(userMessage.content, undefined, {model: resource.backendFineTunedModelId}, (userMessage, assistantMessage, error) => {
                                        if(error) {
                                            return this.handleAIError(error);
                                        }
                                        md["User Message Copy"] = userMessage;
                                        md["Assistant Message Actual"] = assistantMessage;
                                        this.setState({modelTestsInProgress : modelTestsInProgress.filter(mt => mt !== getResourceId(userMessage))});
                                    }, this.getDatasetId(), getUserMessageTemplate(settings));
                                })
                            })
                        }}
                        startIcon={<PlayArrowOutlined/>}
                    >{'Generate Message For All'}</Button>)
                }
            </div>
            <div style={{display : 'flex', gap : '16px', flexDirection  : 'column'}}>
            {
                toArray(selectedModelTestMessagesData).map((md, index) => {
                    let userMessage = md["User Message"];
                    let userMessageCopy = md["User Message Copy"];
                    let assistantMessage = md["Assistant Message"];
                    let assistantMessageActual = md["Assistant Message Actual"];
                    const userMessageId = getResourceId(userMessage);
                    let isInProgress = toArray(modelTestsInProgress).includes(userMessageId);
                    let generateButton = <Button
                        datatest={'generateButton'}
                        variant={'outlined'}
                        color={'secondary'}
                        disabled={isInProgress}
                        onClick={() => {
                            this.setState({modelTestsInProgress :[...modelTestsInProgress, userMessageId]}, () => {
                                sendMessageToAI(userMessage.content, undefined, {model: resource.backendFineTunedModelId}, (userMessage, assistantMessage, error) => {
                                    if(error) {
                                        return this.handleAIError(error);
                                    }

                                    md["User Message Copy"] = userMessage;
                                    md["Assistant Message Actual"] = assistantMessage;
                                    this.setState({modelTestsInProgress : modelTestsInProgress.filter(mt => mt !== getResourceId(userMessage))});
                                }, this.getDatasetId(), getUserMessageTemplate(settings));
                            })

                        }}
                        startIcon={isInProgress ? <CircularProgress size={24}/> : <PlayArrowOutlined/>}
                    >{assistantMessageActual ? 'Regenerate Message' :'Generate Message'}</Button>
                    return <FieldContainer datatest={'messageBlock'} key={userMessageId}>
                        <Typography>{index+1}</Typography>
                        <EditableTextField label={'User Message'} readOnly={true} value={userMessage.content}></EditableTextField>
                        <div style={{display : 'flex', gap : '16px', marginTop : '8px'}}>
                            <div datatest={'expectedContainer'} style={{width : '50%'}}>
                                <div style={{display :"flex", height : '40px'}}>
                                    {centerVertically(<H3Title>Expected</H3Title>)}
                                </div>
                                {this.renderMessage(chatData, assistantMessage, index, true, undefined, undefined, {margin: '8px', marginLeft: '8px'})}
                            </div>
                            <div datatest={'actualContainer'} style={{width : '50%'}}>
                                <div style={{display :"flex", height : '40px'}}>
                                    {centerVertically(<H3Title>Actual</H3Title>)}
                                    <div style={{flexGrow : '1'}}></div>
                                    {assistantMessageActual && centerVertically(generateButton)}
                                </div>
                                {assistantMessageActual
                                    ? isInProgress
                                        ? <LinearProgress></LinearProgress>
                                        : this.renderMessage(
                                            chatData,
                                            assistantMessageActual,
                                            index,
                                            true,
                                            undefined,
                                            undefined,
                                            {margin: '8px', marginLeft: '8px'},
                                            async (sparql) => {
                                                this.setState({runningQuery: getResourceId(assistantMessageActual)})
                                                assistantMessageActual.content = sparql;
                                                delete assistantMessageActual['error'];
                                                try {
                                                    await loadDataForQuery(userMessageCopy, assistantMessageActual);
                                                } catch (e) {
                                                    assistantMessageActual['error'] = 'Failed to get response'
                                                }
                                                this.setState({runningQuery: undefined})
                                            }
                                        )
                                    : <div style={{textAlign : "center", paddingTop : '50px'}}>{generateButton}</div>
                                }
                            </div>
                        </div>
                    </FieldContainer>
                })
            }
            </div>
        </div>;
    }

    updateResource = (resource) => {
        let {chatData} = this.state;
        updateResource(toArray(resource), chatData,
            () => {
                this.setState({
                    apiErrorResponse: undefined,
                    chatData: [...chatData]
                })
            }, (errorResponse) => {
                this.setState({apiErrorResponse: errorResponse});
            });

    }

    renderCompactView = () => {
        let {chatData, advanceEdit, resource, autoFocusId, modelUsageHasNextPage} = this.state;
        let {browseLanguage, settings, theme} = this.props;
        let treeTabValue = this.getTreeTabValue();
        const addButton = isTrainingSetsView(treeTabValue) ?
            <div>
                <Button
                    datatest={'addRowButton'}
                    startIcon={<AddOutlined/>}
                    variant={'outlined'}
                    onClick={() => {
                        let userMessage = {
                            id: getNewIdForUserMessage(uuid4()),
                            type: TYPE_USER_MESSAGE,
                            containedIn: getResourceId(resource),
                            createdOn: new Date(),
                            content: "",
                            createdBy: getUserIri()

                        };
                        const linkedToId = this.getDatasetId();
                        setLinkedToId(userMessage, linkedToId);
                        let assistantMessage = {
                            id: getNewIdForAssistantMessage(uuid4()),
                            type: TYPE_ASSISTANT_MESSAGE,
                            containedIn: getResourceId(resource),
                            createdOn: new Date(),
                            content: "",
                            userMessage: getResourceId(userMessage),
                            createdBy: getUserIri()
                        };
                        setLinkedToId(assistantMessage, linkedToId);
                        userMessage.assistantMessage = getResourceId(assistantMessage);
                        let payload = {
                            [AT_CONTEXT]: getManagementContextURL(),
                            [GRAPH]: [userMessage, assistantMessage],
                        }
                        postMessages(JSON.stringify(payload)).then(async d => {
                            if (d.status === 200) {
                                const postJSON = await d.json();
                                let graph = getGraph(postJSON);
                                graph.forEach(rs => {
                                    let found = [userMessage, assistantMessage].find(cd => cd[ID] === getResourceId(rs));
                                    found[ALIAS_SYS_ETAG] = rs[ALIAS_SYS_ETAG];
                                });
                                this.setState({
                                    autoFocusId : getResourceId(userMessage),
                                    apiErrorResponse: undefined,
                                    chatData: [...chatData, userMessage, assistantMessage]
                                }, () => {
                                    this.chatEndRef.current?.scrollIntoView({block: 'end', behavior: "smooth"})
                                })
                            } else {
                                this.setState({apiErrorResponse: d});
                            }
                        });
                    }}
                    color={'secondary'}
                >Add</Button>
            </div> : this.isModelUsageDataView()
                ? <div style={{textAlign : "center"}}>
                    <Button
                        datatest={'addRowButton'}
                        variant={'outlined'}
                        disabled={modelUsageHasNextPage === "false"}
                        onClick={async () => {
                            let {modelUsagePage} = this.state;
                            let nextPage = modelUsagePage ? modelUsagePage + 1 : 2;
                            const searchParams = {
                                [QUERY]: this.getModelUsageQuery(resource.backendFineTunedModelId),
                                [PAGE]: nextPage,
                                [PAGE_SIZE]: 50,
                                [COUNT_UPTO]: 1000,
                                [SORT_BY]:  '{ createdOn (order:"descending") }'
                            }
                            const searchResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams).catch(handleBackendError(this));
                            const {userMessages, jsonData } = await this.getUserMessages(searchResponse);
                            this.setState({modelUsagePage : nextPage, modelUsageHasNextPage : jsonData[ALIAS_SYS_HAS_NEXT_PAGE], chatData : [...chatData, ...userMessages]})
                        }}
                        color={'secondary'}
                    >Load More</Button>
                </div>
                : undefined;
        if(toArray(chatData).length < 1) {
            return <>{addButton}</>;
        }
        let rows = this.createMessagePairs(chatData);
        return <div datatest={'compactView'} style={{display : 'flex', flexDirection : "column", gap : "8px"}}>
            <FieldContainer style={{display: 'flex', gap : "8px", margin : '8px 0px'}}>
                <FieldContainer style={{width: '30%', maxWidth : '30%'}}>
                    <div style={{ display :'flex', gap : '16px', height : '100%'}}>
                        {centerVertically(<RecordVoiceOverOutlined></RecordVoiceOverOutlined>)}
                        {centerVertically(<H4Title title={"User Message"}></H4Title>)}
                    </div>
                </FieldContainer>
                <FieldContainer style={{flexGrow: '1'}}>
                    <div style={{ display :'flex', gap : '16px', height : '100%'}}>
                        {centerVertically( <AssistantOutlined/>)}
                        {centerVertically(<H4Title title={"Assistant Message"}></H4Title>, { height : '100%'})}
                    </div>
                </FieldContainer>
            </FieldContainer>
            {
                rows.map((row, index) => {
                    const userMessage = row["User Message"];
                    const assistantMessage = row["Assistant Message"];
                    const feedbackValue = getFeedbackValue(assistantMessage);
                    return <FieldContainer datatest={'compactViewRow'} key={"row-"+index+"-"+getResourceId(userMessage)} style={{display: 'flex', flexDirection : 'column', gap : "8px"}}>

                        <div style={{display : 'flex'}}>
                            <Typography>{index+1}</Typography>
                            <div style={{margin: '0px 16px'}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            datatest={'advancedQueryEditor'}
                                            size={"small"}
                                            checked={getResourceId(userMessage) === advanceEdit}
                                            value={true}
                                            onChange={() => {
                                                if(advanceEdit === getResourceId(userMessage)) {
                                                    this.setState({advanceEdit: undefined});
                                                } else {
                                                    this.setState({advanceEdit: getResourceId(userMessage)});
                                                }
                                            }}
                                            name="advancedQueryEditor"
                                        ></Switch>
                                    }
                                    label={<Typography style={{color: theme.palette.primary.main}}>Advanced Editor</Typography>}
                                />
                                {
                                    feedbackValue !== undefined
                                        ? feedbackValue === 1 ? <CustomIconButton

                                            size={'small'}
                                            datatest={'thumbsUp'}
                                            selected={true}
                                            disabled={true}
                                        >
                                            <ThumbUpOutlined/>
                                        </CustomIconButton> : <CustomIconButton
                                            size={'small'}
                                            datatest={'thumbsDown'}
                                            selected={true}
                                            disabled={true}
                                        >
                                            <ThumbDownOutlined/>
                                        </CustomIconButton> : <></>
                                }
                            </div>
                            <div style={{flexGrow : '1'}}></div>

                            {
                                centerVertically(
                                    <Tooltip title={'Delete'}>
                                        <IconButton datatest={'deleteRowButton'} size={'small'} color={"primary"} onClick={() => {
                                            this.handleUserMessageDelete(userMessage);
                                        }}><DeleteForeverOutlined></DeleteForeverOutlined></IconButton>
                                    </Tooltip>
                                )
                            }
                        </div>
                        {
                            advanceEdit === getResourceId(userMessage)
                                ? [userMessage, assistantMessage].map((m) => this.renderMessage(chatData, m, index, true, <EditableTextField
                                    readOnly={isModelsView(treeTabValue)}
                                    autoFocus={autoFocusId === getResourceId(userMessage)}
                                    value={userMessage.content}
                                    onConfirm={(label, value) => {
                                        userMessage.content = value;
                                        this.updateResource(userMessage);
                                    }}
                                    validator={() => {}}
                                    maxLength={10000}
                                    checkConfirmOnKeyDown={true}
                                    helpMessage={getUiLabelTranslation(settings, UI_LABELS_TO_SAVE_PRESS_ENTER_OR_ESC, browseLanguage, UI_LABELS_TO_SAVE_PRESS_ENTER_OR_ESC)}
                                />, isModelsView(treeTabValue) ? undefined : () => this.updateResource(assistantMessage)))
                                : <div style={{display: 'flex' , gap : "8px"}}>
                                    <div style={{width: '30%', maxWidth : '30%'}}>
                                        {centerVertically(
                                            <EditableTextField
                                                readOnly={isModelsView(treeTabValue)}
                                                autoFocus={autoFocusId === getResourceId(userMessage)}
                                                value={userMessage.content}
                                                onConfirm={(label, value) => {
                                                    userMessage.content = value;
                                                    this.updateResource(userMessage);
                                                }}
                                                validator={() => {}}
                                                maxLength={10000}
                                                checkConfirmOnKeyDown={true}
                                                helpMessage={getUiLabelTranslation(settings, UI_LABELS_TO_SAVE_PRESS_ENTER_OR_ESC, browseLanguage, UI_LABELS_TO_SAVE_PRESS_ENTER_OR_ESC)}
                                            />
                                            , { height : '100%'})}
                                    </div>
                                    <div style={{flexGrow: '1'}}>
                                        <EditableTextArea
                                            readOnly={isModelsView(treeTabValue)}
                                            fullHeight={true}
                                            value={assistantMessage.content}
                                            onConfirm={(label, value) => {
                                                assistantMessage.content = value;
                                                this.updateResource(assistantMessage);
                                            }}
                                            validator={() => {}}
                                            maxLength={10000}
                                            checkConfirmOnKeyDown={true}
                                            helpMessage={getUiLabelTranslation(settings, UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC, browseLanguage, UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC)}
                                        />
                                    </div>
                                </div>
                        }
                    </FieldContainer>;
                })
            }
            {
                addButton
            }
        </div>

    }

    createMessagePairs(chatData) {
        return toArray(chatData).filter(m => toArray(m[TYPE]).includes(TYPE_USER_MESSAGE)).map(um => {
            let found = toArray(chatData).find(cd => toArray(cd.userMessage).includes(getResourceId(um)) && toArray(cd[TYPE]).includes(TYPE_ASSISTANT_MESSAGE))
            return {
                "User Message": um,
                "Assistant Message": found
            }
        });
    }

    middleContent = () => {
        const {trainingSetViewType, chatData, loading, listening, showSaveChatDialog, resource, saving, treeData, showCreateNewDialog, showCopyToTrainingSetDialog, showCopyToTrainingSetDefaultTitle, models, datasets} = this.state;
        const {settings, theme, configurations, graphViewer, location, aliasesMap, aliasesToIRIMap, browseLanguage, ontology, onResourceItemCardFocus, } = this.props;
        let feedbackBGColor = getFeedbackBackgroundColor(settings);// '#f0f4fb';// theme.palette.grey.background;
        let feedbackBorderColor = getFeedbackBorderColor(settings);//'#e9f1fb'; //theme.palette.grey.level2;
        let treeTabValue = this.getTreeTabValue();
        let chatFieldHeight =  '72px';
        const isModelViewAndResourceDefined = isModelsView(treeTabValue) && resource;
        return <div key={getResourceId(resource)} datatest={'aiViewMiddleContent'} style={{ height : '100%', display : 'flex', flexDirection : 'column'}}>
            {
                <div style={{display: 'flex', gap : '8px', marginBottom : '8px'}}>
                    {isSuperadmin() && centerVertically(this.getViewButtonGroup())}

                    {
                        <Autocomplete
                                style={{minWidth : '200px'}}
                                PopperComponent={(props) => {
                                    return (<Popper {...props} style={{ width: 360 }} placement='bottom-start' />);
                                }}
                                datatest={'autocompleteMultiValueSelect'}
                                id="textSearchPropertiesValueSelect"
                                value={resource}
                                options={treeData.filter(m => m.viewStatus !== 'deleted')}
                                getOptionLabel={option => option.title ?  option.title : ''}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id;
                                }}
                                multiple={false}
                                onChange={(event, newValue, reason) => {
                                    this.onTreeNodeClick({...newValue, nodeId : getResourceId(newValue)});
                                    //this.setState({showSavedVisualisation: newValue}, () => this.loadVisualisation(newValue.value));
                                }}
                                renderInput={params => (
                                    <OtherTextField
                                        label={'Open'}
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                renderOption={(option, { selected }) => {
                                    const suffix = option.backendFineTunedModelId
                                        ? " (" + option.backendFineTunedModelId + ")" : "";
                                    return <div
                                        datatest={option.title}
                                        style={{paddingLeft: '0px'}}
                                    >{option.title + suffix}</div>;
                                }}
                                size={"small"}
                                disableClearable={true}
                            />

                    }
                    {
                        centerVertically(
                            <Tooltip title={'Create New'}>
                                <IconButton datatest={'createNewButton'} color={'primary'} style={{marginLeft : '4px'}} onClick={() => {
                                    this.setState({showCreateNewDialog : true})
                                }} size={'small'}><AddCircleOutlined/></IconButton>
                            </Tooltip>
                        )
                    }
                    {
                        showCreateNewDialog && (isModelsView(treeTabValue)
                            ? <TrainModelDialog
                                configurations={configurations}
                                settings={settings}
                                aliasesMap={aliasesMap}
                                ontology={ontology}
                                location={location}
                                aliasesToIRIMap={aliasesToIRIMap}
                                browseLanguage={browseLanguage}
                                models={this.getModelsForList()}
                                theme={theme}
                                datasets={datasets}
                                onTrain={(newModel) => {
                                    const datasetId = this.getDatasetId();
                                    setLinkedToId(newModel, datasetId);
                                    createModel(newModel).then(async result => {
                                        if(isRequestSuccessful(result)) {
                                            const resultJSON = await result.json();
                                            const found = getGraph(resultJSON).find(m => toArray(m[TYPE]).includes(TYPE_AI_MODEL));
                                            this.refreshModelsData(() => {
                                                this.setState({showCreateNewDialog: undefined}, () => {
                                                    const treeTabValue = this.getTreeTabValue();
                                                    const resourceId1 = getResourceId(found);
                                                    history.push(getNavigateToChatSessionLink(resourceId1, location, treeTabValue));
                                                });
                                            })
                                        } else {
                                            this.setState({apiErrorResponse : result});
                                        }
                                    })
                                }}
                                onCancel={() => {
                                    this.setState({showCreateNewDialog: false})
                                }}/>
                            : <SaveDialogWithTitle
                                titleProvider={() => {
                                    return isChatSessionsView(treeTabValue)
                                        ? 'Create New Session'
                                        : 'Create New Example Set';
                                }}
                                onSave={async (saveVisualisationTitle, action) => {
                                    let chatSession = isChatSessionsView(treeTabValue)
                                        ? this.createSession(saveVisualisationTitle)
                                        : this.createTrainingSet(saveVisualisationTitle);
                                    postMessages(JSON.stringify(chatSession)).then(d => {
                                        if(d.status === 200) {
                                            this.setState({apiErrorResponse: undefined, showCreateNewDialog : false, selectedNodeId : getResourceId(chatSession)}, () => {
                                                const treeTabValue = this.getTreeTabValue(chatSession);
                                                history.push(getNavigateToChatSessionLink(getResourceId(chatSession), location, treeTabValue));
                                            })
                                        } else {
                                            this.setState({showCreateNewDialog : false, apiErrorResponse : d});
                                        }
                                    })

                                }}
                                onCancel={() => {
                                    this.setState({showCreateNewDialog : false});
                                }}
                            /> )
                    }
                    {
                        showCopyToTrainingSetDialog && <SaveDialogWithTitle
                            defaultTitleValue={showCopyToTrainingSetDefaultTitle}
                            titleProvider={() => {
                                return 'Create New Training Set';
                            }}
                            onSave={async (saveVisualisationTitle, action) => {
                                let newTrainingSet = this.createTrainingSet(saveVisualisationTitle);
                                delete newTrainingSet[AT_CONTEXT];
                                const toPost = this.getChatDataForSave()
                                .map(c => {
                                    c.containedIn = getResourceId(newTrainingSet);
                                    return c;
                                });
                                const oldToNew = {};
                                toPost.forEach(c => {
                                    let newId;
                                    if(c[TYPE] === TYPE_ASSISTANT_MESSAGE) {
                                        newId = getNewIdForAssistantMessage(uuid4());
                                    } else if(c[TYPE] === TYPE_USER_MESSAGE) {
                                        newId = getNewIdForUserMessage(uuid4());
                                    }
                                    oldToNew[getResourceId(c)] = newId;
                                    c[ID] = newId;
                                });
                                toPost.forEach(it=> {
                                    if(it[TYPE] === TYPE_ASSISTANT_MESSAGE) {
                                        it.userMessage = oldToNew[it.userMessage];
                                    } else if(it[TYPE] === TYPE_USER_MESSAGE) {
                                        it.assistantMessage = oldToNew[it.assistantMessage]
                                    }
                                });
                                let payload = {
                                    [AT_CONTEXT] : getManagementContextURL(),
                                    [GRAPH] : [newTrainingSet, ...toPost],
                                }
                                postMessages(JSON.stringify(payload)).then(d => {
                                    if(d.status === 200) {
                                        this.setState({apiErrorResponse: undefined, showCopyToTrainingSetDialog : false, selectedNodeId : getResourceId(newTrainingSet)}, () => {
                                            history.push(getNavigateToChatSessionLink(getResourceId(newTrainingSet), location, TRAINING_SETS_VIEW));
                                        })
                                    } else {
                                        this.setState({showCopyToTrainingSetDialog : false, apiErrorResponse : d, showCopyToTrainingSetDefaultTitle : undefined});
                                    }
                                });
                            }}
                            onCancel={() => {
                                this.setState({showCopyToTrainingSetDialog : false, showCopyToTrainingSetDefaultTitle : undefined});
                            }}
                        />
                    }

                    {isModelsView(treeTabValue) || (isChatSessionsView(treeTabValue) && resource) || (isTrainingSetsView(treeTabValue) && resource) ? <></> : centerVertically(
                        <IconButton
                            datatest={'saveAllButton'}
                            color={'primary'}
                            disabled={ saving || toArray(chatData).length === 0}
                            size={'small'}
                            onClick={async () => {
                                if(resource) {
                                    this.setState({saving : true})
                                    let payload = {
                                        [AT_CONTEXT] : getManagementContextURL(),
                                        [ALIAS_SYS_POST] : [],
                                        [ALIAS_SYS_PATCH] : []
                                    }
                                    const {chatData} = this.state;
                                    const toPost = this.getChatDataForSave()
                                        .filter(c => !c[ALIAS_SYS_ETAG])
                                        .map(c => {
                                            c.containedIn = getResourceId(resource);
                                            return c;
                                        });
                                    payload[ALIAS_SYS_POST] = toPost;
                                    chatData.forEach(c => {
                                        if(c[ALIAS_SYS_ETAG]) {
                                            let newObj = {
                                                [ID]: getResourceId(c),
                                                content: c['content'],
                                                [ALIAS_SYS_ETAG]: c[ALIAS_SYS_ETAG],
                                            }
                                            const datasetId = this.getDatasetId();
                                            setLinkedToId(newObj, datasetId);
                                            payload[ALIAS_SYS_PATCH].push(newObj);
                                        }
                                    });
                                    postBatchMessages(JSON.stringify(payload)).then(async d => {
                                        if(isRequestSuccessful(d)) {
                                            const {chatData, resource} = this.state;
                                            let chatMessages = await this.getMessages(getResourceId(resource));
                                            toArray(chatMessages).forEach(it => {
                                                let found = chatData.find(cd => getResourceId(cd) === getResourceId(it));
                                                found[ALIAS_SYS_ETAG] = it[ALIAS_SYS_ETAG];
                                            })
                                            this.setState({saving : false, apiErrorResponse: undefined});
                                        } else {
                                            this.setState({saving : false, showSaveChatDialog : false, apiErrorResponse : d});
                                        }
                                    })

                                } else {
                                    this.setState({showSaveChatDialog: true});
                                }
                            }}>{saving ? <><CircularProgress style={{marginRight : '-24px'}} size={24}></CircularProgress><SaveOutlined/></>:  <SaveOutlined/>  }</IconButton>
                    )}
                    {
                        showSaveChatDialog && <SaveDialogWithTitle
                            title={''}
                            onSave={async (saveVisualisationTitle, action) => {
                                let chatSession = isChatSessionsView(treeTabValue)
                                    ? this.createSession(saveVisualisationTitle)
                                    : this.createTrainingSet(saveVisualisationTitle);
                                const {chatData, treeData} = this.state
                                let payload = {
                                    [AT_CONTEXT] : chatSession[AT_CONTEXT],
                                    [AT_GRAPH] : [
                                        chatSession
                                    ]
                                }
                                delete chatSession[AT_CONTEXT];
                                this.getChatDataForSave().forEach(c => {
                                    c.containedIn = chatSession.id;
                                    payload[AT_GRAPH].push(c);
                                });

                                postMessages(JSON.stringify(payload)).then(d => {
                                    if(d.status === 200) {
                                        this.setState({apiErrorResponse : undefined, showSaveChatDialog : false, selectedNodeId : getResourceId(chatSession), treeData : [...treeData, chatSession] }, () => {
                                            const treeTabValue = this.getTreeTabValue(chatSession);
                                            history.push(getNavigateToChatSessionLink(getResourceId(chatSession), location, treeTabValue));
                                        })
                                    } else {
                                        this.setState({showSaveChatDialog : false, apiErrorResponse : d});
                                    }
                                })


                            }}
                            onCancel={() => {
                                this.setState({showSaveChatDialog : false});
                            }}
                        />
                    }
                    <div style={{flexGrow: '1'}}></div>
                    {   centerVertically(
                        <IconButton
                            color={'primary'}
                            disabled={!resource}
                            size={'small'}
                            datatest={'deleteAll'}
                            onClick={async () => {
                                const {chatData, resource} = this.state;
                                if (isModelsView(treeTabValue)) {
                                    let payload = {
                                        [AT_CONTEXT]: getManagementContextURL(),
                                        [AT_GRAPH]: [
                                            resource
                                        ]
                                    }
                                    deleteModel(payload).then(r => {
                                        if (isRequestSuccessful(r)) {
                                            const treeTabValue = this.getTreeTabValue();
                                            this.refreshModelsData(() => {
                                                this.setState({apiErrorResponse: undefined}, () => {
                                                    history.push(getNavigateToChatSessionLink(undefined, location, treeTabValue));
                                                })
                                            });
                                        } else {
                                            this.setState({apiErrorResponse: r})
                                        }
                                    })
                                } else {
                                    let payload = {
                                        [AT_CONTEXT]: getManagementContextURL(),
                                        [AT_GRAPH]: [
                                            resource
                                        ]
                                    }
                                    this.getChatDataForSave().forEach(c => {
                                        payload[AT_GRAPH].push(c);
                                    });
                                    deleteMessages(payload).then((r) => {
                                        if (isRequestSuccessful(r)) {
                                            const treeTabValue = this.getTreeTabValue();
                                            this.setState({apiErrorResponse: undefined}, () => {
                                                history.push(getNavigateToChatSessionLink(undefined, location, treeTabValue));
                                            })
                                        } else {
                                            this.setState({apiErrorResponse: r})
                                        }
                                    })
                                }
                            }}
                        ><DeleteForeverOutlined></DeleteForeverOutlined></IconButton>
                    )
                    }
                </div>
            }
            {this.state.treeLoading  && <LinearProgress></LinearProgress>}
            {
                isModelsView(treeTabValue) && resource && <>{this.renderStatus()}</>
            }
            {
                 <>
                    {isModelViewAndResourceDefined && <div style={{marginBottom: '8px', display : 'flex', gap : '8px'}}>
                        <div>
                            <DetailsViewButton
                                datatest={'trainingDataViewButton'}
                                selected={this.isModelTrainingDataView()}
                                onClick={(ev) => {
                                    this.setState({modelSubView : TRAINING_DATA_VIEW, chatData : [], modelTrainingDataLoading : true}, async () => {
                                        const chatData = await this.getMessages(getResourceId(resource));
                                        this.setState({chatData : chatData, modelTrainingDataLoading : false});
                                    })
                                }}
                            >Training Data</DetailsViewButton>
                            <DetailsViewButton
                                datatest={'modelMetricsButton'}
                                selected={this.isModelMetricsDataView()}
                                onClick={async (ev) => {
                                    this.setState({modelSubView : MODEL_METRICS_VIEW, modelMetricsLoading : true});
                                    const response = await getModelMetricsData(getResourceId(resource));
                                    this.setState({modelMetricsData : response, modelMetricsLoading : undefined}, () => {
                                        this.chatStartRef.current?.scrollIntoView({ block: 'end',behavior: "instant" });
                                    });
                                }}
                            >Metrics</DetailsViewButton>
                            {   this.hasModelTrainingSucceeded(resource) &&
                                <>
                                    <DetailsViewButton
                                        datatest={'modelTestButton'}
                                        selected={this.isModelTestView()}
                                        onClick={async (ev) => {
                                            this.setState({
                                                modelSubView: MODEL_TEST_VIEW,
                                                modelTestLoading: true,
                                                chatData: []
                                            }, async () => {
                                                this.getTreeData(TRAINING_SETS_VIEW).then(dt => {
                                                    this.setState({modelTestsData: dt, modelTestLoading: false});
                                                })
                                            });
                                        }}
                                    >Test</DetailsViewButton>
                                    <DetailsViewButton
                                        datatest={'modelUsageButton'}
                                        selected={this.isModelUsageDataView()}
                                        onClick={async (ev) => {
                                            this.setState({
                                                modelUsagePage: 1,
                                                modelSubView: MODEL_USAGE_VIEW,
                                                modelUsageLoading: true,
                                                chatData: []
                                            }, async () => {
                                                const chatData = await this.getMessages(undefined, resource.backendFineTunedModelId);
                                                this.setState({chatData: chatData, modelUsageLoading: false});
                                            });
                                        }}
                                    >Usage</DetailsViewButton>
                                </>
                            }
                        </div>
                        {
                            this.isModelTrainingDataView() &&
                            centerVertically(
                            <Tooltip title={'Create Training Set'}>
                                <IconButton color={'primary'} size={'small'} onClick={() => {
                                    this.setState({showCopyToTrainingSetDialog : true, showCopyToTrainingSetDefaultTitle: "Copied from "+resource?.title})
                                }}><FileCopyOutlined></FileCopyOutlined></IconButton>
                            </Tooltip>
                            )
                        }
                        </div>
                    }
                    {
                        (isTrainingSetsView(treeTabValue) || (isModelsView(treeTabValue) && this.isModelTrainingDataView())) && resource && <div style={{marginBottom: '8px', display : 'flex', gap : '16px'}}>
                            <CustomIconButton
                                size={'small'}
                                datatest={'detailViewButton'}
                                onClick={() => this.setState({trainingSetViewType: TRAINING_SET_DETAIL_VIEW})}
                                selected={!this.isCompactView()}
                            >
                                <Tooltip title={'Detail View'}><DnsOutlined /></Tooltip>
                            </CustomIconButton>
                            <CustomIconButton
                                size={'small'}
                                datatest={'compactViewButton'}
                                onClick={() => this.setState({trainingSetViewType: TRAINING_SET_COMPACT_VIEW})}
                                selected={this.isCompactView()}
                            >
                                <Tooltip title={'Compact View'}><ViewListOutlined /></Tooltip>
                            </CustomIconButton>
                            <div style={{flexGrow : '1'}}></div>
                            <div>{centerVertically( <Typography>{toArray(chatData).filter(cd => toArray(cd[TYPE]).includes(TYPE_USER_MESSAGE)).length+ " Examples"}</Typography> )}</div>
                        </div>
                    }
                    <div datatest={'messagesContainer'}  style={{
                        borderRadius: '4px',
                        backgroundColor: theme.palette.white.main,
                        height: `calc(100% - ${chatFieldHeight})`,
                        overflow: 'auto',
                        border : '4px solid ',
                        borderColor : theme.palette.white.main
                    }}>
                        <div ref={this.chatStartRef}></div>
                        {
                            isModelsView(treeTabValue)
                                ? (resource === undefined && toArray(chatData.length) === 0 ? <H2Title style={{marginTop : '16px', textAlign : 'center'}} title={"Open a model to view details"}></H2Title> : <></>)
                                : (resource === undefined && toArray(chatData.length) === 0 ? <H2Title style={{marginTop : '16px', textAlign : 'center'}} title={isChatSessionsView(treeTabValue) ? "To create a session submit questions" : "To create a training set submit questions"}></H2Title> : <></>)
                        }
                        <div>
                            {
                                isModelViewAndResourceDefined && this.isModelMetricsDataView()
                                    ?  this.renderModelMetricsData()
                                    : isModelViewAndResourceDefined && this.isModelTestView()
                                        ? this.renderModelTest()
                                        : isModelViewAndResourceDefined && this.isModelUsageDataView()
                                            ? this.renderModelUsageData()
                                            : this.isCompactView() && !isChatSessionsView(treeTabValue)
                                                ? this.renderCompactView()
                                                : toArray(chatData).map((d, i) => {
                                                    return this.renderMessage(chatData, d);
                                                })
                            }
                        </div>
                        {
                            isModelsView(treeTabValue) ? <></> :
                            <div ref={this.chatEndRef}
                                 style={{height: isModelsView(treeTabValue) ? "8px" : chatFieldHeight}}/>
                        }
                    </div>
                     {
                         isModelsView(treeTabValue) ? <></>:
                         <div style={{height: chatFieldHeight, textAlign: 'center', alignSelf: 'center'}}>
                             <div style={{
                                 marginTop: '10px',
                                 maxWidth: '700px',
                                 width: '700px',
                                 display: 'flex',
                                 gap: '16px'
                             }}>
                                 {this.renderModelsListForChat()}
                                 <MuiTextField
                                     datatest={'chatMessageInput'}
                                     autoFocus={true}
                                     style={{flexGrow: 1}}
                                     key={this.message}
                                     placeholder={'Type your message/question'}
                                     variant={'outlined'}
                                     fullWidth={true}
                                     defaultValue={this.message}
                                     InputProps={{
                                         startAdornment: <div style={{display: 'flex', gap: '8px', marginRight: '8px'}}>

                                             {
                                                 window.webkitSpeechRecognition && listening === false &&
                                                 <IconButton
                                                     color={'primary'}
                                                     datatest={'speakButton'}
                                                     onClick={() => {
                                                         let recognization = new window.webkitSpeechRecognition();
                                                         recognization.onstart = () => {
                                                             this.setState({listening: true});
                                                         }
                                                         recognization.onresult = (e) => {
                                                             var transcript = e.results[0][0].transcript;
                                                             this.message = transcript;
                                                             this.setState({listening: false,});
                                                             this.sendMessage();
                                                         }
                                                         recognization.start();
                                                     }}
                                                     size={'small'}>
                                                     <KeyboardVoiceOutlined></KeyboardVoiceOutlined>
                                                 </IconButton>
                                             }

                                             {listening && centerVertically(<CircularProgress
                                                 size={24}></CircularProgress>)}
                                             {listening && centerVertically("Listening...")}
                                             {
                                                 listening && <IconButton
                                                     color={'primary'}
                                                     datatest={'speakButton'}
                                                     onClick={() => {
                                                         this.setState({listening: false});
                                                     }}
                                                     size={'small'}>
                                                     <CloseOutlined></CloseOutlined>
                                                 </IconButton>
                                             }

                                         </div>,

                                         endAdornment: <div style={{display: 'flex', gap: '8px', marginRight: '8px'}}>
                                             <IconButton
                                                 color={'primary'}
                                                 size={'small'}
                                                 onClick={this.sendMessage}
                                                 disabled={loading}
                                             >{loading ? <CircularProgress size={24}></CircularProgress> :
                                                 <ArrowUpwardOutlined/>}</IconButton>

                                         </div>
                                     }}
                                     onChange={(ev) => {
                                         const {value} = ev.target;
                                         this.message = value;
                                     }}
                                     onKeyDown={(e) => {
                                         if (e.key === 'Enter') {
                                             this.sendMessage();
                                         }
                                     }}
                                 ></MuiTextField>
                             </div>
                         </div>
                     }
                </>
            }
        </div>;
    }

    renderModelsListForChat = () => {
        let {model} = this.state;
        if(isSuperadmin()) {
            return <FormControl style={{maxWidth: '220px', width: '220px', marginTop: "8px"}}
                                       size={'small'}
                                       variant="outlined">
                <InputLabel id="model-select">Model</InputLabel>

                <Select
                    labelId="model-select"
                    datatest={"chatModelSelect"}
                    label="Model"
                    variant={'outlined'}
                    value={model}
                    onChange={(event) => {
                        this.setState({model: event.target.value});
                    }}
                >
                    <ListSubheader>Base Models</ListSubheader>
                    {getBaseAIModels().map(m => <MenuItem key={m} value={m}>{m}</MenuItem>)}
                    <ListSubheader>Fine Tuned Models</ListSubheader>
                    {this.getModelsForList().map(l => <MenuItem key={getResourceId(l)}
                                                                value={l.backendFineTunedModelId}>{l.title + " (" + l.backendFineTunedModelId + ")"}</MenuItem>)}
                </Select>
            </FormControl>;
        }
        return <></>;
    }

    getModelsForList() {
        let {models} = this.state;
        return toArray(models).filter(m => m.viewStatus !== 'deleted' && m.backendJobStatus === "succeeded");
    }

    renderMessage = (allMessagesArray, messageData, index, defaultShowQuery, userMessageToRender, onSaveQuery, messageBoxStyleOverride, onQuery) => {
        const {settings, theme, configurations, graphViewer, location, aliasesMap, aliasesToIRIMap, browseLanguage, ontology, onResourceItemCardFocus, } = this.props;
        let feedbackBGColor = getFeedbackBackgroundColor(settings);// '#f0f4fb';// theme.palette.grey.background;
        let feedbackBorderColor = getFeedbackBorderColor(settings);//'#e9f1fb'; //theme.palette.grey.level2;

        let isAssistantMessage = messageData.type === TYPE_ASSISTANT_MESSAGE;
        let content;
        let isLoadDataButton = false;
        if (isAssistantMessage) {

            if (getResourceId(messageData) === this.state.loadingAssistantMessage) {
                return <LinearProgress></LinearProgress>;
            } else if (messageData.error) {
                content = <ErrorMessage style={{color: theme.palette.error.main}}
                                        error={messageData['error']}></ErrorMessage>;
            } else {
                if (messageData[ALIAS_SYS_RESULTS]) {
                    content = <SearchResult
                        configurations={configurations}
                        graphViewProvider={graphViewer}
                        location={location}
                        searchResult={messageData}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        browseLanguage={browseLanguage}
                        ontology={ontology}
                        onItemFocus={undefined}
                        viewMode={RESULT_VIEW[0].value}
                        containerType={'div'}
                    />;
                } else if (messageData[SPARQL_SELECT_RESULTS]) {
                    content = this.renderDataGrid(messageData);
                } else {
                    isLoadDataButton = true;
                    content = <div>
                        <Button datatest={'loadDataButton'} onClick={() => {
                            let {chatData} = this.state;
                            let userMessage = chatData.find(m => m.id === toArray(messageData.userMessage)[0]);
                            loadDataForQuery(userMessage, messageData).then(() => {
                                this.setState({chatData: chatData})
                            }).catch(e => {
                                messageData['error'] = 'Failed to get response'
                            })
                        }} variant={'outlined'}>Load Data</Button>
                    </div>
                }
            }
            content = <>
                {content}
            </>
        } else {
            content = userMessageToRender || this.renderUserMessage(messageData);
        }

        let userMessage = allMessagesArray.find(cd => toArray(messageData['userMessage']).includes(getResourceId(cd)))
        const treeTabValue = this.getTreeTabValue();
        let show = isTrainingSetsView(treeTabValue) || isChatSessionsView(treeTabValue);

        return <Message
            messageBoxStyleOverride={messageBoxStyleOverride}
            defaultShowQuery={defaultShowQuery}
            settings={settings}
            browseLanguage={browseLanguage}
            message={messageData}
            content={content}
            feedbackBGColor={feedbackBGColor}
            feedbackBorderColor={feedbackBorderColor}
            runningQuery={this.state.runningQuery}
            isLoadDataButton={isLoadDataButton}
            onSaveQuery={onSaveQuery}
            onQueryRun={onQuery ? onQuery : async (sparql) => {
                this.setState({runningQuery: getResourceId(messageData)})
                messageData.content = sparql;
                delete messageData['error'];
                try {
                    await loadDataForQuery(userMessage, messageData);
                } catch (e) {
                    messageData['error'] = 'Failed to get response'
                }
                this.setState({chatData: [...allMessagesArray], runningQuery: undefined})
            }}
            actionProvider={() => {
                return show ? <>
                    <MessageCopy
                        userMessage={userMessage}
                        assistantMessage={messageData}
                        location={location}
                    />
                    {
                        isChatSessionsView(treeTabValue) && <AIFeedback assistantMessage={messageData} ></AIFeedback>
                    }
                </> :  <></> ;
            }}
        ></Message>;
    }

    renderUserMessage = (userMessage) => {
        const {trainingSetViewType, chatData, loading, listening, model, showSaveChatDialog, resource, saving, treeData, showCreateNewDialog, showCopyToTrainingSetDialog, showCopyToTrainingSetDefaultTitle} = this.state;
        const {settings, browseLanguage} = this.props;
        let treeTabValue = this.getTreeTabValue();

        return <UserMessage
            settings={settings}
            browseLanguage={browseLanguage}
            message={userMessage}
            onQueryRun={isModelsView(treeTabValue) ? undefined : async () => {
                let assistantMessage = this.findAssistantMessage(chatData, userMessage);
                delete assistantMessage[ALIAS_SYS_RESULTS];
                delete assistantMessage[SPARQL_SELECT_RESULTS];
                this.setState({loadingAssistantMessage: getResourceId(assistantMessage)})
                generateQuery(userMessage.content, undefined, undefined, undefined, model).then(async r => {
                    const sparql = r['sparql'];
                    assistantMessage.content = sparql;
                    try {
                        await loadDataForQuery(userMessage, assistantMessage);
                    } catch (e) {
                        assistantMessage['error'] = "Failed to generate response";
                    } finally {
                        this.setState({
                            loadingAssistantMessage: undefined,
                            loading: false,
                            chatData: [...this.state.chatData]
                        })
                    }
                })
            }}
            onDelete={isModelsView(treeTabValue) ? undefined : () => {
                this.handleUserMessageDelete(userMessage);
            }}
        />;
    }

    findAssistantMessage = (chatData, userMessage) => {
        return chatData.find(cd => toArray(cd['userMessage']).includes(getResourceId(userMessage)));
    }

    handleUserMessageDelete = async (userMessage) => {
        const {chatData, resource} = this.state;
        let assistantMessage = this.findAssistantMessage(chatData, userMessage);
        if (userMessage[ALIAS_SYS_ETAG]) {
            let payload = {
                [AT_CONTEXT]: getManagementContextURL(),
                [AT_GRAPH]: [
                    processForBackendAPI(assistantMessage),
                    processForBackendAPI(userMessage)
                ]
            }
            deleteMessages(payload).then((r) => {
                if (isRequestSuccessful(r)) {
                    const toDelete = [getResourceId(userMessage), getResourceId(assistantMessage)];
                    let toLeaveInState = chatData.filter(it => !toDelete.includes(getResourceId(it)));
                    this.setState({
                        chatData: toLeaveInState,
                        apiErrorResponse: undefined
                    })
                } else {
                    this.setState({apiErrorResponse: r})
                }
            })
        } else {
            const toDelete = [getResourceId(userMessage), getResourceId(assistantMessage)];
            let toLeaveInState = chatData.filter(it => !toDelete.includes(getResourceId(it)));
            this.setState({chatData: toLeaveInState})
        }
    }

    saveMessagesData = async (chatData, chatSession) => {
        const messages = chatData.map(c => {
            let newObj = {...c};
            delete newObj[ALIAS_SYS_RESULTS];
            delete newObj[SPARQL_SELECT_RESULTS];
            newObj.containedIn = chatSession.id;
            return newObj;
        });
        await storeMessages(messages);
    }

    getTreeStorageKey = () => {
        return isChatSessionsView(this.getTreeTabValue()) ? STORAGE_KEY_CHAT_SESSIONS : STORAGE_KEY_TRAINING_SETS;
    }

    getTreeData = async (treeTabValue) => {
        let {models} = this.state;
        if(isModelsView(treeTabValue)) {
            return models;
        }
        let linkedToFilter = isMultipleDatasetsInstance() ? `linkedTo(eq:["${this.getDatasetId()}"])` : "";
        const typeToSearch = isChatSessionsView(treeTabValue) ? ALIAS_MANAGEMENT_TYPE_SESSION :  ALIAS_MANAGEMENT_TYPE_TRAINING_SET;
        let query = isModelsView(treeTabValue)
            ? `{type(eq:["${ALIAS_MANAGEMENT_TYPE_AI_MODEL}"]) ${linkedToFilter} }`
            : `{type(eq:["${typeToSearch}"]) ${linkedToFilter} createdBy(eq:["${getUserIri()}"]) }`;

        const searchParams = {
            [QUERY] : query,
            [PAGE] : 1,
            [PAGE_SIZE] : 1000,
            [COUNT_UPTO] : 1000
        }
        const searchData = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams).catch(handleBackendError(this));
        if(isRequestSuccessful(searchData)) {
            const items = await searchData.json();
            return items[ALIAS_SYS_RESULTS]
        }
        return [];
    }

    getMessages = async (containerId, model) => {
        const treeTabValue = this.getTreeTabValue();
        if(isModelsView(treeTabValue) && this.isModelTrainingDataView()) {
                let {resource} = this.state;
                let result = await getModelTrainingData(containerId);
                if (isRequestSuccessful(result)) {
                    const jsonData = await result.json();
                    let graphData = toArray(jsonData[GRAPH] || jsonData[AT_GRAPH]);
                    return graphData;
                }
                return []
        } else {
            let query = isModelsView(treeTabValue) && this.isModelUsageDataView()
                ? this.getModelUsageQuery(model)
                : this.getMessageContainedInQuery(containerId);

            let pageSize = isModelsView(treeTabValue) && this.isModelUsageDataView() ? 50 : undefined ;
            //let query = `{type(eq:["${TYPE_USER_MESSAGE}" "${TYPE_ASSISTANT_MESSAGE}"]) }`;
            return await this.searchMessagesData(query, 1, pageSize);
        }
    }

    getModelUsageQuery = (model) => {
        return `{type(eq:["${TYPE_USER_MESSAGE}" "${TYPE_ASSISTANT_MESSAGE}"]) model(eq:["${model}"])}`;
    }

    getMessageContainedInQuery = (containerId) => {
        return `{type(eq:["${TYPE_USER_MESSAGE}" "${TYPE_ASSISTANT_MESSAGE}"]) containedIn(eq:["${containerId}"])}`;
    }

    getUserMessages = async (searchResponse) => {
        if (isRequestSuccessful(searchResponse)) {
            const jsonData = await searchResponse.json();
            let toReturn = [];
            let processed = jsonData[ALIAS_SYS_RESULTS].map(it => {
                if (it.content) {
                    let content = toArray(it.content)[0];
                    it.content = content;
                }
                return it;
            })
            processed.forEach(p => {
                if (toArray(p[TYPE]).includes(TYPE_USER_MESSAGE)) {
                    toReturn.push(p);
                    let am = processed.find(it => toArray(it.userMessage).includes(getResourceId(p)));
                    toReturn.push(am);
                }
            })

            return {
                userMessages : toReturn,
                jsonData : jsonData
            };
        }
        return {userMessages : [], jsonData : undefined};
   }

    searchMessagesData = async (query, page, pageSize, orderBy) => {
        const searchParams = {
            [QUERY]: query,
            [PAGE]: page !== undefined ? page : 1,
            [PAGE_SIZE]: pageSize !== undefined ? pageSize: 1000,
            [COUNT_UPTO]: 1000,
            [SORT_BY]: orderBy ? orderBy : this.isModelUsageDataView() ? '{ createdOn (order:"descending") }' : '{ createdOn (order:"ascending") }'
        }
        const searchResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams).catch(handleBackendError(this));
        const processedData = await this.getUserMessages(searchResponse);
        return processedData.userMessages;
    }

    getViewButtonGroup = () => {
        let {theme} = this.props;
        let {resource, treeTab} = this.state;
        let treeTabValue = this.getTreeTabValue(resource);
        return <div style={{minWidth : '380px', paddingRight : '16px' , paddingTop: '8px', paddingBottom : '4px'}}>
            <DetailsViewButton
                datatest={'sessionsTab'}
                selected={isChatSessionsView(treeTabValue)}
                onClick={(ev) => this.handleTreeViewChange(ev, CHAT_SESSIONS_VIEW)}
                startIcon={<QuestionAnswerOutlined/>}
            >Sessions</DetailsViewButton>
            <DetailsViewButton
                datatest={'exampleSetsTab'}
                selected={isTrainingSetsView(treeTabValue)}
                onClick={(ev) => this.handleTreeViewChange(ev, TRAINING_SETS_VIEW)}
                startIcon={<StorageOutlined/>}
            >Example Sets</DetailsViewButton>
            <DetailsViewButton
                datatest={'modelsTab'}
                selected={isModelsView(treeTabValue)}
                onClick={(ev) => this.handleTreeViewChange(ev, MODELS_VIEW)}
                startIcon={<ComputerOutlined/>}
            >Models</DetailsViewButton>
        </div>;
    }

    getTopLevelTreeData = async (treeTab) => {
        return await this.getTreeData(treeTab);
    }

    handleTreeViewChange = (event, treeTab) => {
        let {location} = this.props;
        this.setState({treeLoading: true, treeData: [], treeTab : treeTab}, async () => {
            history.push(getNavigateToChatSessionLink(undefined, location, treeTab));
        });
    }


    getTreeTabValue = () => {
        let {location} = this.props;
        let params = queryString.parse(location.search);
        let treeTabFromParam = params['treeTab'];
        return treeTabFromParam || CHAT_SESSIONS_VIEW;
    }

    onTreeNodeToggle = async (node) => {
        let {expandedNodeIds} = this.state;
        if(expandedNodeIds.includes(node.nodeId)) {
            const filteredNodes = expandedNodeIds.filter(nid => nid !== node.nodeId);
            this.setState({expandedNodeIds : [...filteredNodes]})
        } else {
            this.setState({expandedNodeIds : [...expandedNodeIds, node.nodeId]})
        }
    }


    onTreeNodeClick = async (node) => {
        let {location} = this.props;
        this.setState({selectedNodeId : node.nodeId, treeLoading : true, modelSubView : undefined}, () => {
            history.push(getNavigateToChatSessionLink(getResourceId(node), location, this.getTreeTabValue(node)));
        })
    }

    createSession = (saveVisualisationTitle) => {

        const uuid = uuid4();
        let chatSession = {
            [AT_CONTEXT]: getManagementContextURL(),
            id: getNewIdForSession(uuid),
            createdDate: new Date(),
            title: saveVisualisationTitle,
            type: 'Session',
            createdBy: getUserIri()
        }
        this.linkToDataset(chatSession)
        return chatSession;
    }

    linkToDataset = (resource) => {
        const datasetId = this.getDatasetId();
        if(datasetId) {
            setLinkedToId(resource, datasetId);
        }
    }

    getDatasetId = () => {
        if(isMultipleDatasetsInstance()) {
            let {datasets} = this.state;
            let datasetLabel = getDatasetLabel();
            let found = toArray(datasets).find(dt => dt[ALIAS_MANAGEMENT_ID_LABEL] === datasetLabel);
            if (found) {
                let linkedToId = found[ID];
                return linkedToId;
            }
        }
    }

    createTrainingSet = (saveVisualisationTitle) => {
        const uuid = uuid4();
        let chatSession = {
            [AT_CONTEXT]: getManagementContextURL(),
            id: getNewIdForTrainingSet(uuid),
            createdDate: new Date(),
            title: saveVisualisationTitle,
            type: 'TrainingSet',
            createdBy: getUserIri()
        }
        this.linkToDataset(chatSession)
        return chatSession;
    }

    render() {
        let {headerProvider, theme} = this.props;
        const {resource, location, browseLanguage, aliasesMap, settings, ontology, aliasesToIRIMap} = this.props;
        const {showCreateNewDialog, apiErrorResponse} = this.state;
        const treeTabValue = this.getTreeTabValue();

        return <React.Fragment key={''}>
            <AllPartsLayout
                apiError={apiErrorResponse !== undefined}
                apiErrorResponse={apiErrorResponse}
                onApiErrorClose={() => {
                    this.setState({apiErrorResponse : undefined})
                }}
                resizeable={true}
                header={headerProvider()}
                fullScreenStyle={{
                }}
                leftMinSize={ undefined}
                resizeStoreKey={'ai'}
                leftComponentScroll={isSuperadmin() && {y: 'hidden', x: 'hidden'}}
                leftComponentStyle={isSuperadmin() &&  {paddingTop: '0px'}}
                leftStyle={isSuperadmin() &&  {paddingTop: '8px',height : `calc(100% - ${0}px)`}}
                leftComponentContainerStyle={isSuperadmin() &&  {paddingRight: '0px'}}
                middleStyle={{paddingTop: '8px',height : `calc(100% - ${16}px)`, maxWidth : 'calc(100% - 48px)'}}
                middleComponentStyle={{margin : ( "0px 16px"),height : `calc(100%)` }}
                leftMainHeader={undefined}
                leftComponent={undefined}
                middleActions={undefined}
                middleComponent={this.middleContent()}
                resizeable={false}
            />
        </React.Fragment>;
    }

}

AIInternal.propTypes = {
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    viewProperties: PropTypes.any,
    graphViewProvider:PropTypes.func,
    headerProvider: PropTypes.func
};

export const AI = withStyles(styles, {withTheme:true})(withRouter(AIInternal));

AI.propTypes = {
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    viewProperties: PropTypes.any,
    graphViewProvider:PropTypes.func,
    headerProvider: PropTypes.func
};
