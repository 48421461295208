import React from "react";
import MermaidWrapper, {generateMermaidResourceId, getPropertyValue, normalizeStyle} from "./MermaidWrapper";
import {getLocalName} from "../../../components/util";


const subgraphId = 'subgraphId';
const subgraphLabel = 'subgraphLabel';
const subgraphLabelStyle = 'subgraphLabelStyle';
const subgraphContainerStyle = 'subgraphContainerStyle';
const subgraphDirection = 'subgraphDirection';
const nodeId = 'nodeId';
const nodeContent = 'nodeContent';
const nodeStyle = 'nodeStyle';
const linkTargetNodeId = 'linkTargetNodeId';
const linkContent = 'linkContent';
const linkStyle = 'linkStyle';

export const FLOWCHART_DIAGRAM_SETTINGS = [
    {label : "Subgraph Id", stateKey : subgraphId},
    {label : "Subgraph Direction", stateKey : subgraphDirection},
    {label : "Subgraph Label", stateKey : subgraphLabel},
    {label : "Subgraph Label Style", stateKey : subgraphLabelStyle},
    {label : "Subgraph Container Style", stateKey : subgraphContainerStyle},
    {label : "Node Id", stateKey : nodeId},
    {label : "Node Content", stateKey : nodeContent},
    {label : "Node Style", stateKey : nodeStyle},
    {label : "Link Target Node Id", stateKey : linkTargetNodeId},
    {label : "Link Content", stateKey : linkContent},
    {label : "Link Style", stateKey : linkStyle},
];


export default class FlowchartGraphDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData  : ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {})
    }

    createObject = () => {
        return {
            properties: [],
            relations : []
        }
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let themeObject = {
            ["theme"] : `${diagramSettings?.['theme']}`,
            ["themeCSS"] : []
        }
        let graphData = {
            nodes : {},
            subgraphs : {}
        };
        let edgesTo = new Set();
        let themeCSS = new Set();
        for(let i=0;i< bindings.length;i++){
            let bd = bindings[i];
            let nodeIdVal = await getPropertyValue(diagramSettings, nodeId, bd);
            let subgraphIdVal = await getPropertyValue(diagramSettings, subgraphId, bd);
            if(subgraphIdVal) {
                let subgraph = graphData.subgraphs[subgraphIdVal];
                if(!subgraph) {
                    subgraph = {
                        nodes : {
                            [nodeIdVal] : {
                                relations : []
                            }
                        }
                    }
                    graphData.subgraphs[subgraphIdVal] = subgraph;
                }
                if(!subgraph.nodes[nodeIdVal]) {
                    subgraph.nodes[nodeIdVal] = {
                        relations : []
                    }
                }
                let entityKeys = [subgraphLabel, subgraphLabelStyle, subgraphDirection, subgraphContainerStyle];
                for(let i=0;i<entityKeys.length;i++) {
                    let key = entityKeys[i];
                    if(!graphData.subgraphs[subgraphIdVal][key]) {
                        graphData.subgraphs[subgraphIdVal][key] = await getPropertyValue(diagramSettings, key, bd);
                    }
                }
            }

            let nodeKeys = [nodeContent, nodeStyle];
            let nodeObject = subgraphIdVal
                ? graphData.subgraphs[subgraphIdVal].nodes[nodeIdVal]
                : graphData.nodes[nodeIdVal];
            if(!nodeObject) {
                nodeObject = {
                    relations : []
                };
                if(nodeIdVal && !graphData.nodes[nodeIdVal]) {
                    graphData.nodes[nodeIdVal] = nodeObject;
                }
            }
            for(let i=0;i<nodeKeys.length;i++) {
                let key = nodeKeys[i];
                if(!nodeObject[key]) {
                    nodeObject[key] = await getPropertyValue(diagramSettings, key, bd);
                }
            }
            let linkTargetNodeIdVal = await getPropertyValue(diagramSettings, linkTargetNodeId, bd)
            let linkContentVal = await getPropertyValue(diagramSettings, linkContent, bd)
            if(linkTargetNodeIdVal) {
                let linkKeys = [linkStyle];
                for (let i = 0; i < linkKeys.length; i++) {
                    let key = linkKeys[i];
                    let foundObject = nodeObject.relations.find(p => {
                        let found = p[linkTargetNodeId] === linkTargetNodeIdVal
                            && linkContentVal && p[linkContent] === linkContentVal;
                        return found ? true : false;
                    });
                    if (!foundObject) {
                        foundObject = {
                            [linkTargetNodeId]: linkTargetNodeIdVal,
                            [linkContent]: linkContentVal
                        };
                        nodeObject.relations.push(foundObject)
                    }
                    if (!foundObject[key]) {
                        foundObject[key] = await getPropertyValue(diagramSettings,key, bd);
                    }
                }
                edgesTo.add(linkTargetNodeIdVal);
            }
        }
        edgesTo.forEach(e => {
            let found = Object.keys(graphData.nodes).includes(e);
            if(found) {
                return;
            }
            found = Object.keys(graphData.subgraphs).map(sk => {
                return graphData.subgraphs[sk].nodes[e];
            }).filter(n => n).find(n => n);
            if(!found) {
                const classObject = this.createObject();
                graphData.nodes[e] = {
                    nodeContent : getLocalName(e, false)
                };
            }
        })
        const direction = diagramSettings?.direction || "";
        let mermaidData = '\n'+`flowchart ${direction}`;
        let mermaidIdsToOriginalId = {};
        let links = {};
        Object.keys(graphData.subgraphs).forEach(sk => {
            const subgraphIdForMermaid = generateMermaidResourceId(sk, mermaidIdsToOriginalId);
            const subgraphObject = graphData.subgraphs[sk];
            mermaidData = mermaidData+"\n"+`subgraph ${subgraphIdForMermaid}["${subgraphObject[subgraphLabel]}"]`;
            if(subgraphObject[subgraphDirection]) {
                mermaidData = mermaidData+"\n"+`direction ${subgraphObject[subgraphDirection]}`;
            }
            if(subgraphObject[subgraphContainerStyle]) {
                themeCSS.add(`g#${subgraphIdForMermaid}.cluster rect ${normalizeStyle(subgraphObject[subgraphContainerStyle])}`);
            }
            if(subgraphObject[subgraphLabelStyle]) {
                themeCSS.add(`g#${subgraphIdForMermaid}.cluster .cluster-label .nodeLabel  ${normalizeStyle(subgraphObject[subgraphLabelStyle])}`);
            }
            Object.keys(subgraphObject.nodes).forEach(nk => {
                const nodeObject = subgraphObject.nodes[nk];
                mermaidData = mermaidData + this.processNodeObject(nk, mermaidIdsToOriginalId, nodeObject, links);
            });
            mermaidData = mermaidData+"\n"+`end`;
        })
        Object.keys(graphData.nodes).forEach(nk => {
            const nodeObject = graphData.nodes[nk];
            mermaidData = mermaidData + this.processNodeObject(nk, mermaidIdsToOriginalId, nodeObject, links);
        });
        Object.keys(links).forEach((lkv, index) => {
            mermaidData = mermaidData + "\n" + lkv;
            const linkStyle = links[lkv];
            if(linkStyle) {
                mermaidData = mermaidData + "\n" + `linkStyle ${index} ${linkStyle}`;
            }
        })

        themeObject.themeCSS =  [...themeCSS];
        let themeSetting = `%%{init: ${JSON.stringify(themeObject, null, 2)}}%%`;
        mermaidData =  themeSetting+"\n"+mermaidData;
        console.log("mermaidData", mermaidData)
        return mermaidData;

        //this.setState({mermaidData})

    }

    processNodeObject = (nk, mermaidIdsToOriginalId, nodeObject, links) => {
        let mermaidData = "";
        const nodeIdForMermaid = generateMermaidResourceId(nk, mermaidIdsToOriginalId)
        let nodeContentVal = nodeObject[nodeContent] || "";
        mermaidData = mermaidData + "\n" + `  ${nodeIdForMermaid}${nodeContentVal}`;
        nodeObject.relations.forEach(rl => {
            const linkValue = nodeIdForMermaid + rl[linkContent] + generateMermaidResourceId(rl[linkTargetNodeId], mermaidIdsToOriginalId);
            if (!links[linkValue]) {
                links[linkValue] = rl[linkStyle];
            }
        })
        return mermaidData;
    }

    render() {
        const {mermaidData} = this.state;
        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}}
            >{mermaidData}</MermaidWrapper>}
        </>;
    }
}
