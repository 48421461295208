import React, {useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withEvent} from "./Event";
import {withPermissions} from "../../service/permission-service";
import PropTypes from "prop-types";
import {
    centerVertically,
    getResourceId,
    getUiLabelTranslationFromContext, isArrayOnly,
    isBlankNodeId,
    isEmptyArray,
    isObjectOnly,
    isOntology,
    sort,
    toArray
} from "../../components/util";
import {
    ALIAS_SH_CLASS,
    AT_ID,
    AT_TYPE,
    ID,
    RDFS_IS_DEFINED_BY,
    TYPE,
    TYPE_OWL_CLASS,
    TYPE_OWL_DATATYPE_PROPERTY,
    TYPE_OWL_OBJECT_PROPERTY,
    TYPE_OWL_ONTOLOGY,
    TYPE_RDF_LIST,
    TYPE_RDFS_CLASS,
    TYPE_SKOS_CONCEPT,
    TYPE_SKOS_CONCEPT_SCHEME,
    TYPE_SKOS_XL_LABEL
} from "../../Constants";
import {isString} from "lodash";
import {getResourceUrl, getValuesObject} from "./SearchResultItem";
import {UI_LABELS_NO_LABEL_AVAILABLE} from "./UILabel";
import {loadResource} from "../../service/data-loader";
import {renderSimpleObject} from "./ObjectNode";
import queryString from "query-string";
import {IRI_SKOS_IN_SCHEME} from "./TaxonomyView";
import {getObjectLink} from "./WithObjectSummary";
import H4Title from "../../components/H4Title";
import {NavigationRounded} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {adjustForObo, getOntologyIRIs, TYPE_OWL_ANNOTATION_PROPERTY} from "../../service/sparql-queries";
import history from "../../history";
import {getNavigateToConceptLink, getNavigateToOntologyLink} from "./LeftTreeComponents";
import FeedbackWidget from "./FeedbackWidget";
import AddFeedbackDialog from "./AddFeedbackDialog";
import cloneDeep from "lodash/cloneDeep";
import {isFeedbackEnabled} from "./BasicSetup";


function ObjectLink({keyId, resourceId, resource, valueObject, theme, onClick, settings, location, aliasesToIRIMap, aliasesMap, ontology, browseLanguage, configurations, propertyKey, context}) {
    const [focus, setFocus] = useState(false);
    const [addFeedback, setAddFeedback] = useState(false);

    let computedStyle = {
        position: 'relative',
        minHeight : '32px',
        display: 'flex',
        padding: '8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.grey.level2,
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        borderRightWidth: '1px',
        borderLeftWidth: '3px',
        borderRadius: '4px',
        backgroundColor : theme.palette.white.main,
    }

    const handleFocusOff = () => {
        if(focus === true) {
            setFocus(false);
        }
    }

    const handleFocusOn = () => {
        if(focus === false) {
            setFocus(true);
        }
    }

    return <React.Fragment>{
        <div key={keyId+focus} style={{width: '100%', marginBottom: '8px'}} datatest={'container-' + resourceId}>
            <div
                datatest={'focusContainer-' + (resourceId || '')}
                style={computedStyle}
                onFocus={handleFocusOn}
                onBlur={handleFocusOff}
                onMouseEnter={handleFocusOn}
                onMouseLeave={handleFocusOff}
                tabIndex={0}
            >
                {
                    centerVertically(
                        <IconButton datatest={'resourceButton'} onClick={() => onClick(resource)} size={'small'}
                                    color={'secondary'}>
                            <NavigationRounded style={{transform: 'rotate(45deg)'}}/>
                        </IconButton>, {marginRight: '8px'})
                }
                {
                    renderSimpleObject(valueObject, undefined, theme, {marginBottom: '0px'}, true)
                }
                <div style={{flexGrow :'1'}}></div>
                {
                    focus && isFeedbackEnabled(settings) ? centerVertically(<FeedbackWidget
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                console.log(context);
                                setAddFeedback(true)
                            }}
                            settings={settings}
                            location={location}
                            aliasesToIRIMap={aliasesToIRIMap}
                            ontology={ontology}
                            aliasesMap={aliasesMap}
                            browseLanguage={browseLanguage}
                            configurations={configurations}
                        />, {marginLeft: '8px'}
                    ) : <div style={{minHeight : '30px'}}></div>
                }

            </div>
            {
            addFeedback && <AddFeedbackDialog
                location={location}
                context={ context}
                parentObject={resource}
                shapeProperty={context.shapeProperty}
                nodeValue={{}}
                ontology={ontology}
                browseLanguage={browseLanguage}
                aliasesToIRIMap={aliasesToIRIMap}
                aliasesMap={aliasesMap}
                settings={settings}
                onClose={() => {
                    setFocus(false);
                    setAddFeedback(false);
                }}
                onSaveSuccess={() => {
                    setAddFeedback(false);
                }}
            />
        }

        </div>
    }</React.Fragment> ;

}

class ObjectLinks extends React.Component {
    constructor(props) {
        super(props);
        let {resource, propertyKey, aliasesToIRIMap} = props;
        let value = resource[propertyKey];
        let normalisedValue = toArray(value).map(v => v?.[ID] || v);
        if([TYPE, AT_TYPE].includes(propertyKey)) {
            normalisedValue = toArray(normalisedValue).map(v => aliasesToIRIMap[v] || v);
        }

        this.state = {
            resourceIds : normalisedValue,
            resourcesMap: {},
            taxonomyMap: {},
            ontologyMap: {},
            resourceOntologyMap: {},
            valueObjectsMap: {},
            resourceErrorMap: {},

        }
    }

    componentDidMount() {
        let {resourceIds} = this.state;
        this.loadResources(resourceIds);
    }

    loadResources = (resourceIds, batchSize = 100) => {
        let {resourceErrorMap} = this.state;

        let resourceIdStringArray = toArray(resourceIds)
            .map(id => isObjectOnly(id) ? id[ID] : id)
            .filter(id => isString(id));

        let resourceIdsArray = resourceIdStringArray.filter(id => isBlankNodeId(id) === false);
        let resourceBNodeIdsArray = resourceIdStringArray.filter(id => isBlankNodeId(id) === true);
        let {resourcesMap, valueObjectsMap} = this.state;
        resourceBNodeIdsArray.forEach(id => {
            let bNodeObject = toArray(resourceIds).find(idObj => idObj[ID] === id);
            valueObjectsMap[id] = { [ID] : id};
            resourcesMap[id] = bNodeObject;
        })
        this.setState({});

        for (let i = 0,j = resourceIdsArray.length; i < j; i += batchSize) {
            let batch = resourceIdsArray.slice(i, i + batchSize);
            loadResource(batch).then( (results) => {
                let allValues = results.map(async (resource, index) => {
                    //We pick resource id from batch as the resource may not load in which case resource will be undefined
                    let resourceId = batch[index];
                    await this.loadResource(resource, resourceId);
                });
                Promise.all(allValues).then(v => {
                    this.setState({});
                });
            }).catch((r) => {
                batch.forEach(id => {
                    console.log('Resource load failed', r);
                    resourceErrorMap[id] = 'Resource load failed. Status '+r;
                });
                this.setState({});
            })
        }

    }

    loadResource = async (resource, resourceId) => {
        let {settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let {valueObjectsMap, resourceErrorMap, resourcesMap, taxonomyMap, ontologyMap, resourceOntologyMap} = this.state;

        if (resource) {
            let valuesObject = await getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology);
            valuesObject[TYPE] = resource[TYPE];
            valuesObject.backingObject = resource;
            valueObjectsMap[resourceId] = valuesObject;

            resourcesMap[resourceId] = resource;
            const inSchemeIRIs = this.getInSchemeIRIs(resource);
            if(inSchemeIRIs.length > 0) {
                let results = await loadResource(inSchemeIRIs);
                results.filter(r => r).forEach(r => {
                    taxonomyMap[r[ID]] = r;
                });
            }
            const isDefinedByIRIs = this.getIsDefinedByIRIs(resource);
            if(isDefinedByIRIs.length > 0) {
                let results = await loadResource(isDefinedByIRIs);
                results.filter(r => r).forEach(r => {
                    if(isOntology(r)) {
                        const ontologyId = getResourceId(r);
                        ontologyMap[ontologyId] = r;
                        resourceOntologyMap[resourceId] = ontologyId;
                    }
                });
            }
            let typesIRIs = toArray(resource[TYPE]).map(t => aliasesToIRIMap[t] || t);
            let ontologyResourceTypes = [TYPE_OWL_ONTOLOGY, TYPE_OWL_CLASS, TYPE_RDFS_CLASS, TYPE_OWL_OBJECT_PROPERTY, TYPE_OWL_DATATYPE_PROPERTY, TYPE_OWL_ANNOTATION_PROPERTY];
            let isOntologyResource = typesIRIs.find(t => ontologyResourceTypes.includes(t));
            let isDefinedByResources = isDefinedByIRIs.map(isd => ontologyMap[isd]);
            if(isOntologyResource && isDefinedByResources.length < 1) {
                let ontologyIds = await getOntologyIRIs();
                let isInOntology = ontologyIds.find(oid => getResourceId(resource).startsWith(adjustForObo(oid)));
                if(ontologyIds.length > 0 && isInOntology ) {
                    let results = await loadResource(isInOntology);
                    results.filter(r => r).forEach(r => {
                        if(isOntology(r)) {
                            const ontologyId = getResourceId(r);
                            ontologyMap[ontologyId] = r;
                            resourceOntologyMap[resourceId] = ontologyId;
                        }
                    });
                }
            }

        } else {
            delete valueObjectsMap[resourceId];
            delete resourcesMap[resourceId];
            resourceErrorMap[resourceId] = getUiLabelTranslationFromContext(this, UI_LABELS_NO_LABEL_AVAILABLE);
        }
    }

    getIRIs = (resource, typesToCheckIRI, propertyIRI) => {
        if(resource) {
            let {aliasesToIRIMap} = this.props;
            let typeIRIs = toArray(resource[TYPE]).map(t => aliasesToIRIMap[t] || t).map(t => t[ID] || t);
            let fountType = toArray(typesToCheckIRI).find(t => typeIRIs.includes(t));
            if (fountType) {
                const inSchemeKey = Object.keys(resource).find(k => (aliasesToIRIMap[k] || k) === propertyIRI);
                if (inSchemeKey) {
                    let conceptSchemes = resource[inSchemeKey];
                    let ids = toArray(conceptSchemes).map(cs => getResourceId(cs) || cs);
                    return ids;
                }
            }
        }
        return []
    }

    getInSchemeIRIs = (resource) => {
        return this.getIRIs(resource, [TYPE_SKOS_CONCEPT, TYPE_SKOS_XL_LABEL], IRI_SKOS_IN_SCHEME)
    }

    getIsDefinedByIRIs = (resource) => {
        let typesToCheckIRI = [TYPE_OWL_CLASS, TYPE_RDFS_CLASS, TYPE_OWL_OBJECT_PROPERTY, TYPE_OWL_DATATYPE_PROPERTY, TYPE_OWL_ANNOTATION_PROPERTY]
        return this.getIRIs(resource, typesToCheckIRI, RDFS_IS_DEFINED_BY)
    }


    onClick = (resource) => {
        let {onConceptClick, aliasesToIRIMap} = this.props;
        let {location} = this.props;
        let {taxonomyMap, resourceOntologyMap} = this.state;
        const resourceId = getResourceId(resource);
        if(!onConceptClick) {
            let typeIRIs = toArray(resource[TYPE]).map(t => aliasesToIRIMap[t] || t);
            if(typeIRIs.includes(TYPE_SKOS_CONCEPT_SCHEME)) {
                return history.push(getNavigateToConceptLink(resourceId, undefined, location));
            } else if (typeIRIs.includes(TYPE_OWL_ONTOLOGY)) {
                return history.push(getNavigateToOntologyLink(resourceId, undefined, location));
            }
            return history.push(getResourceUrl(location, resourceId));
        }
        let params = queryString.parse(location.search);
        let conceptSchemeId = params['conceptSchemeId'];
        let ontologyId = params['ontologyId'];
        let inSchemeIRIs = this.getInSchemeIRIs(resource);
        if(inSchemeIRIs.includes(conceptSchemeId)) {
           return onConceptClick(resource, {navigateInTaxonomy : conceptSchemeId});
        } else if (resourceId === conceptSchemeId) {
            return onConceptClick(resource, {navigateInTaxonomy : conceptSchemeId});
        } else {
            let cs = inSchemeIRIs.find(is => taxonomyMap[is]);
            if(cs) {
                return onConceptClick(resource, {navigateInOtherTaxonomy : cs});
            } else {
                const resourceOntologyId = resourceOntologyMap[resourceId];
                if (toArray(resourceOntologyId).includes(ontologyId)) {
                    return onConceptClick(resource, {navigateInOntology : resourceOntologyId});
                } else if (resourceId === ontologyId) {
                    return onConceptClick(resource, {navigateInOntology : resourceId});
                } else {
                    if(resourceOntologyId) {
                        return onConceptClick(resource, {navigateInOtherOntology : resourceOntologyId});
                    }
                    return onConceptClick(resource, {navigateToResource : getResourceId(resource)});
                }
            }
        }
    }

    renderResource = (resourceId, index) => {
        let { theme, location, configurations, settings, aliasesToIRIMap, aliasesMap, ontology, browseLanguage, propertyKey, context, customizations} = this.props;
        let {valueObjectsMap, resourceErrorMap, resourcesMap} = this.state;
        let valueObject = valueObjectsMap[resourceId];
        let error = resourceErrorMap[resourceId];
        let resource = resourcesMap[resourceId];

        const key = resourceId+"-"+index;

        if(error || !valueObject) {
            if(isObjectOnly(resourceId) || isArrayOnly(resourceId)) {
                return <div key={key} style={{display: 'flex', overflow: 'auto'}}>
                    {centerVertically(<H4Title noWrap={true} title={JSON.stringify(resourceId)}></H4Title>)}
                </div>;
            } else {
                return <div key={key} style={{display: 'flex', overflow: 'auto'}}>
                    {centerVertically(getObjectLink(resourceId, true, theme, {margin: '0px 8px 0px 0px'}), {paddingTop: '4px'})}
                    {centerVertically(<H4Title noWrap={true} title={resourceId}></H4Title>)}
                </div>;
            }
        }
        let contextClone = cloneDeep(context);
        contextClone.dataValue = resourceId;
        contextClone[TYPE] =  AT_ID;

        return <ObjectLink
            key={key}
            resourceId={resourceId}
            resource={resource}
            valueObject={valueObject}
            theme={theme}
            keyId={key}
            onClick={this.onClick}
            settings={settings}
            location={location}
            browseLanguage={browseLanguage}
            ontology={ontology}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            propertyKey={propertyKey}
            customizations={customizations}
            context={contextClone}
            configurations={configurations}
        /> ;
    }


    render() {
        let { propertyKey, resource, location, theme, nodeValue,onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage, shapeProperty} = this.props;

        let {valueObjectsMap, resourceErrorMap, resourceIds} = this.state;

        let resourceIdsArray = isEmptyArray(Object.keys(valueObjectsMap))
            ? toArray(resourceIds)
            : shapeProperty?.[ALIAS_SH_CLASS] === TYPE_RDF_LIST ? Object.keys(valueObjectsMap)  :  sort(Object.keys(valueObjectsMap).map(k => valueObjectsMap[k])).map(v => v[ID]);
        if(resourceErrorMap) {
            Object.keys(resourceErrorMap).filter(id => resourceIdsArray.includes(id) === false).map(k => resourceIdsArray.push(k));
        }

        if(isEmptyArray(resourceIdsArray)) {
            return <></>;
        }

        return resourceIdsArray.map((r, index) => {
            return this.renderResource(r, index);
        })

    }
}

ObjectLinks.propTypes = {
    configurations: PropTypes.any,
    resource: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    propertyKey: PropTypes.any,
    shapeProperty: PropTypes.any,
    context: PropTypes.any,
    customizations: PropTypes.any,
    onConceptClick: PropTypes.func,
}

export default withEvent(withStyles(styles, {withTheme: true})(withPermissions(ObjectLinks)));
