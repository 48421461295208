import React from "react";
import MermaidWrapper, {getPropertyValue} from "./MermaidWrapper";

const sectionTitle = 'sectionTitle';
const task = 'task';
const taskId = 'taskId';
const taskTag = 'taskTag';
const taskStart = 'taskStart';
const taskEnd = 'taskEnd';

export const GANTT_DIAGRAM_SETTINGS = [
    {label : "Section Title", stateKey : sectionTitle},
    {label : "Task", stateKey : task},
    {label : "Task Id", stateKey : taskId},
    {label : "Task Tag", stateKey : taskTag},
    {label : "Task Start", stateKey : taskStart},
    {label : "Task End", stateKey : taskEnd},
];

export const DEFAULT_INPUT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_OUTPUT_DATE_FORMAT = '%Y-%m-%d';

export default class Gantt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData: ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {
        })
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let themeSetting = diagramSettings['theme'] ?  `
%%{
  init: {
    'theme': '${diagramSettings['theme']}'
  }
}%%
` : '';

        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }

        let usedIdsMap = {};
        let mermaidData = themeSetting+'\ngantt';
        if(diagramSettings['inputDateFormat']) {
            mermaidData += '\ndateFormat '+diagramSettings['inputDateFormat'];
        } else {
            mermaidData += '\ndateFormat ' + DEFAULT_INPUT_DATE_FORMAT;
        }
        if(diagramSettings['outputDateFormat']) {
            mermaidData += '\naxisFormat '+diagramSettings['outputDateFormat'];
        } else {
            mermaidData += '\naxisFormat ' + DEFAULT_OUTPUT_DATE_FORMAT;
        }
        let mermaidDataCollector = [];
        this.process(usedIdsMap, mermaidDataCollector, resolvedValues);
        mermaidData = mermaidData + "" + mermaidDataCollector.join("");
        console.log("mermaidData", mermaidData)
        return mermaidData;
    }

    process = (usedIdsMap, mermaidDataCollector, resolvedValues) => {
        let data = [];
        resolvedValues.forEach(rv => {
            const sectionTitleVal = rv[sectionTitle];
            const found = data.find(dt => dt[sectionTitle] === sectionTitleVal);
            if(!found) {
                data.push({
                    [sectionTitle] : sectionTitleVal,
                    tasks : {}
                })
            }
        });
        data.forEach(s => {
            const sectionSub = resolvedValues.filter(dt => dt[sectionTitle] === s[sectionTitle]);
            sectionSub.forEach(ss => {
                let taskValue = ss[task];
                let taskObject = s.tasks[taskValue];
                if(!taskObject) {
                    s.tasks[taskValue] = {};
                    taskObject = s.tasks[taskValue];
                }
                [ taskTag, taskId, taskStart, taskEnd].forEach(tk => {
                    taskObject[tk] = ss[tk];
                })
            })
        })
        data.forEach(s => {
            if(s[sectionTitle]) {
                mermaidDataCollector.push(`\nsection ${s[sectionTitle]}`);
            }
            Object.keys(s.tasks).forEach(tk => {
                let taskObject = s.tasks[tk];
                let taskMetadata = "";
                [taskTag, taskId, taskStart, taskEnd].forEach((tk, index) => {
                    taskMetadata +=  (index >0 ? ", " : ": ")+taskObject[tk];
                })

                mermaidDataCollector.push(`\n ${tk} ${taskMetadata} `);
            })
        })
    }

    render() {
        const {mermaidData} = this.state;

        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}}
            >{mermaidData}</MermaidWrapper>}
        </>;
    }

}