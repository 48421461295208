import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {
    ALIAS_MANAGEMENT_TITLE,
    AT_DEFAULT,
    EXAMPLE_TYPE_TO_COLOR,
    ID,
    ROUTE_APPS_EXPLORER_SITE,
    TYPE
} from "../../Constants";
import {
    centerVertically,
    getBrowseLanguageCode,
    GetEditableClassNames,
    getMultilingualValue,
    getRouteWithInstanceAndDataset,
    getShapesData,
    getUiLabelTranslationFromContext,
    toArray
} from "../../components/util";
import {convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
import {getPagesForWorkspace, HAS_PARTS, isExampleRequest, loadPageWorkspace, PART_DATA} from "./PagesSetup";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {getTitlePrefixComponent} from "../apiplayground/Examples";
import H3Title from "../../components/H3Title";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {showRequest} from "../apiplayground/ScenarioDetails";
import {getPlayButton} from "../apiplayground/CollectionDetails";
import {theme} from "../../theme";
import SettingsIcon from "@material-ui/icons/Settings";
import {Grid, IconButton as MUIIconButton} from "@material-ui/core";
import {EXAMPLE_TYPE_TO_TITLE_KEY, isSameForAllLanguages} from "./UILabel";
import GraphView, {graphViewLogo} from "./GraphView";
import uuid4 from "uuid/v4";
import {
    getContainerHeight,
    getContainerWidth,
    isContentType,
    isDataVisualisation,
    isGridType,
    isLinksToPagesBlock,
    isSpreadsheet,
    isVisualisation,
    PART_CONTAINER_STYLE_KEY,
    QUERY_TYPE_DATA_QUERY,
    WIDTH_TYPE_CARDS_BASED
} from "./PageEditor";
import DataGridView from "./DataGridView";
import history from "../../history";
import DataVisualisationView from "./DataVisualisationView";
import ResultCard, {CARD_WIDTH} from "./ResultCard";
import {
    cardFocusActions,
    getPartId,
    getSiteHomePath,
    renderRequestResult,
    SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
    SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE
} from "./Workspace";
import {getDefaultLanguage} from "./WorkspaceSettingsDialog";
import {updateSearchRequest} from "../apiplayground/SearchRequest";
import {graphSearchByMaps} from "../../service/graph-api";
import {primeFromSearchResult} from "../../service/data-loader";
import {WithAdminEdit} from "./WithAdminEdit";

function requestTitleRenderer(theme) {
    return function(running, disableRunButton, onRun, urlComponent, onSettings) {
        return <div style={{display: 'flex'}}>
            <div>{getPlayButton(running, disableRunButton, theme, onRun)}</div>
            <div style={{flexGrow : '1'}}>
                <div style={{display : 'flex'}}>
                    <div style={{flexGrow : '1'}}></div>
                    <MUIIconButton datatest={'settingsButton'} size={'small'} onClick={onSettings}><SettingsIcon/></MUIIconButton>
                </div>
                {urlComponent}
            </div>
        </div>
    }
}

export function getPartContainerStyle(part, containerStyle={}) {
    let contentStyleOverride = part['containerStyle'];
    try {
        let contentStyleParsed = JSON.parse(contentStyleOverride);
        Object.keys(contentStyleParsed).forEach(k => {
            containerStyle[k] = contentStyleParsed[k];
        })
    } catch (e) {

    }
    return containerStyle;
}

export function renderContentForView(browseLanguage, settings, part, index, pageWorkspace, pageWorkspaceSettings, customizations) {
    let browseLanguageCode = getBrowseLanguageCode(browseLanguage);
    let data = part[PART_DATA];
    let contentState = settings.isMultilingual
        ? data[browseLanguageCode]
        : data[AT_DEFAULT];
    if(isSameForAllLanguages(data)) {
        contentState = data[AT_DEFAULT];
    }
    if (contentState) {
        try {
            const rawContentState = convertToRaw(contentState.getCurrentContent());
            const markup = draftToHtml(rawContentState);

            return <div
                datatest={'content-'+index}
                class={'content '+GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
                style={getPartContainerStyle(part, {margin: '0px'})}
            >
                <WithAdminEdit
                    data={{
                        action: SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                        id : getPartId(index),
                        index: index,
                        part:part,
                        pageWorkspace, pageWorkspaceSettings, customizations
                }}
                />
                {parse(markup)}
            </div>
        } catch (e) {
            return <div style={{margin: '0px'}}>Failed to render content</div>;
        }

    } else {
        return <div></div>;
    }
}

export function renderLinkToPageBlock(part, pageObjects, settings, location, index, pageWorkspace, pageWorkspaceSettings, customizations) {
    if (!part[PART_DATA]) {
        part[PART_DATA] = []
    }
    let selectedPageObjects = part[PART_DATA].map(v => {
        const found = toArray(pageObjects).find(pd => pd[ID] === v[ID]);
        return found;
    }).filter(o => o);
    return <div datatest={'pageBlock-'+index}  style={getPartContainerStyle(part,{height : part['containerHeight'] ? part['containerHeight'] : undefined, marginTop : '8px'})} className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}>
        <WithAdminEdit
            style={{marginTop : '-16px'}}
            data={{
                action:SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                id : getPartId(index),
                index : index,
                part : part,
                pageWorkspace: pageWorkspace,
                pageWorkspaceSettings: pageWorkspaceSettings,
                customizations
            }}/>
        <Grid style={{width : part['containerWidth']}} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={2}>
                    {
                        selectedPageObjects.map((pageObject, index) => {
                            let title = getMultilingualValue(pageObject[ALIAS_MANAGEMENT_TITLE], getDefaultLanguage(settings))
                            return <Grid key={title + index} item>
                                <ResultCard customizations={{disableQuickEdit : true}} variant={'button'} title={title}
                                            url={`${getSiteHomePath(location)}/page/${title}`}/>
                            </Grid>;
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    </div>;
}

export function renderGrid(part, partIndex, partsRenderer, pageWorkspace, pageWorkspaceSettings, customizations) {
    if (!part[PART_DATA]) {
        part[PART_DATA] = []
    }
    const containerHeight = getContainerHeight(part);
    const isCardsRelative = part['widthType'] === WIDTH_TYPE_CARDS_BASED;
    const isPercentage = toArray(part[PART_DATA]).find((p) => !isCardsRelative && p['containerWidth']?.includes('%') )
    const partsArray = toArray(part[PART_DATA]).filter(p => p['disable'] !== true);
    return <div datatest={'grid-'+partIndex} style={getPartContainerStyle(part)}
                                                          className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(partIndex), undefined, customizations)}
    >
        <WithAdminEdit
            style={{marginTop : '-24px'}}
            data={{
                action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                id : getPartId(partIndex),
                part:part,
                index:partIndex,
                pageWorkspace, pageWorkspaceSettings, customizations
            }} />
        {
            isPercentage ?
                    <div style={{display : 'flex', gap : '8px'}}>
                        {
                            partsArray.map((p, index) => {
                                const itemStyle =  {minWidth: index !== 0 ?  `calc(${p['containerWidth']} - 8px)` : p['containerWidth']};
                                p['containerHeight'] = containerHeight;
                                return <div key={index} style={itemStyle}>{partsRenderer(p, index, {disableQuickEdit: true})}</div>
                            })

                        }
                    </div>
                :
            <Grid container spacing={2}>
                {
                    partsArray.map((p, index) => {

                        const cardSpan = Number(p['columnSpan'] || 1);
                        const cardSpanWidth = cardSpan * CARD_WIDTH + ((cardSpan - 1) * 24) + 'px';
                        const itemStyle = isCardsRelative
                            ? {minWidth: cardSpanWidth, width: cardSpanWidth}
                            : {minWidth: p['containerWidth']};
                        return <Grid key={index} xs={isCardsRelative ? undefined : 6} item
                                     style={itemStyle}>{partsRenderer(p, index, {disableQuickEdit: true})}</Grid>
                    })
                }
            </Grid>
        }
    </div>;
}

export function getPageParts(partsProvider, pageWorkspaceSettings) {
    if (partsProvider) {
        return partsProvider(pageWorkspaceSettings);
    }
    if (pageWorkspaceSettings) {
        return toArray(pageWorkspaceSettings[HAS_PARTS]);
    }
    return [];
}

class PageView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedIds : []
        }
    }

    async componentDidMount() {
        let {pageWorkspaceId, partsProvider, settings} = this.props;
        if(partsProvider) {
            let defaultLanguage = this.getDefaultLanguageFromSettings();
            await this.syncHomePageQueries(settings, defaultLanguage);
            getPagesForWorkspace(this.props.workspace).then(r => {
                this.setState({pageObjects : r});
            });
        } else {
            await this.loadWorkspaceForPage(pageWorkspaceId);
        }
    }

    getDefaultLanguageFromSettings = () => {
        return toArray(this.props.settings?.browseLanguages).find(bl => bl.isDefault === true);
    }

    loadWorkspaceForPage = async (pageWorkspaceId) => {
        let pageWorkspaceAndSettings = await loadPageWorkspace(pageWorkspaceId);
        if(pageWorkspaceAndSettings) {
            let {pageWorkspace, pageWorkspaceSettings} = pageWorkspaceAndSettings;
            this.setState({pageWorkspace, pageWorkspaceSettings});
            let defaultLanguage = this.getDefaultLanguageFromSettings();
            this.syncHomePageQueries(pageWorkspaceSettings, defaultLanguage)
        }
        getPagesForWorkspace(this.props.workspace).then(r => {
            this.setState({pageObjects : r});
        });
    }

    syncHomePageQueries = (pageWorkspaceSettings, defaultLanguage) => {
        let {partsProvider} = this.props;
        this.getPageParts(pageWorkspaceSettings).filter(q => isExampleRequest(q) && q['disable'] !== true).map((part, index) => {
            let searchRequest = partsProvider ? part : part[PART_DATA];
            if (searchRequest?.otherOptions) {
                searchRequest.otherOptions.lang = defaultLanguage;
            }
            updateSearchRequest(searchRequest, searchRequest.search, searchRequest.facet, searchRequest.mixin, searchRequest.sort, searchRequest.otherOptions);
            let {paramMap, headerMap} = searchRequest;
            this.searchAndCache(paramMap, headerMap).then(o => {
                searchRequest.searchResult = o.json;
                this.setState({});
            }).catch(response => {
                this.setState({apiError: true, apiErrorResponse: response});
            })
        })
    }

    searchAndCache = async (paramMap, headerMap, latestRequestToken) => {
        let response = await graphSearchByMaps(paramMap, headerMap);
        try {
            let json = await response.json();
            primeFromSearchResult(json);
            return {
                json,
                response,
                latestRequestToken
            };
        } catch (e) {
            return Promise.reject(response);
        }
    }


    toggleExpanded = (ev, expanded, id) => {
        let {expandedIds} = this.state
        if (expanded === true) {
            expandedIds.push(id)
            this.setState({expandedIds})
        } else {
            this.setState({expandedIds: expandedIds.filter(i => i !== id)})
        }
    }

    renderSearchRequest = (part, index, customizations) => {
        let {onChange, browseLanguage, aliasesMap, location, isPreview, partsProvider} = this.props;
        let {expandedIds, pageWorkspace, pageWorkspaceSettings} = this.state;
        let searchRequest = partsProvider ? part : part[PART_DATA];
        let id = searchRequest[ID];
        let expanded = toArray(expandedIds).includes(searchRequest[ID]);
        let summaryComponentStyle = {padding: '8px', borderRight: '1px solid #EEEEEE'}
        let prefixLabel = getUiLabelTranslationFromContext(this, EXAMPLE_TYPE_TO_TITLE_KEY[part[TYPE]]);
        return <div
            className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
            style={getPartContainerStyle(part, { padding : ''})}
            datatest={'panel-' + index} key={searchRequest[ID]}>
            <WithAdminEdit
                style={{marginTop : '-24px'}}
                data={{
                action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                id : getPartId(index),
                index, part, pageWorkspace, pageWorkspaceSettings, customizations
            }}/>
            {
                <div>
                    <Accordion
                        expanded={expanded}
                        onChange={(ev, expanded) => this.toggleExpanded(ev, expanded, id)}

                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon datatest={'expandButton'}/>}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id={"additional-actions1-header" + id}
                            IconButtonProps={{
                                size: 'small'
                            }}
                            style={{
                                borderBottom: expanded ? '1px solid #EEEEEE' : 'none',
                                minHeight: 'unset',
                                padding: '0px 8px 0px 8px',
                                margin: '0px'
                            }}
                        >
                            {centerVertically(getTitlePrefixComponent(EXAMPLE_TYPE_TO_COLOR[part[TYPE]], prefixLabel), {
                                textAlign: 'center',
                                width: '48px', ...summaryComponentStyle,
                                paddingTop: '10px'
                            })}
                            <div style={{paddingLeft: '8px'}}/>
                            {
                                centerVertically(
                                    <div>
                                        <H3Title datatest={'requestTitle'} color={'primary'}>
                                            {getMultilingualValue(searchRequest.title, browseLanguage)}
                                        </H3Title>
                                    </div>
                                )
                            }
                        </AccordionSummary>
                        <AccordionDetails style={{padding: '0px'}}>
                            <div datatest={'requestDetails'} style={{
                                width: 'calc(100% - 16px)',
                                padding: '8px',
                            }}>{
                                showRequest(
                                    searchRequest, searchRequest[ID],
                                    undefined,
                                    aliasesMap,
                                    undefined,
                                    () => {
                                    },
                                    onChange,
                                    {
                                        hideSettings: false,
                                        hideRun: false,
                                        hideSave: true,
                                        hideNameInDetails: true,
                                        hideDescriptionInDetails: true,
                                        titleRenderer : requestTitleRenderer(theme),
                                        hideVariablesBuilder : true
                                    }
                                )
                            }</div>
                        </AccordionDetails>
                    </Accordion>
                </div>

            }

        </div>;
    }

    renderVisualisationType = (part, index, customizations) => {
        let {theme, workspace, onExpand, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, location} = this.props;
        let {pageWorkspace, pageWorkspaceSettings} = this.state;
        if(!part[PART_DATA]) {
            part[PART_DATA] = {}
        }
        let selectedVisualisation = {value : part[PART_DATA] , backingObject : {[ID] : part[PART_DATA]}};

        const mainStyle = {marginBottom: '16px', position:'relative', height: getContainerHeight(part), padding : '16px', borderRadius : '4px', backgroundColor : theme.palette.white.main};
        return <div
            className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
            datatest={'visualisation-'+index}
            style={getPartContainerStyle(part, mainStyle)}>
            <WithAdminEdit
                style={{marginTop : '-40px'}}
                data={{
                    action :SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                    index, id : getPartId(index), part, pageWorkspace, pageWorkspaceSettings, customizations
                }}
            />
                    <GraphView
                        embedded={true}
                        workspace={workspace}
                        settings={settings}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        languageCode={getBrowseLanguageCode(browseLanguage)}
                        configurations={configurations}
                        ontology={ontology}
                        location={location}
                        onExpand={onExpand}
                        data={[]}
                        visualisation={selectedVisualisation}
                        minimized={true}
                        onClose={() => {
                            this.setState({graphViewId : uuid4(), openVisualisation : undefined, openGraphViewerFor : undefined});
                        }}
                        open={true}
                        hideButton={true}
                    />
                </div>;

    }

    renderLinkToPageBlockType = (part, index, customizations) => {
        let {settings, location} = this.props;

        let {pageObjects, pageWorkspace, pageWorkspaceSettings} = this.state;
        if(!pageObjects) {
            return <></>;
        }
        return renderLinkToPageBlock(part, pageObjects, settings, location, index, pageWorkspace, pageWorkspaceSettings, customizations);

    }


    renderDataVisualisationType = (part, index, customizations) => {
        let {theme, workspace, onExpand, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, location} = this.props;
        let {pageWorkspace, pageWorkspaceSettings} = this.state;
        if(!part[PART_DATA]) {
            part[PART_DATA] = {}
        }
        let selectedVisualisation = {value : part[PART_DATA] , backingObject : {[ID] : part[PART_DATA]}};

        const mainStyle = {padding : '16px', position:'relative', height: getContainerHeight(part), borderRadius : '4px', backgroundColor : theme.palette.white.main};
        return <>
            <div
                className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
                datatest={'dataVisualisation-'+index}
                style={getPartContainerStyle(part, mainStyle)}
            >
                <WithAdminEdit
                    style={{marginTop : '-32px'}}
                    data={{
                    action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                    id : getPartId(index),
                    index, part, pageWorkspace, pageWorkspaceSettings, customizations
                }}/>
                <DataVisualisationView
                    embedded={true}
                    workspace={workspace}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={getBrowseLanguageCode(browseLanguage)}
                    configurations={configurations}
                    ontology={ontology}
                    location={location}
                    visualisation={selectedVisualisation}
                    open={true}
                    hideButton={true}
                />
            </div>
        </>;

    }

    renderSpreadsheetType = (part, index, customizations) => {
        let {theme, workspace, onExpand, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, location} = this.props;
        let {pageWorkspace, pageWorkspaceSettings} = this.state;

        if(!part[PART_DATA]) {
            part[PART_DATA] = {}
        }
        let selected = {value : part[PART_DATA] , backingObject : {[ID] : part[PART_DATA]}};

        const mainStyle = {
            height: getContainerHeight(part),
            padding : '16px',
            borderRadius : '4px',
            backgroundColor : theme.palette.white.main,
        };
        return <>
            <div
                datatest={'spreadsheet-'+index}
                style={getPartContainerStyle(part, mainStyle)}
                className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
            >
                <WithAdminEdit
                    style={{marginTop : '-32px'}}
                    data={{
                    action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                    id : getPartId(index),
                    index, part, pageWorkspace, pageWorkspaceSettings, customizations
                }} />
                <DataGridView
                    workspace={workspace}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={getBrowseLanguageCode(browseLanguage)}
                    configurations={configurations}
                    ontology={ontology}
                    shapes={getShapesData(configurations)}
                    location={location}
                    onExpand={onExpand}
                    minimized={true}
                    onClose={() => {
                        history.push( getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`));
                    }}
                    embedded={true}
                    spreadsheet={selected}

                />
            </div>
        </>;

    }

    onGraphViewerClose = () => {
        this.setState({openGraphViewerFor : undefined})
    }

    graphViewer = (resourceId) => {
        let {theme, location, onExpand} = this.props;
        let { workspace, settings, browseLanguage, aliasesMap, aliasesToIRIMap, configurations, ontology} = this.props;
        return settings ? <div><GraphView
            headerProvider={
                () => graphViewLogo(theme, settings, this.onGraphViewerClose)
            }
            workspace={workspace}
            settings={settings}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            languageCode={getBrowseLanguageCode(browseLanguage)}
            configurations={configurations}
            ontology={ontology}
            location={location}
            onExpand={onExpand}
            data={toArray(resourceId)}
            minimized={true}
            onClose={this.onGraphViewerClose}
            open={true}
            hideButton={ true}
        /></div> : <></>;
    }

    onResourceItemCardFocus = (resource) => {
        let {settings, location, aliasesMap} = this.props;
        const onClick = (ev) => {
            this.setState({openGraphViewerFor : resource[ID]})
            ev.preventDefault();
            ev.stopPropagation();
        };

        return cardFocusActions(resource, onClick, settings, aliasesMap, location);
    }

    getPageParts = (pageWorkspaceSettings) => {
        let {partsProvider} = this.props;
        return getPageParts(partsProvider, pageWorkspaceSettings);
    }

    render() {
        let {pageWorkspaceSettings, openGraphViewerFor, pageWorkspace} = this.state;

        const partsContainerStyle = {
            margin : 'auto',
            maxWidth : pageWorkspaceSettings?.['pageWidth'],
            display : "flex",
            flexDirection : "column",
            gap : pageWorkspaceSettings?.['componentGap'] || "16px"
        };

        return <div
            datatest={'pageView'}
            style={{minHeight : '40px'}}
            className={GetEditableClassNames(SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE, undefined, undefined, undefined)}
        >
            <WithAdminEdit style={{marginTop : '-16px'}} data={{
                action: SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE,
                pageWorkspace, pageWorkspaceSettings,

            }}/>
            <div datatest={'mainContentContainer'} style={partsContainerStyle}>
            {openGraphViewerFor && this.graphViewer(openGraphViewerFor)}
            {
                this.getPageParts(pageWorkspaceSettings).map((part, index) => {
                    return <React.Fragment key={index}>{this.renderPart(part, index)}</React.Fragment> ;
                })
            }
            </div>
        </div>;

    }

    renderPart = (part, index, customizations) => {
        let {pageWorkspaceSettings, openGraphViewerFor, pageWorkspace} = this.state;
        let {browseLanguage, partsProvider, settings, isPreview, theme, location, aliasesMap, aliasesToIRIMap, ontology} = this.props;
        if (part['disable'] === true) {
            return <></>;
        }
        if (isContentType(part)) {
            return renderContentForView(browseLanguage, settings, part, index, pageWorkspace, pageWorkspaceSettings, customizations);
        } else if (isExampleRequest(part)) {
            if (part['queryType'] === QUERY_TYPE_DATA_QUERY || partsProvider) {
                let actions = isPreview ? () => {
                } : this.onResourceItemCardFocus;
                const partObject = partsProvider ? part : part[PART_DATA];
                //Copy other settings in case those are used later
                Object.keys(part).filter(k => [PART_CONTAINER_STYLE_KEY].includes(k)).forEach(k => {
                    partObject[k] = part[k];
                })
                return renderRequestResult(partObject, index, browseLanguage, theme, location, settings, aliasesMap, aliasesToIRIMap, ontology, actions, pageWorkspace, pageWorkspaceSettings, customizations);
            }
            return this.renderSearchRequest(part, index, customizations)
        } else if (isVisualisation(part)) {
            return this.renderVisualisationType(part, index, customizations);
        } else if (isSpreadsheet(part)) {
            return this.renderSpreadsheetType(part, index, customizations);
        } else if (isDataVisualisation(part)) {
            return this.renderDataVisualisationType(part, index, customizations);
        } else if (isLinksToPagesBlock(part, index)) {
            return this.renderLinkToPageBlockType(part, index, customizations);
        } else if (isGridType(part)) {
            return renderGrid(part, index, this.renderPart, pageWorkspace, pageWorkspaceSettings, customizations)
        }
    }
}

PageView.propTypes = {
    pageWorkspaceId: PropTypes.string,
    settings: PropTypes.any,
    browseLanguage: PropTypes.any,
    aliasesMap: PropTypes.any,
    location: PropTypes.any,
    workspace: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    onExpand: PropTypes.func,
    isPreview:  PropTypes.any,
    partsProvider:  PropTypes.func,
};

export default withStyles({}, {withTheme: true})(PageView);
