import React from "react";
import MermaidWrapper, {generateMermaidResourceIdForStateDiagram, getPropertyValue} from "./MermaidWrapper";

const parentStateId = 'parentStateId';
const stateId = 'stateId';
const stateLabel = 'stateLabel';
const stateType = 'stateType';
const stateNote = 'stateNote';
const stateStyle = 'stateStyle';
const stateNotePosition = 'stateNotePosition';
const concurrencyGroupId = 'concurrencyGroupId';
const transitionToStateId = 'transitionToStateId';
const transitionLabel = 'transitionLabel';

export const STATE_DIAGRAM_SETTINGS = [
    {label : "Parent State Id", stateKey : parentStateId},
    {label : "State Id", stateKey : stateId},
    {label : "State Label", stateKey : stateLabel},
    {label : "State Type", stateKey : stateType},
    {label : "State Note", stateKey : stateNote},
    {label : "State Style", stateKey : stateStyle},
    {label : "State Note Position", stateKey : stateNotePosition},
    {label : "Concurrency Group Id", stateKey : concurrencyGroupId},
    {label : "Transition To State id", stateKey : transitionToStateId},
    {label : "Transition Label", stateKey : transitionLabel},
];

export default class StateDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData: ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {
        })
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let resolvedValues = [];
        for(let i=0;i< bindings.length;i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for(let i=0;i<keys.length;i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }
        //Now create node value object for all node ids
        let usedIdsMap = {};

        let nodes = {};
        resolvedValues.forEach(rv => {
            const stateIdValue = rv[stateId];
            const parentStateIdValue = rv[parentStateId];
            let nodeId = this.getNodeIdFromStateId(stateIdValue, parentStateIdValue);
            if(!nodes[nodeId]) {
                nodes[nodeId] = {
                    mermaidId : generateMermaidResourceIdForStateDiagram(stateIdValue, usedIdsMap),
                    relations : {}
                }
            }
            if(!nodes[parentStateIdValue]){
                nodes[parentStateIdValue] = {
                    mermaidId : generateMermaidResourceIdForStateDiagram(parentStateIdValue, usedIdsMap),
                    relations : {}
                }
            }
            [stateLabel, stateType, stateNote, stateStyle,stateNotePosition, concurrencyGroupId, parentStateId].forEach(vk => {
                const rvValue = rv[vk];
                if(rvValue) {
                    nodes[nodeId][vk] = rvValue;
                }
            });
            let transitionToStateIdVal = rv[transitionToStateId];
            let transitionLabelVal = rv[transitionLabel];
            if(transitionToStateIdVal) {
                nodes[nodeId].relations[transitionToStateIdVal] = transitionLabelVal;
                //While here also add linked node object
                if(!nodes[transitionToStateIdVal]) {
                    nodes[transitionToStateIdVal] = {
                        mermaidId : generateMermaidResourceIdForStateDiagram(transitionToStateIdVal, usedIdsMap),
                        relations : {}
                    }
                }
            }
        });
        let mermaidData = 'stateDiagram-v2\n';
        const direction = diagramSettings?.direction;
        if(direction) {
            mermaidData = mermaidData +"\n"+`direction ${direction}`
        }
        let mermaidDataCollector = [];
        this.process("", resolvedValues, usedIdsMap, mermaidDataCollector, nodes);
        //Adding Styles in composite nodes does not work so add all styles here
        Object.keys(nodes).forEach((nk, index) => {
            let nodeObject = nodes[nk];
            const mermaidResourceId = generateMermaidResourceIdForStateDiagram(nk, usedIdsMap);
            const stateStyleVal = nodeObject[stateStyle];
            if(stateStyleVal) {
                const styleClass = 'style'+index+mermaidResourceId;
                mermaidDataCollector.push("\n"+ `classDef ${styleClass} ${stateStyleVal}`);
                mermaidDataCollector.push("\n"+ `class ${mermaidResourceId} ${styleClass}`);
            }
        })
        mermaidData = mermaidData + "\n"+ mermaidDataCollector.join("");
        console.log("mermaidData", mermaidData)
        return mermaidData;


    }

    getNodeIdFromStateId(stateIdValue, parentStateIdValue) {
        return stateIdValue.trim() === "[*]"
            ? parentStateIdValue + "[*]" : stateIdValue;
    }

    findTopStates = (parentId, resolvedValues) => {
        let rowsToProcess = resolvedValues.filter(rv => {
            if(parentId) {
                return rv[parentStateId] === parentId
            } else {
                return !rv[parentStateId];
            }
        })
        let topNodesArray = rowsToProcess.filter(rp => {
            const stateIdVal = rp[stateId];
            let found = rowsToProcess.find(rp => {
                if(rp[transitionToStateId] === "[*]" && stateIdVal === "[*]") {
                    return false;
                }
                return rp[transitionToStateId] === stateIdVal
            });
            return found ? false : true;
        }).map(tn => tn[stateId]);
        topNodesArray = [...new Set(topNodesArray)];
        return topNodesArray;
    }

    process = (parentId, resolvedValues, usedIds, mermaidDataCollector, nodes) => {
        let topNodesArray = this.findTopStates(parentId, resolvedValues);
        topNodesArray.forEach(id => {
            const nodeObject = nodes[id];
            const relations = nodeObject.relations;
            const relationsKeys = relations && Object.keys(relations);
            const mermaidResourceId = generateMermaidResourceIdForStateDiagram(id, usedIds);
            this.addStateInfo(mermaidResourceId, nodeObject, mermaidDataCollector);
            //Start node cannot be styled, so we do not add that
            if(relationsKeys) {
                relationsKeys.forEach(rTo => {
                    this.addRelation(mermaidResourceId, rTo, nodes, nodeObject, usedIds, mermaidDataCollector);
                })
            }
        });
        //Keeping below in separate loop as it renders better
        topNodesArray.forEach(id => {
            const nodeObject = nodes[id];
            const relations = nodeObject.relations;
            if(relations) {
                Object.keys(relations).forEach(transitionToId => {
                    this.processTransitionTo(transitionToId, resolvedValues, usedIds, mermaidDataCollector, nodes)
                })
            }
        });

    }

    addStateInfo = (mermaidResourceId, nodeObject, mermaidDataCollector) => {
        if(nodeObject.visited !== true) {
            const stateTypeValue = nodeObject[stateType];
            const stateLabelValue = nodeObject[stateLabel];
            if (stateTypeValue) {
                mermaidDataCollector.push("\n" + `state ${mermaidResourceId} ${stateTypeValue}`);
            } else {
                if (stateLabelValue) {
                    mermaidDataCollector.push("\n" + `state "${stateLabelValue}" as ${mermaidResourceId}`);
                }
            }
            const relations = nodeObject.relations;
            const relationsKeys = relations && Object.keys(relations);
            if (!stateTypeValue && !stateLabelValue) {
                mermaidDataCollector.push(`\n${mermaidResourceId}`);
            }
            const stateNoteVal = nodeObject[stateNote];
            const stateNotePositionVal = nodeObject[stateNotePosition];
            if (stateNoteVal) {
                mermaidDataCollector.push("\n" + `note ${stateNotePositionVal || 'left of'} ${mermaidResourceId} :  ${stateNoteVal}`)
            }
        }
        nodeObject.visited = true;
    }

    processTransitionTo = (transitionToStateIdVal, resolvedValues, usedIds, mermaidDataCollector, nodes) => {
        if(transitionToStateIdVal.trim() === "[*]") {
            //Do nothing
        } else {
            let isTransitionToComposite = resolvedValues.find(rp => rp[parentStateId] === transitionToStateIdVal);
            if (isTransitionToComposite) {
                mermaidDataCollector.push(`\nstate ${nodes[transitionToStateIdVal].mermaidId} {`)
                let topUniqStateIds = this.findTopStates(transitionToStateIdVal, resolvedValues);
                let topUniqStateIdsByConcurrencyGroup = {};
                topUniqStateIds.forEach(stateIdValue => {
                    let concurrencyGroupIdVal = resolvedValues.find(rp => rp[stateId] === stateIdValue && rp[concurrencyGroupId])?.[concurrencyGroupId];
                    if(topUniqStateIdsByConcurrencyGroup[concurrencyGroupIdVal]) {
                        topUniqStateIdsByConcurrencyGroup[concurrencyGroupIdVal].push(stateIdValue);
                    } else {
                        topUniqStateIdsByConcurrencyGroup[concurrencyGroupIdVal] = [stateIdValue];
                    }
                });
                const groupKeys = Object.keys(topUniqStateIdsByConcurrencyGroup);
                groupKeys.forEach((cg, index) => {
                    let stateIdsInGroup = topUniqStateIdsByConcurrencyGroup[cg];
                    stateIdsInGroup.forEach(stateIdValue => {

                        if(stateIdValue === "[*]") {
                            let nodeObject = nodes[this.getNodeIdFromStateId(stateIdValue, transitionToStateIdVal)];
                            this.addStateInfo("[*]", nodeObject, mermaidDataCollector);
                            //Start node cannot be styled, so we do not add that
                            const relations = nodeObject.relations;
                            if(relations) {
                                Object.keys(relations).forEach(rTo => {
                                    this.addRelation(stateIdValue, rTo, nodes, nodeObject, usedIds, mermaidDataCollector);
                                })
                                Object.keys(relations).forEach(transitionToId => {
                                    this.processTransitionTo(transitionToId, resolvedValues, usedIds, mermaidDataCollector, nodes)
                                })
                            }
                        } else {
                            this.processTransitionTo(stateIdValue, resolvedValues, usedIds, mermaidDataCollector, nodes)
                        }
                    });
                    if(groupKeys.length - 1 > index) {
                        mermaidDataCollector.push(`\n--`)
                    }
                })
                mermaidDataCollector.push(`\n}`)
                let nodeObject = nodes[transitionToStateIdVal];
                const relations = nodeObject.relations;
                if(relations) {
                    Object.keys(relations).forEach(rTo => {
                        this.addRelation(nodeObject.mermaidId, rTo, nodes, nodeObject, usedIds, mermaidDataCollector);
                    })
                    Object.keys(relations).forEach(transitionToId => {
                        this.processTransitionTo(transitionToId, resolvedValues, usedIds, mermaidDataCollector, nodes)
                    })
                }
            } else {
                const nodeObject = nodes[transitionToStateIdVal];
                const mermaidResourceId = generateMermaidResourceIdForStateDiagram(transitionToStateIdVal, usedIds);
                this.addStateInfo(mermaidResourceId, nodeObject, mermaidDataCollector);
                const relations = nodeObject.relations;
                if(relations) {
                    Object.keys(relations).forEach(rTo => {
                        this.addRelation(mermaidResourceId, rTo, nodes, nodeObject, usedIds, mermaidDataCollector);
                    })
                    Object.keys(relations).forEach(transitionToId => {
                        this.processTransitionTo(transitionToId, resolvedValues, usedIds, mermaidDataCollector, nodes)
                    })
                }
            }
        }

    }

    addRelation = (relationFromMermaidId, relationToId, nodes, nodeObject, usedIds, mermaidDataCollector) => {
        const mermaidResourceIdForTo = generateMermaidResourceIdForStateDiagram(relationToId, usedIds);
        let visited = nodeObject.relationsVisited || [];
        let found = visited.find(v => v.from === relationFromMermaidId && v.to === mermaidResourceIdForTo);
        if(!found) {
            let relationLabel = nodeObject.relations[relationToId];
            if (relationToId !== '[*]') {
                this.addStateInfo(mermaidResourceIdForTo, nodes[relationToId], mermaidDataCollector);
            }
            mermaidDataCollector.push("\n" + `${relationFromMermaidId} --> ${mermaidResourceIdForTo}${relationLabel ? " : " + relationLabel : ""}`)
            if (!nodeObject.relationsVisited) {
                nodeObject.relationsVisited = []
            }
            nodeObject.relationsVisited.push({from: relationFromMermaidId, to: mermaidResourceIdForTo});
        }
    }


    render() {
        const {mermaidData} = this.state;
        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}}
            >{mermaidData}</MermaidWrapper>}
        </>;
    }
}

