import React from "react";
import MermaidWrapper, {getPropertyValue} from "./MermaidWrapper";

const sectionTitle = 'sectionTitle';
const timePeriod = 'timePeriod';
const event = 'event';

export const TIMELINE_DIAGRAM_SETTINGS = [
    {label : "Section Title", stateKey : sectionTitle},
    {label : "Time Period", stateKey : timePeriod},
    {label : "Event", stateKey : event},
];

export default class TimeLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData: ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {
        })
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let themeSetting = diagramSettings?.['theme'] ? `
%%{
  init: {
    'theme': '${diagramSettings['theme']}'
  }
}%%
` : '';
        let resolvedValues = [];
        for (let i = 0; i < bindings.length; i++) {
            let bd = bindings[i];
            let resolvedObject = {};
            let keys = Object.keys(bd);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                resolvedObject[key] = await getPropertyValue(diagramSettings, key, bd);
            }
            resolvedValues.push(resolvedObject);
        }

        let usedIdsMap = {};
        let mermaidData = themeSetting +'\n'+`timeline`;

        let mermaidDataCollector = [];
        this.process(usedIdsMap, mermaidDataCollector, resolvedValues);
        mermaidData = mermaidData + "" + mermaidDataCollector.join("");
        console.log("mermaidData", mermaidData)
        return mermaidData;
    }

    process = (usedIdsMap, mermaidDataCollector, resolvedValues) => {
        let data = [];
        resolvedValues.forEach(rv => {
            const sectionTitleVal = rv[sectionTitle];
            const found = data.find(dt => dt[sectionTitle] === sectionTitleVal);
            if(!found) {
                data.push({
                    [sectionTitle] : sectionTitleVal,
                    events : {}
                })
            }
        });
        data.forEach(s => {
            const sectionSub = resolvedValues.filter(dt => dt[sectionTitle] === s[sectionTitle]);
            sectionSub.forEach(ss => {
                const timePeriodValue = ss[timePeriod];
                const eventValue = ss[event];
                if(s.events[timePeriodValue]) {
                    s.events[timePeriodValue].push(eventValue)
                } else {
                    s.events[timePeriodValue] = [eventValue]
                }
            })
        })
        data.forEach(s => {
            if(s[sectionTitle]) {
                mermaidDataCollector.push(`\nsection ${s[sectionTitle]}`);
            }
            Object.keys(s.events).forEach(ek => {
                mermaidDataCollector.push(`\n ${ek}`);
                s.events[ek].forEach(ev => {
                    mermaidDataCollector.push(`\n   : ${ev}`);
                })
            })
        })
    }

    render() {
        const {mermaidData} = this.state;

        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}} >{mermaidData}</MermaidWrapper>}
        </>;
    }

}