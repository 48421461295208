import React from "react";
import MermaidWrapper, {
    getAlphaNumericOnly,
    getMermaidResourceId,
    getPropertyValue,
    normalizeStyle
} from "./MermaidWrapper";
import {getLocalName} from "../../../components/util";


const entityNodeLabel = 'entityNodeLabel';
const entityNodeLabelStyle = 'entityNodeLabelStyle';
const entityNodeBoxStyle = 'entityNodeBoxStyle';
const attributeLabel = 'attributeLabel';
const attributeDatatype = 'attributeDatatype';
const attributeKey = 'attributeKey';
const attributeComment = 'attributeComment';
const attributeBoxOddStyle = 'attributeBoxOddStyle';
const attributeBoxEvenStyle = 'attributeBoxEvenStyle';
const relationshipTargetEntityNodeLabel = 'relationshipTargetEntityNodeLabel';
const relationshipEdgeIdentity = 'relationshipEdgeIdentity';
const relationshipLabel = 'relationshipLabel';
const relationshipLabelStyle = 'relationshipLabelStyle';
const relationshipLabelBoxStyle = 'relationshipLabelBoxStyle';
const relationshipLineStyle = 'relationshipLineStyle';
const relationshipCardinalityOnSource = 'relationshipCardinalityOnSource';
const relationshipCardinalityOnTarget = 'relationshipCardinalityOnTarget';

export const ER_DIAGRAM_SETTINGS = [
    {label : "Entity Node Label", stateKey : entityNodeLabel},
    {label : "Entity Node Box Style", stateKey : entityNodeBoxStyle},
    {label : "Entity Node Label Style", stateKey : entityNodeLabelStyle},
    {label : "Attribute Label", stateKey : attributeLabel},
    {label : "Attribute Datatype", stateKey : attributeDatatype},
    {label : "Attribute Key", stateKey : attributeKey},
    {label : "Attribute Comment", stateKey : attributeComment},
    {label : "Attribute Box Odd Style", stateKey : attributeBoxOddStyle},
    {label : "Attribute Box Even Style", stateKey : attributeBoxEvenStyle},
    {label : "Relationship Target Node Label", stateKey : relationshipTargetEntityNodeLabel},
    {label : "Relationship Edge Type", stateKey : relationshipEdgeIdentity},
    {label : "Relationship Label", stateKey : relationshipLabel},
    {label : "Relationship Label Style", stateKey : relationshipLabelStyle},
    {label : "Relationship Label Box Style", stateKey : relationshipLabelBoxStyle},
// Not working at the moment    {label : "Relationship Line Style", stateKey : relationshipLineStyle},
    {label : "Relationship Cardinality On Source", stateKey : relationshipCardinalityOnSource},
    {label : "Relationship Cardinality On Target", stateKey : relationshipCardinalityOnTarget},
];

const relationshipLeftEdgeTypes = [
    "|o",
    "||",
    "}o",
    "}|"
];

const relationshipRightEdgeTypes = [
    "o|",
    "||",
    "o{",
    "|{"
];

const relationshipEdgeIdentityTypes = ["--", ".."];

export default class ERDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mermaidData  : ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData})).catch(e => {})
    }

    createObject = () => {
        return {
            properties: [],
            relations : []
        }
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let themeObject = {
                ["theme"] : `${diagramSettings?.['theme']}`,
                ["themeCSS"] : []
            }
        let mermaidData = '\n'+`erDiagram`;
        const direction = diagramSettings?.direction;
        if(direction) {
            //mermaidData = mermaidData +"\n"+`direction ${direction}`
        }
        let nodes = {};
        let edgesTo = new Set();
        let themeCSS = new Set();
        for(let i=0;i< bindings.length;i++){
            let bd = bindings[i];
            let entityNodeLabelVal = await getPropertyValue(diagramSettings, entityNodeLabel, bd);
            if(!nodes[entityNodeLabelVal]) {
                nodes[entityNodeLabelVal] = this.createObject();
            }
            let entityKeys = [entityNodeLabel, entityNodeBoxStyle, entityNodeLabelStyle];
            for(let i=0;i<entityKeys.length;i++) {
                let key = entityKeys[i];
                if(!nodes[entityNodeLabelVal][key]) {
                    nodes[entityNodeLabelVal][key] = await getPropertyValue(diagramSettings, key, bd);
                }
            }
            let attributeLabelVal = await getPropertyValue(diagramSettings, attributeLabel, bd);
            let attributeKeys = [attributeLabel, attributeKey, attributeDatatype, attributeComment, attributeBoxEvenStyle, attributeBoxOddStyle];
            for(let i=0;i<attributeKeys.length;i++) {
                let key = attributeKeys[i];
                let foundObject = nodes[entityNodeLabelVal].properties.find(p => p[attributeLabel] === attributeLabelVal);
                if(!foundObject) {
                    foundObject = {
                        [attributeLabel] : attributeLabelVal
                    };
                    nodes[entityNodeLabelVal].properties.push(foundObject)
                }
                if(!foundObject[key]) {
                    foundObject[key] = await getPropertyValue(diagramSettings, key, bd);
                }
            }
            let relationshipLabelVal = await getPropertyValue(diagramSettings,relationshipLabel, bd)
            let relationshipTargetEntityNodeLabelVal = await getPropertyValue(diagramSettings, relationshipTargetEntityNodeLabel, bd)
            if(relationshipTargetEntityNodeLabelVal) {
                let relationshipKeys = [relationshipLabelStyle, relationshipLabelBoxStyle, relationshipLineStyle, relationshipEdgeIdentity, relationshipCardinalityOnSource, relationshipCardinalityOnTarget];
                for (let i = 0; i < relationshipKeys.length; i++) {
                    let key = relationshipKeys[i];
                    let foundObject = nodes[entityNodeLabelVal].relations.find(p => {
                        let found = p[relationshipTargetEntityNodeLabel] === relationshipTargetEntityNodeLabelVal
                            && relationshipLabelVal && p[relationshipLabel] === relationshipLabelVal;
                        return found ? true : false;
                    });
                    if (!foundObject) {
                        foundObject = {
                            [relationshipLabel]: relationshipLabelVal,
                            [relationshipTargetEntityNodeLabel]: relationshipTargetEntityNodeLabelVal
                        };
                        nodes[entityNodeLabelVal].relations.push(foundObject)
                    }
                    if (!foundObject[key]) {
                        foundObject[key] = await getPropertyValue(diagramSettings,key, bd);
                    }
                }
                edgesTo.add(relationshipTargetEntityNodeLabelVal);
            }
        }
        edgesTo.forEach(e => {
            if(!nodes[e]) {
                const classObject = this.createObject();
                nodes[e] = classObject;
            }
        })
        let mermaidIds = {};

        Object.keys(nodes).forEach(id => {
            const entityLabelUnquoted = nodes[id][entityNodeLabel]  || getLocalName(id, false);;
            let entityIdForMermaid = getMermaidResourceId(entityLabelUnquoted);
            if(mermaidIds[entityIdForMermaid]) {
                mermaidIds[entityIdForMermaid] = [...mermaidIds[entityIdForMermaid], id];
                entityIdForMermaid = entityIdForMermaid+"_"+mermaidIds[entityIdForMermaid].length;
            }
            mermaidIds[entityIdForMermaid] = [id];
            const entityNodeBoxStyleVal = nodes[id][entityNodeBoxStyle];
            if(entityNodeBoxStyleVal) {
                let entityNodeBoxStyleValToUse = ` .er.entityBox ${normalizeStyle(entityNodeBoxStyleVal)} `;
                themeCSS.add(`[id*=entity-${entityIdForMermaid}] ${entityNodeBoxStyleValToUse}`)
            }
            const entityNodeLabelStyleVal = nodes[id][entityNodeLabelStyle];
            if(entityNodeLabelStyleVal) {
                let entityNodeLabelStyleValToUse = ` .er.entityLabel ${normalizeStyle(entityNodeLabelStyleVal)} `;
                themeCSS.add(`[id*=entity-${entityIdForMermaid}] ${entityNodeLabelStyleValToUse}`)
            }

            mermaidData = mermaidData+"\n"+`${entityIdForMermaid}["${entityLabelUnquoted}"] {`;
            const nodeProperties = nodes[id].properties;
            nodeProperties.forEach(p => {
                const attributeLabelVal = p[attributeLabel];
                const attributeDatatypeVal = p[attributeDatatype] && getLocalName(p[attributeDatatype], false);
                const attributeKeyVal = p[attributeKey] || "" ;
                const attributeCommentVal = p[attributeComment] || "" ;
                if(attributeDatatypeVal && attributeLabelVal) {
                    mermaidData = mermaidData + "\n" + `    ${getAlphaNumericOnly(attributeDatatypeVal)} ${getAlphaNumericOnly(attributeLabelVal)} ${getAlphaNumericOnly(attributeKeyVal)} ${attributeCommentVal ? `"${attributeCommentVal}"` : ""  }`;
                }
                const attributeBoxEvenStyleVal = p[attributeBoxEvenStyle];
                if(attributeBoxEvenStyleVal) {
                    let attributeBoxEvenStyleValToUse = ` .er.attributeBoxEven ${normalizeStyle(attributeBoxEvenStyleVal)} `;
                    themeCSS.add(`[id*=entity-${entityIdForMermaid}] ${attributeBoxEvenStyleValToUse} `)
                }
                const attributeBoxOddStyleVal = p[attributeBoxOddStyle];
                if(attributeBoxOddStyleVal) {
                    let attributeBoxOddStyleValToUse = ` .er.attributeBoxOdd ${normalizeStyle(attributeBoxOddStyleVal)} `;
                    themeCSS.add(`[id*=entity-${entityIdForMermaid}] ${attributeBoxOddStyleValToUse}`)
                }
            })
            mermaidData = mermaidData + "\n" + `}`;

            nodes[id].relations.forEach(r => {
                const relationshipLabelVal = r[relationshipLabel] || getLocalName(r[relationshipLabel], false) ||"";
                const relationshipCardinalityOnSourceVal = r[relationshipCardinalityOnSource] || "}|";
                const relationshipCardinalityOnTargetVal = r[relationshipCardinalityOnTarget] || "|{";
                const relationshipEdgeIdentityVal = r[relationshipEdgeIdentity] || "--";
                const relationshipTargetEntityNodeLabelVal =  r[relationshipTargetEntityNodeLabel];
                if(relationshipTargetEntityNodeLabelVal) {
                    let targetClassMermaidId = getMermaidResourceId(relationshipTargetEntityNodeLabelVal)
                    mermaidData = mermaidData + "\n" + `${targetClassMermaidId} ${relationshipCardinalityOnTargetVal} ${relationshipEdgeIdentityVal} ${relationshipCardinalityOnSourceVal} ${entityIdForMermaid} : "${relationshipLabelVal}"`
                }
                if(r[relationshipLabelStyle]) {
                    themeCSS.add(` .er.relationshipLabel  ${normalizeStyle(r[relationshipLabelStyle])}`)
                }
                if(r[relationshipLabelBoxStyle]) {
                    themeCSS.add(` .er.relationshipLabelBox ${normalizeStyle(r[relationshipLabelBoxStyle])}`)
                }
            })
        });
        themeObject.themeCSS =  [...themeCSS];
        let themeSetting = `%%{init: ${JSON.stringify(themeObject, null, 2)}}%%`;
        mermaidData =  themeSetting+"\n"+mermaidData;
        console.log("mermaidData", mermaidData)
        return mermaidData;

        //this.setState({mermaidData})

    }

    render() {
        const {mermaidData} = this.state;
        return <>
            {<MermaidWrapper
                name={this.props.name}
                onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}}
            >{mermaidData}</MermaidWrapper>}
        </>;
    }
}
