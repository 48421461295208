import React, {useCallback, useContext, useEffect, useState} from "react";
import mermaid from "mermaid";
import svgPanZoom from "svg-pan-zoom"
import "./index.css";
import {escapeForSPARQL, getBindingValue} from "../../../service/sparql-queries";
import {VIS_RADIO_VALUE_DATA} from "../ViualisationSetting";
import {runSPARQLQuery} from "../../../service/ai";
import {centerVertically, flattenRecursive, getLocalName} from "../../../components/util";
import {Dialog, DialogContent, IconButton, Paper} from "@material-ui/core";
import uuid4 from "uuid/v4";
import {
    CameraAltOutlined, DoubleArrowOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    GetAppOutlined,
    SettingsBackupRestoreOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@material-ui/icons";
import {
    UI_LABELS_DOWNLOAD_IMAGE,
    UI_LABELS_DOWNLOAD_SVG,
    UI_LABELS_FULL_SCREEN,
    UI_LABELS_FULL_SCREEN_EXIT,
    UI_LABELS_REFRESH,
    UI_LABELS_ZOOM_IN,
    UI_LABELS_ZOOM_OUT
} from "../UILabel";
import GlobalsContext from "../../../components/GlobalsContext";
import Tooltip from "@material-ui/core/Tooltip";
import {toBase64} from 'js-base64';

mermaid.initialize({
    startOnLoad: true,
    theme: "default",
    securityLevel: "loose",
    themeCSS: `
    g.classGroup rect {
      fill: #282a36;
      stroke: #6272a4;
    } 
    g.classGroup text {
      fill: #f8f8f2;
    }
    g.classGroup line {
      stroke: #f8f8f2;
      stroke-width: 0.5;
    }
    .classLabel .box {
      stroke: #21222c;
      stroke-width: 3;
      fill: #21222c;
      opacity: 1;
    }
    .classLabel .label {
      fill: #f1fa8c;
    }
    .relation {
      stroke: #ff79c6;
      stroke-width: 1;
    }
    #compositionStart, #compositionEnd {
      fill: #bd93f9;
      stroke: #bd93f9;
      stroke-width: 1;
    }
    #aggregationEnd, #aggregationStart {
      fill: #21222c;
      stroke: #50fa7b;
      stroke-width: 1;
    }
    #dependencyStart, #dependencyEnd {
      fill: #00bcd4;
      stroke: #00bcd4;
      stroke-width: 1;
    } 
    #extensionStart, #extensionEnd {
      fill: #f8f8f2;
      stroke: #f8f8f2;
      stroke-width: 1;
    }`,
    fontFamily: "Fira Code"
});

// Function to find the line number with the most characters in common with the error
export function findMostRelevantLineNumber(errorLineText, code) {
    const codeLines = code.split('\n');
    let mostRelevantLineNumber = -1;
    let maxCommonLength = 0;

    for (const [i, line] of codeLines.entries()) {
        let commonLength = 0;
        for (let j = 0; j <= errorLineText.length; j++) {
            for (let k = j + 1; k <= errorLineText.length; k++) {
                const sub = errorLineText.slice(j, k);
                if (line.includes(sub)) {
                    commonLength = Math.max(commonLength, sub.length);
                }
            }
        }
        if (commonLength > maxCommonLength) {
            maxCommonLength = commonLength;
            mostRelevantLineNumber = i + 1; // Line numbers start from 1
        }
    }
    return mostRelevantLineNumber;
}

// Function to replace the incorrect line number in the error message
export function replaceLineNumberInErrorMessage(
    errorMessage,
    realLineNumber
) {
    const regexParseError = /Parse error on line (\d+):/;
    const regexLexError = /Lexical error on line (\d+)/;
    return errorMessage
        .replace(regexParseError, `Parse error on line ${realLineNumber}:`)
        .replace(regexLexError, `Lexical error on line ${realLineNumber}:`);
}

export function extractErrorLineText(errorMessage) {
    const regex = /Error: Parse error on line \d+:\n(.+)\n+/;
    const match = errorMessage.match(regex);
    if (match) {
        return match[1].slice(3);
    }

    const regexLex = /Error: Lexical error on line \d+. Unrecognized text.\n(.+)\n-+/;
    const matchLex = errorMessage.match(regexLex);
    return matchLex ? matchLex[1].slice(3) : '';
}

export async function getPropertyValue(diagramSettings, propertyName, binding) {
    if(!diagramSettings || !diagramSettings[propertyName]) {
        return getBindingValue(binding, propertyName);
    }
    let {radioValue, selectedVariable, query, bindings} =  diagramSettings?.[propertyName];
    if(radioValue === VIS_RADIO_VALUE_DATA || !radioValue) {
        return getBindingValue(binding, selectedVariable || propertyName);
    } else {
        let replacedQuery = query;
        bindings.forEach(bd => {
            let variable = bd.value;
            let valueFromData = getBindingValue(binding, bd.bindTo);
            let valueDatatype = binding?.[bd.bindTo]?.datatype;
            let valueType = binding?.[bd.bindTo]?.type;
            let lang = binding?.[bd.bindTo]?.["xml:lang"];
            const escapedValueFromData = escapeForSPARQL(valueFromData);
            let replacementValue = valueType === 'uri'
                ? "<"+valueFromData+">"
                : lang
                    ? '"'+ escapedValueFromData+'"'+"@"+lang
                    : valueDatatype ? '"'+escapedValueFromData+'"'+"^^"+"<"+valueDatatype+">" : '"'+escapedValueFromData+'"'
            replacedQuery = replacedQuery.replace(variable, " "+replacementValue+" ");
        });
        try {
            const queryResult = await runSPARQLQuery(replacedQuery);
            let queryResultData = queryResult?.['results']?.['bindings'];
            let values = queryResultData.map(bd => Object.keys(bd).map(k => getBindingValue(bd, k)));
            const flattened = flattenRecursive(values);
            return flattened.length > 0 ? flattened[0] : "NO_VALUE";
        } catch (e) {
            console.log("ERROR_IN_QUERY", e);
            return "ERROR_IN_QUERY";
        }
    }
}

export function normalizeStyle(styleValue) {
    if(styleValue.trim().startsWith("{")) {
        return styleValue;
    }
    return "{"+styleValue+"}";

}

export function generateMermaidResourceIdForStateDiagram(value, usedIdsMap ) {
    if(value.trim() === "[*]") {
        return "[*]";
    }
    return generateMermaidResourceId(value, usedIdsMap);
}

export function generateMermaidResourceId(value, usedIdsMap ) {
    if(usedIdsMap[value]) {
        return usedIdsMap[value];
    }
    let mermaidResourceId = getMermaidResourceId(getLocalName(value, false));
    while(Object.keys(usedIdsMap).map(k => usedIdsMap[k]).find(v => v === mermaidResourceId)) {
        mermaidResourceId = mermaidResourceId+"0";
    }
    usedIdsMap[value] = mermaidResourceId;
    return mermaidResourceId;
}

export function getMermaidResourceId(classLabelUnquoted) {
    return classLabelUnquoted.replace(/[\W_]+/g, "");
}

export function getAlphaNumericOnly(value) {
    return value.replace(/[\W_]+/g, "");
}

const MermaidWrapper = (props) => {
    const globals = useContext(GlobalsContext);

    const [mermaidId, setMermaidId] = useState(props.id || getMermaidResourceId("a"+uuid4()));
    const [element, setElement] = useState();
    const [render_result, setRenderResult] = useState();
    const [fullScreen, setFullScreen] = useState(false);
    const [refreshId, setRefreshId] = useState(uuid4());
    const [panZoom, setPanZoom] = useState(uuid4());
    const [settingsMenuPaneOpen, setSettingsMenuPaneOpen] = useState(false);


    const container_id = `${mermaidId}-mermaid`;
    const svg_id = `${mermaidId}-svg`;
    const diagram_text = props.children;

    // initialize mermaid here, but beware that it gets called once for every instance of the component
    useEffect(() => {
        // wait for page to load before initializing mermaid
        mermaid.initialize({
            startOnLoad: true,
            securityLevel: "loose",
            suppressErrorRendering: true,
            // theme: "forest",
            logLevel: 5,
            "class" : {
                hideEmptyMembersBox : true,
            },
            "gantt" : {
                barHeight : 40,
                fontSize : 14,
                barGap : 8
            }
        });
    },[]);

    // hook to track updates to the component ref, compatible with useEffect unlike useRef
    const updateDiagramRef = useCallback((elem) => {
        if (!elem) return;
        setElement(elem);
    }, []);

    // hook to update the component when either the element or the rendered diagram changes
    useEffect(() => {
        if (!element) return;
        if (!render_result?.svg) return;
        //below is to fit the svg in container and make zoom pan work
        element.innerHTML = render_result.svg.replace(/max-width:[\s0-9\.]*px;/i, 'width : 100%;height :100%;');
        render_result.bindFunctions?.(element);
        let panZoom = svgPanZoom("#"+svg_id,{
            zoomEnabled: true
            , controlIconsEnabled: false
            , fit: 1
            , center: 1
        })
        setPanZoom(panZoom);
        window.nodeClick = async function (e) {
            console.log("nodeClick", e);
        }
    }, [
        element,
        render_result
    ]);

    const handleError = async (code) => {
        try {
            await mermaid.parse(code);
        } catch (error) {
            //processed.error = error as Error;
            //errorDebug();
            console.error(error);
            if ('hash' in error) {
                try {
                    let errorString = error.toString();
                    const errorLineText = extractErrorLineText(errorString);
                    const realLineNumber = findMostRelevantLineNumber(errorLineText, code);

                    let first_line, last_line, first_column, last_column;
                    try {
                        ({ first_line, last_line, first_column, last_column } = (error.hash).loc);
                    } catch {
                        const lineNo = findMostRelevantLineNumber(errorString, code);
                        first_line = lineNo;
                        last_line = lineNo + 1;
                        first_column = 0;
                        last_column = 0;
                    }

                    if (realLineNumber !== -1) {
                        errorString = replaceLineNumberInErrorMessage(errorString, realLineNumber);
                    }

                    const marker = {
                        severity: 8, // Error
                        startLineNumber: realLineNumber,
                        startColumn: first_column,
                        endLineNumber: last_line + (realLineNumber - first_line),
                        endColumn: last_column + (first_column === last_column ? 0 : 5),
                        message: errorString || 'Syntax error'
                    };
                    return marker;
                } catch (error) {
                    console.error('Error without line helper', error);
                }
            }
        }
    }

    // hook to handle the diagram rendering
    useEffect(() => {
        if (!diagram_text && diagram_text.length === 0) return;
        // create async function inside useEffect to cope with async mermaid.run
        (async () => {
            try {
                await mermaid.parse(diagram_text);

                const rr = await mermaid.render(`${svg_id}`, diagram_text);

                setRenderResult(rr);
            } catch (e) {

                console.log("renderError",e);
                let marker = await handleError(diagram_text);
                console.log("code",diagram_text);
                console.log("marker",marker);

                props.onError?.(e);
            }
        })();
    }, [
        diagram_text, refreshId
    ]);

    const getFileName = (extension) => {
        return `${props.name || 'diagram'}-${new Date().toISOString()}.${extension}`;
    }

    const simulateDownload = (download, href) => {
        const a = document.createElement('a');
        a.download = download;
        a.href = href;
        a.click();
        a.remove();
    };

    const downloadImage = (context, image) => {
        return () => {
            const { canvas } = context;
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            simulateDownload(
                getFileName('png'),
                canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
            );
        };
    };

    const onDownloadPNG = async (event) => {
        await exportImage(event, downloadImage);

    };

    const onDownloadSVG = () => {
        simulateDownload(getFileName('svg'), `data:image/svg+xml;base64,${getBase64SVG()}`);
    };

    const exportImage = async (event, exporter) => {
        const canvas = document.createElement('canvas');
        const svg = document.querySelector(`#${container_id} svg`);
        if (!svg) {
            throw new Error('svg not found');
        }
        const box = svg.getBoundingClientRect();
        canvas.width = box.width;
        canvas.height = box.height;

        const context = canvas.getContext('2d');
        if (!context) {
            throw new Error('context not found');
        }
        context.fillStyle = `hsl(${window.getComputedStyle(document.body).getPropertyValue('--b1')})`;
        context.fillRect(0, 0, canvas.width, canvas.height);

        const image = new Image();
        image.addEventListener('load', exporter(context, image));
        image.src = `data:image/svg+xml;base64,${getBase64SVG(svg, canvas.width, canvas.height)}`;

        event.stopPropagation();
        event.preventDefault();
    };

    const getSvgElement = () => {
        const svgElement = document.querySelector(`#${container_id} svg`)?.cloneNode(true);
        svgElement.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
        return svgElement;
    };

    const getBase64SVG = (svg, width, height) => {
        if (svg) {
            // Prevents the SVG size of the interface from being changed
            svg = svg.cloneNode(true);
        }
        height && svg?.setAttribute('height', `${height}px`);
        width && svg?.setAttribute('width', `${width}px`); // Workaround https://stackoverflow.com/questions/28690643/firefox-error-rendering-an-svg-image-to-html5-canvas-with-drawimage
        if (!svg) {
            svg = getSvgElement();
        }
        const svgString = svg.outerHTML
            .replaceAll('<br>', '<br/>')
            .replaceAll(/<img([^>]*)>/g, (m, g) => `<img ${g} />`);

        return toBase64(`<?xml version="1.0" encoding="UTF-8"?>
${svgString}`);
    };

    // render container (div) to hold diagram (nested SVG)
    const key = fullScreen ? UI_LABELS_FULL_SCREEN_EXIT : UI_LABELS_FULL_SCREEN;
    const content = <>
        {
            props.onRefresh &&  <Paper elevation={3} style={{position: "absolute", display : 'flex', gap: '8px', padding : '4px 16px'}}>
                <Tooltip title={globals?.getUiLabelTranslationFor?.(key, key)}>
                    <IconButton datatest={'fullScreenButton'} color={'primary'} onClick={() => {
                        setFullScreen(!fullScreen);
                    }} size={'small'}>{fullScreen ? <FullscreenExitOutlined/> :<FullscreenOutlined/>}</IconButton>
                </Tooltip>
                <Tooltip title={globals?.getUiLabelTranslationFor?.(UI_LABELS_REFRESH, UI_LABELS_REFRESH)}>
                <IconButton datatest={'refreshButton'} color={'primary'} onClick={() => {
                    props.onRefresh();
                    setRefreshId(uuid4());
                }} size={'small'}><SettingsBackupRestoreOutlined/></IconButton>
                </Tooltip>
                { settingsMenuPaneOpen &&
                    <>
                        <Tooltip
                            title={globals?.getUiLabelTranslationFor?.(UI_LABELS_DOWNLOAD_IMAGE, UI_LABELS_DOWNLOAD_IMAGE)}>
                            <IconButton datatest={'downloadPNGButton'} color={'primary'} onClick={onDownloadPNG}
                                        size={'small'}><CameraAltOutlined/></IconButton>
                        </Tooltip>
                        <Tooltip
                            title={globals?.getUiLabelTranslationFor?.(UI_LABELS_DOWNLOAD_SVG, UI_LABELS_DOWNLOAD_SVG)}>
                            <IconButton datatest={'downloadSVGButton'} color={'primary'} onClick={onDownloadSVG}
                                        size={'small'}><GetAppOutlined/></IconButton>
                        </Tooltip>
                        {
                            panZoom &&
                            <>
                                <Tooltip title={globals?.getUiLabelTranslationFor?.(UI_LABELS_ZOOM_IN, UI_LABELS_ZOOM_IN)}>
                                    <IconButton datatest={'zoomInButton'} color={'primary'} onClick={() => {
                                        panZoom.zoomIn();
                                    }} size={'small'}><ZoomInOutlined/></IconButton></Tooltip>
                                <Tooltip
                                    title={globals?.getUiLabelTranslationFor?.(UI_LABELS_ZOOM_OUT, UI_LABELS_ZOOM_OUT)}>
                                    <IconButton datatest={'zoomOutButton'} color={'primary'} onClick={() => {
                                        panZoom.zoomOut();
                                    }} size={'small'}><ZoomOutOutlined/></IconButton>
                                </Tooltip>
                            </>
                        }
                    </>
                }
                {
                    centerVertically(<Tooltip title={ settingsMenuPaneOpen ? 'Minimize': 'More'}>
                        <IconButton
                            datatest={'moreOptions'}
                            color={'primary'}
                            size={'small'}
                            onClick={() => {
                                setSettingsMenuPaneOpen(!settingsMenuPaneOpen)
                            }}
                        >
                            <DoubleArrowOutlined style={settingsMenuPaneOpen ? {transform : 'rotate(180deg)'} : {}}></DoubleArrowOutlined>
                        </IconButton>
                    </Tooltip>, {paddingLeft : '4px', marginLeft : '0px', borderRadius : '2px', borderLeft : '4px solid', borderColor : globals?.theme.palette.border.main})
                }
            </Paper>
        }
        <div className={props.className}
             onClick={props.onClick}
             id={container_id}
             data-testid={props.testId}
             ref={updateDiagramRef}
             style={{overflow: 'hidden', width: "100%", height: "100%", textAlign: 'center'}}
        />
    </>;

    const renderDialog = (content) => {
        return fullScreen && <Dialog
            datatest={'mermaidViewDialog'}
            aria-labelledby="Diagram View"
            open={fullScreen}
            fullWidth={true}
            fullScreen={true}
        >

            <DialogContent style={{padding : '8px'}}>
                {content}
            </DialogContent>
        </Dialog>;
    }

    return fullScreen ? renderDialog(content)  : content;
}

export default MermaidWrapper
