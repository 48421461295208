import React from "react";
import MermaidWrapper, {getMermaidResourceId, getPropertyValue} from "./MermaidWrapper";
import PropTypes from "prop-types";
import {getLocalName} from "../../../components/util";
import {withStyles} from "@material-ui/core/styles";


const classNodeId = 'classNodeId';
const classNodeLabel = 'classNodeLabel';
const classNodeSubtitle = 'classNodeSubtitle';
const classNodeAnnotation = 'classNodeAnnotation';
const classNodeNote = 'classNodeNote';
const classNodeStyle = 'classNodeStyle';
const propertyId = 'propertyId';
const propertyLabel = 'propertyLabel';
const propertyDatatype = 'propertyDatatype';
const propertyVisibility = 'propertyVisibility';
const methodId = 'methodId';
const methodName = 'methodName';
const methodParameters = 'methodParameters';
const methodVisibility = 'methodVisibility';
const methodReturnType = 'methodReturnType';
const relationshipTargetClassId = 'relationshipTargetClassId';
const relationshipEdgeType = 'relationshipEdgeType';
const relationshipLabel = 'relationshipLabel';
const relationshipCardinalityOnSource = 'relationshipCardinalityOnSource';
const relationshipCardinalityOnTarget = 'relationshipCardinalityOnTarget';

const relationShipEdgeTypes = [
  {label : "Inheritance", value : "<|--"},
  {label : "Inheritance", value : "--|>"},
  {label : "Composition", value : "*--"},
  {label : "Composition", value : "--*"},
  {label : "Aggregation", value : "o--"},
  {label : "Aggregation", value : "--o"},
  {label : "Association", value : "-->"},
  {label : "Association", value : "<--"},
  {label : "Link (Solid)", value : "--"},
  {label : "Dependency", value : "..>"},
  {label : "Dependency", value : "<.."},
  {label : "Realization", value : "..|>"},
  {label : "Realization", value : "<|.."},
  {label : "Link (Dashed)", value : ".."}
];

export const CLASS_DIAGRAM_DIRECTION = [
    {label : "Top To Bottom", value : "TB"},
    {label : "Bottom To Top", value : "BT"},
    {label : "Left To Right", value : "LR"},
    {label : "Right To Left", value : "RL"},
]

export const CLASS_DIAGRAM_SETTINGS = [
  {label : "Class Node Id", stateKey : classNodeId},
  {label : "Class Node Label", stateKey : classNodeLabel},
  {label : "Class Node Subtitle", stateKey : classNodeSubtitle},
  {label : "Class Node Annotation", stateKey : classNodeAnnotation},
  {label : "Class Node Note", stateKey : classNodeNote},
  {label : "Class Node Style", stateKey : classNodeStyle},
  {label : "Property Id", stateKey : propertyId},
  {label : "Property Label", stateKey : propertyLabel},
  {label : "Property Datatype", stateKey : propertyDatatype},
  {label : "Property Visibility", stateKey : propertyVisibility},
  {label : "Method Id", stateKey : methodId},
  {label : "Method Name", stateKey : methodName},
  {label : "Method Parameters", stateKey : methodParameters},
  {label : "Method Visibility", stateKey : methodVisibility},
  {label : "Method Return Type", stateKey : methodReturnType},
  {label : "Relationship Target Class Id", stateKey : relationshipTargetClassId},
  {label : "Relationship Edge Type", stateKey : relationshipEdgeType},
  {label : "Relationship Label", stateKey : relationshipLabel},
  {label : "Relationship Cardinality On Source", stateKey : relationshipCardinalityOnSource},
  {label : "Relationship Cardinality On Target", stateKey : relationshipCardinalityOnTarget},
];


class ClassDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          mermaidData  : ""
        };
    }

    componentDidMount() {
        this.load().then(mermaidData => this.setState({mermaidData}))
    }


    createClassObject = () => {
      return {
        properties: [],
        methods : [],
        relations : []
      }
    }

    load = async () => {
        const {data, diagramSettings, theme} = this.props;
        let bindings = data?.["results"]?.["bindings"];
        let themeSetting = diagramSettings ? `
%%{
  init: {
    'theme': '${diagramSettings['theme']}',
  }
}%%
        ` : '';
        let mermaidData = themeSetting +'\n'+`classDiagram`;
        const direction = diagramSettings?.direction;
        if(direction) {
          mermaidData = mermaidData +"\n"+`direction ${direction}`
        }
        let nodes = {};
        let edgesTo = new Set();
        for(let i=0;i< bindings.length;i++){
          let bd = bindings[i];
          let classIdVal = await getPropertyValue(diagramSettings, classNodeId, bd);
          if(!nodes[classIdVal]) {
            nodes[classIdVal] = this.createClassObject();
          }
          let classKeys = [classNodeLabel, classNodeSubtitle, classNodeAnnotation, classNodeNote, classNodeStyle];
          for(let i=0;i<classKeys.length;i++) {
            let key = classKeys[i];
            if(!nodes[classIdVal][key]) {
              nodes[classIdVal][key] = await getPropertyValue(diagramSettings, key, bd);
            }
          }
          let propertyIdVal = await getPropertyValue(diagramSettings,propertyId, bd);
          let propertyKeys = [propertyId, propertyLabel, propertyDatatype, propertyVisibility];
          for(let i=0;i<propertyKeys.length;i++) {
            let key = propertyKeys[i];
            let foundProperty = nodes[classIdVal].properties.find(p => p[propertyId] === propertyIdVal);
            if(!foundProperty) {
              foundProperty = {
                [propertyId] : propertyIdVal
              };
              nodes[classIdVal].properties.push(foundProperty)
            }
            if(!foundProperty[key]) {
              foundProperty[key] = await getPropertyValue(diagramSettings,key, bd);
            }
          }
          let methodNameVal = await getPropertyValue(diagramSettings,methodName, bd);
          if(methodNameVal) {
            let methodKeys = [methodParameters, methodVisibility, methodReturnType];
            for (let i = 0; i < methodKeys.length; i++) {
              let key = methodKeys[i];
              let foundMethod = nodes[classIdVal].methods.find(p => p[methodName] === methodNameVal);
              if (!foundMethod) {
                foundMethod = {
                  [methodName]: methodNameVal
                };
                nodes[classIdVal].methods.push(foundMethod)
              }
              if (!foundMethod[key]) {
                foundMethod[key] = await getPropertyValue(diagramSettings,key, bd);
              }
            }
          }
          let relationshipLabelVal = await getPropertyValue(diagramSettings,relationshipLabel, bd)
          let relationshipTargetClassIdVal = await getPropertyValue(diagramSettings,relationshipTargetClassId, bd)
          if(relationshipTargetClassIdVal) {
            let relationshipKeys = [relationshipEdgeType, relationshipCardinalityOnSource, relationshipCardinalityOnTarget];
            for (let i = 0; i < relationshipKeys.length; i++) {
              let key = relationshipKeys[i];
              let foundObject = nodes[classIdVal].relations.find(p => {
                let found = p[relationshipTargetClassId] === relationshipTargetClassIdVal
                    && (
                        (propertyIdVal && p[propertyId] === propertyIdVal)
                        || (relationshipLabelVal && p[relationshipLabel] === relationshipLabelVal)
                    );
                return found ? true : false;
              });
              if (!foundObject) {
                foundObject = {
                  [propertyId]: propertyIdVal,
                  [relationshipLabel]: relationshipLabelVal,
                  [relationshipTargetClassId]: relationshipTargetClassIdVal
                };
                nodes[classIdVal].relations.push(foundObject)
              }
              if (!foundObject[key]) {
                foundObject[key] = await getPropertyValue(diagramSettings,key, bd);
              }
            }
            edgesTo.add(relationshipTargetClassIdVal);
          }
        }
        edgesTo.forEach(e => {
          if(!nodes[e]) {
            const classObject = this.createClassObject();
            nodes[e] = classObject;
          }
        })
        let mermaidIds = {};

      Object.keys(nodes).forEach(id => {
          const classLabelUnquoted = nodes[id][classNodeLabel] || getLocalName(id, false);
          let classIdForMermaid = getMermaidResourceId(classLabelUnquoted);
          if(mermaidIds[classIdForMermaid]) {
            mermaidIds[classIdForMermaid] = [...mermaidIds[classIdForMermaid], id];
            classIdForMermaid = classIdForMermaid+"_"+mermaidIds[classIdForMermaid].length;
          }
          mermaidIds[classIdForMermaid] = [id];
          const subtitle = nodes[id][classNodeSubtitle];
          mermaidData = mermaidData+"\n"+`class ${classIdForMermaid}["${classLabelUnquoted} ${subtitle ? "<br/>"+subtitle :""} "]`;
          const annotation = nodes[id][classNodeAnnotation];
          if(annotation) {
            mermaidData = mermaidData+"\n"+`${annotation} ${classIdForMermaid}`;
          }
          const style = nodes[id][classNodeStyle];
          if(style) {
            mermaidData = mermaidData+"\n"+`style ${classIdForMermaid} ${style}`;
          }
          const note = nodes[id][classNodeNote];
          if(note) {
            mermaidData = mermaidData+"\n"+`note for ${classIdForMermaid} "${note}"`;
          }
          const nodeProperties = nodes[id].properties;
          nodeProperties.forEach(p => {
            const propertyLabelVal = p[propertyLabel] ? p[propertyLabel] : getLocalName(p[propertyId], false) ;
            const propertyDatatypeVal = p[propertyDatatype] && getLocalName(p[propertyDatatype], false);
            const propertyVisibilityVal = p[propertyVisibility] || "";
            if(propertyDatatypeVal) {
              mermaidData = mermaidData + "\n" + `${classIdForMermaid} : ${propertyVisibilityVal} ${propertyDatatypeVal}  ${propertyLabelVal}`;
            }
          })
          nodes[id].methods.forEach(m => {
            const methodNameVal = m[methodName];
            const methodVisibilityVal = m[methodVisibility] || "";
            const methodParametersVal = m[methodParameters] || "";
            const methodReturnTypeVal = m[methodReturnType] || "";
            mermaidData = mermaidData + "\n" + `${classIdForMermaid} : ${methodVisibilityVal} ${methodNameVal}(${methodParametersVal}) ${methodReturnTypeVal}`;
          })
          nodes[id].relations.forEach(r => {
            const relationshipLabelVal = r[relationshipLabel] || getLocalName(r[propertyId], false) ||"";
            const relationshipCardinalityOnSourceVal = r[relationshipCardinalityOnSource] || "";
            const relationshipCardinalityOnTargetVal = r[relationshipCardinalityOnTarget] || "";
            const relationshipEdgeTypeVal = r[relationshipEdgeType];
            let relationshipEdgeTypeValToUse = "<--";
            if(relationshipEdgeTypeVal) {
              const found = relationShipEdgeTypes.find(ret => ret.value === relationshipEdgeTypeVal || ret.label === relationshipEdgeTypeVal);
              relationshipEdgeTypeValToUse = found.value;
            }
            const relationshipTargetClassIdVal =  r[relationshipTargetClassId];
            if(relationshipTargetClassIdVal) {
              let targetClassMermaidId = nodes[relationshipTargetClassIdVal][classNodeLabel] || getLocalName(relationshipTargetClassIdVal, false);
              targetClassMermaidId = getMermaidResourceId(targetClassMermaidId)
              mermaidData = mermaidData + "\n" + `${targetClassMermaidId} "${relationshipCardinalityOnTargetVal}" ${relationshipEdgeTypeValToUse} "${relationshipCardinalityOnSourceVal}" ${classIdForMermaid} : ${relationshipLabelVal}`
            }
          })
        });
        console.log("mermaidData", mermaidData)
        return mermaidData;

        //this.setState({mermaidData})

    }

    render() {
      const {mermaidData} = this.state;
        return <>
          {<MermaidWrapper
              name={this.props.name}
              onRefresh={() => {this.load().then(mermaidData => this.setState({mermaidData}))}}
          >{mermaidData}</MermaidWrapper>}
        </>;
    }
}

ClassDiagram.propTypes = {
    diagramSettings: PropTypes.any,
    data: PropTypes.any,
};

export default withStyles({}, {withTheme: true})(ClassDiagram);

